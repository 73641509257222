import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import Searchbox from "../../../../../Reuseable/Searchbox";
import { setFilters } from "../../../../../../redux/Tracking/actionCreator";
import RefreshIcon from "@mui/icons-material/Refresh";
import file_download_Icon from "../../../../../../assets/images/file_download_Icon.svg"
import print_icon from "../../../../../../assets/images/print_icon.svg"
import filterDateIcon from "../../../../../../assets/images/filterDateIcon.svg"
import { DatePicker } from "antd";
import moment from "moment";

export const TaskListHeaders = ({
    setProjectView,
    searchValue,
    handleSearch,
    handleDueSearch,
    userRole,
    userRoleType,
    allMembers,
    cardView,
    refreshAllTracking,
    loading,
    allProjects,
    allReportTasksData,
    setTrackingFilters,
    createPermission,
    selectedRows,
    selectAll,
    setSearchFromDate,
    searchfromDate,
    searchDueDate,
    handleFromDate
}) => {
    const item = allReportTasksData.map(ele => ele)

    const dispatch = useDispatch();
    let allStatus = useSelector((state) => state.Tracking.allFlowStatus);
    let filters = useSelector((state) => state.Tracking.filters);
    const trackingClientId = useSelector((state) => state.Auth.user.clientId);

    if (allStatus.length > 0) {
        let result = [];
        allStatus?.forEach((element) => {
        result.push({ label: element.label, value: element.id });
        });
        allStatus = result;
    }

    if (allReportTasksData.length > 0) {
        let result = [];
        allReportTasksData?.forEach((element) => {
            result.push({ label: element.label, value: element.id });
        });
        allReportTasksData = result;
    }

    if (allMembers.length > 0) {
        let result = [];
        allMembers?.forEach((element) => {
            result.push({ label: element.personname, value: element.trackinguserid });
        });
        allMembers = result;
    }

    function removeHTML(str) {
        var tmp = document.createElement("DIV");
        tmp.innerHTML = str;
        return tmp.textContent || tmp.innerText || "";
    }

    const getMonthName = (monthIndex) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return months[monthIndex];
    };

    const downloadList = () => {
        var A = [['Task No ', 'Task name', 'Created date', 'Project name', 'Timeline', 'Log time', 'Remaining time', 'Status']];
        var csvRows = [];

        item.forEach(element => {
            element.title = element.title.replace(/,/g, "");
            A.push([
                `${trackingClientId}-${element.trackingid}`,
                element.title,
                moment(element.reporteddate).format("DD/MM/YYYY"),
                element.projectname,
                element.timeline,
                element.loggedTime,
                element.remainingTime,
                element?.status_name,
            ]);
        });

        for (var i = 0, l = A.length; i < l; ++i) {
            csvRows.push(A[i].join(','));
        }

        var csvString = csvRows.join("\n");
        var a = document.createElement('a');
        a.href = 'data:attachment/csv,' + encodeURIComponent(csvString);
        a.target = '_blank';
        a.download = `${trackingClientId}_task_list.csv`;

        document.body.appendChild(a);
        a.click();
    }

    const PrintList = () => {
        var tableRows = [];
        item.forEach(element => {
            element.title = element.title.replace(/,/g, "");
            tableRows.push(`
            <tr>
                <td>${trackingClientId}-${element.trackingid || "--"}</td>
                <td>${element.title === "" ? "--" : element.title}</td>
                <td>${moment(element.reporteddate).format("DD/MM/YYYY") || "--"}</td>
                <td>${element.projectname || "--"}</td>
                <td>${element?.timeline || "--"}</td>
                <td>${element.loggedTime || "--"}</td>
                <td>${element.remainingTime || "--"}</td>
                <td>${element.status_name || "--" }</td>
            </tr>
            `);
        });
        
        var tableHTML = `
            <table>
                <thead>
                    <tr>
                        <th>Task No</th>
                        <th>Task name</th>
                        <th>Created date</th>
                        <th>Project name</th>
                        <th>Timeline</th>
                        <th>Log time</th>
                        <th>Remaining time</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    ${tableRows.join('')}
                </tbody>
            </table>
        `;

        var printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write('<html><body>');
        printWindow.document.write(tableHTML);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <>
            {allReportTasksData.length > 0 &&
                <Row className="pb-2">
                    <div className="report_task_header">
                        <div className="report_task_date_filter">
                            <Col className="report_date_filters">
                                {" "}
                                <DatePicker
                                    suffixIcon={<img src={filterDateIcon} alt="Calendar" />}
                                    onChange={handleFromDate}
                                    selected={searchfromDate}
                                    name={searchfromDate}
                                    placeholder="From date"
                                    className="form-control search-control border-start-0 search_date_filter"
                                />{" "}
                                {" "}
                            </Col>
                            <Col className="report_date_filters">
                                {" "}
                                <DatePicker
                                    suffixIcon={<img src={filterDateIcon} alt="Calendar" />}
                                    onChange={handleDueSearch}
                                    selected={searchDueDate}
                                    name={searchDueDate}
                                    placeholder="To date"
                                    className="form-control search-control border-start-0 search_date_filter"
                                />{" "}
                                {" "}
                            </Col>
                            <Col>
                                {" "}
                                <Searchbox
                                    handleSearch={handleSearch}
                                    value={searchValue}
                                    placeholder={"Search by name"}
                                    className="form-control search-control border-start-0 search_name_filter"
                                    byName="searchByName"
                                    filterNameIcon={filterDateIcon}
                                />{" "}
                            </Col>
                        </div>
                        <div className="report_task_action_btns">
                            <Col>
                                <Button className={allReportTasksData.length > 0 ? "me-1 download_csv_file_btn" : "download_csv_file_btn disabled"} onClick={e => {
                                    downloadList();
                                }}>
                                    <img src={file_download_Icon} alt="file_download_Icon" /> Download CSV
                                </Button>
                            </Col>
                            <Col>
                                <Button className={allReportTasksData.length > 0 ? "me-1 download_print_btn" : "me-1 download_print_btn disabled"} onClick={e => {
                                    PrintList()
                                }}>
                                    <img src={print_icon} alt="print_icon" /> Print
                                </Button>
                            </Col>
                        </div>
                    </div>
                </Row>
            }
        </>
    );
};