import React from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import goalsImage from "../../../../assets/images/AdminImages/Teams/Shared goals-amico.svg";

const AddTeams = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="card-bd">
            <Card.Body>
              <div className="d-flex flex-column flex-md-row align-items-center">
                <div>
                  <img
                    className="img-fluid"
                    src={goalsImage}
                    alt="add User idea"
                  />
                </div>

                <div>
                  <div className="fs-4 ms-4 mb-4" style={{ color: "#2D53DA" }}>
                    You have not added any user to your account.
                  </div>
                  <div className="ms-4 pb-1">
                    Add user to your account by sending them invitation and keep
                    track of all your projects.
                  </div>

                  <Button
                    className="border-0 px-5 py-1 m-4 float-end"
                    style={{ background: "#2D53DA" }}
                  >
                    Create Team
                  </Button>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddTeams;
