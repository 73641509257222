import React, { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert } from "react-bootstrap"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setAllTrackings,
  setFetchedAllTrackingsList,
  setStartAllTrekId,
  setAllTaskView
} from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import { TrackingView } from "./TrackingView"
import { Clone } from "./Clone"
import { v4 as uuid } from "uuid"
import { useSessionStorage } from "react-use"
import usePrevious from "../../../../Hooks/usePrevious"
import useTrackingItemData from "../../../../Hooks/useTrackingItemData"
import UIModal from "../../../Reuseable/Modal"

const TrackingModal = ({ setShowTaskViewModal, showTaskViewModal, permission }) => {
  /********************* Banner issue Task TRWH-1112 *****************/

  const editPermission = permission?.edit
  const createPermission = permission?.create
  const deletePermission = permission?.delete

  const fetchedAllTrackings = useSelector((state) => state.Tracking.fetchedAllTrackings)
  const fetchedMyTrackings = useSelector((state) => state.Tracking.fetchedMyTrackings)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)

  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const allProjects = useSelector((state) => state.Tracking.projects)
  const allProjectsLoading = useSelector((state) => state.Tracking.projectsLoading)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  let allMembers = useSelector((state) => state.Tracking.allMembers)
  let allWorkFlow = useSelector((state) => state.Tracking.allWorkFlow)
  let Work_Flow = allWorkFlow && Object.keys(allWorkFlow)?.map((key) => allWorkFlow[key])
  const allTaskTypes = useSelector((state) => state.Tracking.allTaskTypes)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)

  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const [showView, setShowView] = useState(false)

  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [formType, setFormType] = useState("")
  const [clonetaskData, setCloneTask] = useState({})
  let taskview = useSelector((state) => state.Tracking.allTaskView)

  const isProjectExist = (project) => {
    const result = allProjects?.find((e) => e.trackingprojectid == project || e.number == project)
    return result !== undefined ? true : false
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }
  const dispatch = useDispatch()
  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters?.projects,
    typeFilter: filters?.taskType,
    assignedtoFilter: filters?.assignedTo,
    statusFilter: filters?.status,
    priorityFilter: filters?.priority,
    reportedByFilter: filters?.reportedBy
  })

  const selected_menu = "all_tasks"
  const [changeInForm, setChangeInForm] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [updatedData, setUpdatedData] = useSessionStorage("trackingUpdatedData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [trackingLoading, setTrackingLoading] = useState(false)
  const [startAllTrackingId, setStartAllTrackingId] = useState(
    useSelector((state) => state.Tracking.startAllTrackingId)
  )

  const [trackingItemData, setTrackingItemData] = useTrackingItemData()

  const selectedProject = useSelector((state) => state.Tracking.allTaskSelectedProject)

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.title,
        projectid: trackingItemData?.trackingprojectsid,
        taskType: trackingItemData?.type,
        assignedTo: trackingItemData?.assignedto,
        priority: trackingItemData?.priority,
        status: trackingItemData?.trackingprojectstatusid,
        description: trackingItemData?.description,
        timeline: trackingItemData?.timeline,
        fileurls: trackingItemData?.fileurls,
        filesize: trackingItemData?.filesize ?? [],
        fileArray: []
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.cloneTitle,
        // assignedto: trackingItemData.assignedto,
        assignedto: ""
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.title,
        description: trackingItemData?.description,
        fileurls: trackingItemData?.fileurls,
        timeline: trackingItemData?.timeline,
        filesize: trackingItemData?.filesize
      }
    })
  }, [trackingItemData])

  const previousProject = usePrevious(selectedProject) || allProjects?.[0]?.trackingprojectid

  const project = isProjectExist(selectedProject)
    ? selectedProject
    : previousProject || allProjects?.[0]?.trackingprojectid

  const fetchTrackingsList = async (startId, fetchtype) => {
    setTrackingLoading(true)
    setLoading(true)
    try {
      let url = `/tracking/trackingproject/${trackingClientId}/${startId}`
      const response = await api.get(url, {
        headers: {
          authorizationtoken: ` ${authToken}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      })
      setTrackingLoading(false)
      if (response.data.status === true) {
        if (selected_menu === "all_tasks") {
          if (response.data.data) {
            if (fetchtype === "refresh") dispatch(setAllTrackings([...response.data.data]))
            else {
              dispatch(setAllTrackings(response.data.data))
            }
          }
          if (response.data.data.LastEvaluatedKey) {
            setStartAllTrackingId(response.data.data.LastEvaluatedKey.trackingid)
            dispatch(setStartAllTrekId(response.data.data.LastEvaluatedKey.trackingid))
          } else {
            setStartAllTrackingId("none")
            dispatch(setStartAllTrekId("none"))
          }
          dispatch(setFetchedAllTrackingsList(true))
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      console.log("fetchTrackingsList  error", error)
      setTrackingLoading(false)
    }
  }

  useEffect(() => {
    if (project) {
      fetchTrackingsList(project)
    }
  }, [project])

  const taskViewRender = (value) => {
    dispatch(setAllTaskView(value))
  }

  const changesInList = useRef(null)

  const changesMade = () => {
    changesInList.current = uuid()
  }

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete("/tracking/" + trackingClientId + "/" + trackingItemData.trackingid, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            taskViewRender("AllTask")
            let index = allTrackingsList.findIndex(
              (element) => element.trackingid === trackingItemData.trackingid
            )
            if (index >= 0) {
              allTrackingsList.splice(index, 1)
            }
            changesMade()
            setShowTaskViewModal(false)
            setAlertMessage("Selected item has been deleted successfully")
          }
        })
    }
  }

  return (
    <>
      {allTrackingsList?.length > 0 ? (
        <>
          {showAlertBox && (
            <Alert
              variant='success'
              className='text-center alert-sucess alert mt-3 mb-1'
              dismissible
              closeVariant='white'
              onClick={(e) => setShowAlertBox(false)}
            >
              {alertMessage}
            </Alert>
          )}
          <ConfirmationBox
            heading='Are you sure to delete this ?'
            hidePopup={(e) => setDeleteConfirmationShow(false)}
            show={deleteConfirmationShow}
            confirmationResponse={deleteConfimrationHandler}
          />

          {trackingItemData?.title ? (
            <>
              <UIModal showModal={showTaskViewModal} setShowModal={setShowTaskViewModal} size='xl'>
                <TrackingView
                  isModalView={true}
                  setCloneTask={setCloneTask}
                  fileLoading={fileLoading}
                  setTaskview={taskViewRender}
                  show={showView}
                  setTaskFile={setTaskFile}
                  setTaskFileType={setTaskFileType}
                  taskfileType={taskfileType}
                  setTrackingItemData={setTrackingItemData}
                  taskfileList={taskfileList}
                  setTaskFileList={setTaskFileList}
                  taskfile={taskfile}
                  setShowView={(value) => setShowView(value)}
                  allTaskTypes={allTaskTypes}
                  allStatus={allFlowStatus}
                  item={trackingItemData}
                  data={viewTaskData}
                  setViewTaskData={setViewTaskData}
                  showEditTask={showEditTask}
                  setShowEditTask={setShowEditTask}
                  updatedData={updatedData}
                  setUpdatedData={setUpdatedData}
                  history={taskHistory}
                  setHistory={setHistory}
                  setShowClone={setShowClone}
                  selected_menu={selected_menu}
                  setDeleteConfirmationShow={setDeleteConfirmationShow}
                  deletePermission={deletePermission}
                  editPermission={editPermission}
                />
              </UIModal>
              {clonetaskData?.title !== undefined ? (
                <Clone
                  showClone={showClone}
                  setShowClone={setShowClone}
                  item={trackingItemData}
                  allTrackingsList={allTrackingsList}
                  setShowAlertBox={setShowAlertBox}
                  setAlertMessage={setAlertMessage}
                  clonetaskData={clonetaskData}
                  setCloneTask={setCloneTask}
                  setTaskview={taskViewRender}
                  backTo={"AllTask"}
                  taskfile={taskfile}
                  taskfileList={taskfileList}
                  viewTaskData={viewTaskData}
                />
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  )
}

export default memo(TrackingModal)
