import React from "react"
import { useLocation } from "react-router-dom"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import useMediaQuery from "@mui/material/useMediaQuery"
import { key } from "../../../../../data/queryKeys"
import { useFetch } from "../../../../../Hooks/useFetch"
export const TrackingListColumns = ({
  handleSearch,
  trackingSortings,
  setTrackingSorting,
  selectAllTasks,
  handleTasksHeaderCheckbox
}) => {
  const largeScreen = useMediaQuery("(min-width:991px)")
  const location = useLocation()
  const currentPath = "/myTasks"

  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" }
      })
  }

  const applyProjectNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "project",
        trackingNumberSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, projectNameSorting: "down" }
      })
  }

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" }
      })
  }

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        typeSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" }
      })
  }

  const applytypeSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "type",
        trackingNumberSorting: "",
        typeSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" }
      })
  }

  const applyAssignToSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "assignedto",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, assignedToSorting: "down" }
      })
  }

  const applyStatusSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "status",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "down" }
      })
  }

  let columnValues = []

  const { data: allTableHeadersNameRes } = useFetch(
    key.get_table_headers,
    `/masterdata/3`,
    () => {},
    () => {},
    false,
    false
  )

  let allTaskHeadersNames = allTableHeadersNameRes?.formStructureData ?? []

  for (let i = 0; i < allTaskHeadersNames.length; i++) {
    columnValues.push(allTaskHeadersNames[i].column1)
    columnValues.push(allTaskHeadersNames[i].column2)
    columnValues.push(allTaskHeadersNames[i].column3)
    columnValues.push(allTaskHeadersNames[i].column4)
    columnValues.push(allTaskHeadersNames[i].column5)
    columnValues.push(allTaskHeadersNames[i].column6)
    columnValues.push(allTaskHeadersNames[i].column7)
    if (location.pathname !== currentPath) {
      columnValues.push(allTaskHeadersNames[i].column8)
    }
    columnValues.push(allTaskHeadersNames[i].column9)
    columnValues.push(allTaskHeadersNames[i].column10)
  }

  return (
    <>
      {largeScreen && (
        <th className='text-end'>
          <input
            type='checkbox'
            name='group1'
            className='table_check_box mt-1'
            onChange={() => handleTasksHeaderCheckbox()} // Wrap the function call inside an arrow function
            checked={selectAllTasks}
          />
        </th>
      )}
      {columnValues.map((item, index) => {
        const handleSortingClick = () => {
          if (item === "Task No.") {
            applyTrackingNumberSorting(trackingSortings.trackingNumberSorting)
          } else if (item === "Status") {
            applyStatusSorting(trackingSortings.statusSorting)
          } else if (item === "Reported By") {
            applyReportedBySorting(trackingSortings.reportedBySorting)
          } else if (item === "Assigned To") {
            applyAssignToSorting(trackingSortings.assignedToSorting)
          } else if (item === "Priority") {
            applyPrioritySorting(trackingSortings.prioritySorting)
          }
        }
        return (
          <th onClick={handleSortingClick} key={index}>
            {item}
            {item === "Task No." ||
            item === "Status" ||
            item === "Reported By" ||
            item === "Assigned To" ||
            item === "Priority" ? (
              <>
                <span color={trackingSortings.trackingNumberSorting != "" ? "#2D53DA" : ""}>
                  {trackingSortings.trackingNumberSorting == "up" && item === "Task No." ? (
                    <ArrowUpwardIcon
                      color={trackingSortings.trackingNumberSorting != "" ? "#2D53DA" : ""}
                      style={{ cursor: "pointer", fontSize: "20px", fontWeight: "800" }}
                    />
                  ) : trackingSortings.statusSorting == "up" && item === "Status" ? (
                    <ArrowUpwardIcon
                      color={trackingSortings.statusSorting != "" ? "#2D53DA" : ""}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  ) : trackingSortings.reportedBySorting == "up" && item === "Reported By" ? (
                    <ArrowUpwardIcon
                      color={trackingSortings.reportedBySorting != "" ? "#2D53DA" : ""}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  ) : trackingSortings.assignedToSorting == "up" && item === "Assigned To" ? (
                    <ArrowUpwardIcon
                      color={trackingSortings.assignedToSorting != "" ? "#2D53DA" : ""}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  ) : trackingSortings.prioritySorting == "up" && item === "Priority" ? (
                    <ArrowUpwardIcon
                      color={trackingSortings.prioritySorting != "" ? "#2D53DA" : ""}
                      style={{ cursor: "pointer", fontSize: "20px" }}
                    />
                  ) : (
                    <ArrowDownwardIcon
                      style={{ cursor: "pointer", fontSize: "20px", fontWeight: "800" }}
                    />
                  )}
                </span>
              </>
            ) : (
              <></>
            )}
          </th>
        )
      })}
    </>
  )
}
