import { ActionTypes } from "./actions"
import configData from "../../config/config"

export const intialState = {
  darkmode: false,
  panelview: configData.panelView
}

export const LayoutReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.CHANGE_THEME:
      return {
        ...state,
        darkmode: payload
      }
    case ActionTypes.CHANGE_VIEW:
      return {
        ...state,
        panelview: payload
      }
    default:
      return state
  }
}
