import React, { useState } from "react"
import { useSelector } from "react-redux"
import Checkbox from "@mui/material/Checkbox"
import FormGroup from "@mui/material/FormGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import { Button, Card, Table, Modal } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import pendinguser from "../../../../../../assets/images/AdminImages/User/invite-pending-user.svg"
import DeleteIcon from "../../../../../../assets/images/AdminImages/User/bluedelete12.svg"
import Divider from "@mui/material/Divider"
import Switch from "@mui/material/Switch"
import { api } from "../../../../../../Services/api"
import CloseIcon from "@mui/icons-material/Close"
import { useMedia } from "react-use"
import UserlistWrapper from "../style"
import Snackbar from "@mui/material/Snackbar"
import Alert from "@mui/material/Alert"
import { client } from "../../../../../.."
import { key } from "../../../../../../data/queryKeys"
import { useFetch } from "../../../../../../Hooks/useFetch"

const UserDetails = ({
  subUserPermission,
  setShowAlertBox,
  setAlertMessage,
  deleteUser,
  memberDetails,
  setMemberDetails,
  setsubUserPermission
}) => {
  const clientId = useSelector((state) => state.Auth.user.clientId)
  const authToken = useSelector((state) => state.Auth.authToken)
  const User = useSelector((state) => state.Auth.user)

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = React.useState(false)

  const handleClosePopup = (reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const showResposiveSideBar = useMedia("(max-width: 767px)")
  const userId = subUserPermission.userId

  const { refetch: getAllMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  const { refetch: getAllProjects } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  const deleteMember = (userId) => {
    setLoading(true)
    api
      .delete("/trackingusers/memberlist/" + clientId + "/" + userId, {
        headers: {
          authorizationtoken: ` ${authToken}`
        }
      })
      .then(function (response) {
        if (response.data.status === true) {
          setMemberDetails([
            ...memberDetails.slice(0, deleteUser),
            ...memberDetails.slice(deleteUser + 1, memberDetails.length)
          ])
          setLoading(false)
          handleClose()
          setShowAlertBox(true)
          setAlertMessage("Selected User has been deleted successfully")
          getAllMembers()
          getAllProjects()
        }
      })
  }

  const handleCreateChange = (MenuList, index) => {
    let Id = index
    setsubUserPermission((prevState) => {
      let temp = {
        ...prevState,
        menuList: [...prevState.menuList]
      }

      temp.menuList[Id].permission.create = !temp.menuList[Id].permission.create
      if (
        temp.menuList[Id].permission.view === false &&
        temp.menuList[Id].permission.create === false &&
        temp.menuList[Id].permission.delete === false &&
        temp.menuList[Id].permission.edit === false
      ) {
        temp.menuList[Id].access = false
      }
      return temp
    })
    UpdateMenuPermission()
  }

  const handleViewChange = (MenuList, index) => {
    let Id = index
    setsubUserPermission((prevState) => {
      let temp = {
        ...prevState,
        menuList: [...prevState.menuList]
      }

      temp.menuList[Id].permission.view = !temp.menuList[Id].permission.view
      if (
        temp.menuList[Id].permission.view === false &&
        temp.menuList[Id].permission.create === false &&
        temp.menuList[Id].permission.delete === false &&
        temp.menuList[Id].permission.edit === false
      ) {
        temp.menuList[Id].access = false
      }
      return temp
    })
    UpdateMenuPermission()
  }

  const handleDeleteChange = (MenuList, index) => {
    let Id = index
    setsubUserPermission((prevState) => {
      let temp = {
        ...prevState,
        menuList: [...prevState.menuList]
      }

      temp.menuList[Id].permission.delete = !temp.menuList[Id].permission.delete
      if (
        temp.menuList[Id].permission.view === false &&
        temp.menuList[Id].permission.create === false &&
        temp.menuList[Id].permission.delete === false &&
        temp.menuList[Id].permission.edit === false
      ) {
        temp.menuList[Id].access = false
      }
      return temp
    })
    UpdateMenuPermission()
  }

  const handleEditChange = (MenuList, index) => {
    let Id = index
    setsubUserPermission((prevState) => {
      let temp = {
        ...prevState,
        menuList: [...prevState.menuList]
      }

      temp.menuList[Id].permission.edit = !temp.menuList[Id].permission.edit

      if (
        temp.menuList[Id].permission.view === false &&
        temp.menuList[Id].permission.create === false &&
        temp.menuList[Id].permission.delete === false &&
        temp.menuList[Id].permission.edit === false
      ) {
        temp.menuList[Id].access = false
      }
      return temp
    })
    UpdateMenuPermission()
  }

  const handleMenuAccess = (MenuList, index) => {
    let Id = index
    setsubUserPermission((prevState) => {
      let temp = {
        ...prevState,
        menuList: [...prevState.menuList]
      }

      temp.menuList[Id].access = !temp.menuList[Id].access

      if (temp.menuList[Id].access === false) {
        temp.menuList[Id].permission.create = false
        temp.menuList[Id].permission.view = false
        temp.menuList[Id].permission.delete = false
        temp.menuList[Id].permission.edit = false
      }
      if (temp.menuList[Id].access === true) {
        temp.menuList[Id].permission.view = true
      }

      return temp
    })
    UpdateMenuPermission()
  }

  const UpdateMenuPermission = () => {
    let obj = subUserPermission.menuList

    api
      .patch("/trackingusers/memberlist/" + clientId + "/" + userId, obj, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then(function (response) {
        if ((response.status = 200)) {
        }
      })
  }

  const sendRemainder = () => {
    let obj = {
      clientname: User.userName,
      emailId: subUserPermission.userId,
      clientId: clientId,
      personname: subUserPermission.personname,
      trackinguserid: User.userId
    }

    api
      .post("trackingadmin/subuser/sendreminder", obj, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then(function (response) {
        if ((response.status = 200)) {
          setOpen(true)
        }
      })
  }

  let countTrueAccess = 0
  if (subUserPermission) {
    for (const item of subUserPermission?.menuList) {
      if (item.access) {
        countTrueAccess++
        if (countTrueAccess > 1) {
          break
        }
      }
    }
  }

  const onlyOneAccessTrue = countTrueAccess === 1
  return (
    <>
      <div className='User-details-container w-100'>
        <Card style={{ border: "none" }} className='card-bd card-border my-1 w-100'>
          {showResposiveSideBar ? (
            <UserlistWrapper>
              {subUserPermission.status === "Unverified" ? (
                <Card.Body className='details-card-body  w-100 h-100'>
                  <div className=' float-end p-1'>
                    <img
                      onClick={() => {
                        handleShow()
                      }}
                      style={{ cursor: "pointer" }}
                      className='float-end'
                      src={DeleteIcon}
                      alt='Delete'
                    />
                  </div>

                  <center>
                    <img className='img-fluid mb-5' src={pendinguser} alt='Pending invite' />
                    <p className='mb-5'>
                      {subUserPermission.personname} has not accepted your invite yet.{" "}
                      <span onClick={sendRemainder} className='send_remainder'>
                        Send remainder
                      </span>
                    </p>
                  </center>
                </Card.Body>
              ) : null}
              <Card.Body>
                <div>
                  <div className='Name-team-row'>
                    <h4>{subUserPermission.personname}</h4>
                  </div>
                  <div className=' float-end p-1'>
                    <img
                      onClick={() => {
                        handleShow()
                      }}
                      style={{ cursor: "pointer" }}
                      className='float-end'
                      src={DeleteIcon}
                      alt='Delete'
                    />
                  </div>
                  <p className='d-flex faded-text'>Email ID: {subUserPermission.userId}</p>
                </div>
                <Divider />
                <div>
                  <h6 className='my-3' style={{ color: "#2D53DA" }}>
                    Menus
                  </h6>
                  <div>
                    {subUserPermission ? (
                      <>
                        {subUserPermission.menuList.map((MenuList, index) => {
                          return (
                            <div className='menu-list-row'>
                              <FormGroup className='menu-checkbox'>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={MenuList.access}
                                      disabled={onlyOneAccessTrue && MenuList.access}
                                      onChange={() => {
                                        handleMenuAccess(MenuList, index)
                                      }}
                                      sx={{
                                        color: "#2D53DA",
                                        "&.Mui-checked": {
                                          color: "#2D53DA"
                                        }
                                      }}
                                    />
                                  }
                                  label={MenuList.name}
                                />
                              </FormGroup>
                            </div>
                          )
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
                <div></div>
                <div className='mt-3'>
                  <Table borderless responsive className='text-white mt-2'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>View</th>
                        <th>Create</th>
                        <th>Delete</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subUserPermission ? (
                        <>
                          {subUserPermission.menuList.map((MenuList, index) => {
                            return (
                              <tr>
                                <th className='access-type'>{MenuList.name}</th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleViewChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.view}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleCreateChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.create}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleDeleteChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.delete}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleEditChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.edit}
                                  />
                                </th>
                              </tr>
                            )
                          })}
                        </>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </UserlistWrapper>
          ) : (
            <>
              {subUserPermission.status === "Unverified" ? (
                <Card.Body
                  style={{ borderRadius: "5px;" }}
                  className='details-card-body  w-100 h-100'
                >
                  <div className=' float-end p-1'>
                    <img
                      onClick={() => {
                        handleShow()
                      }}
                      style={{ cursor: "pointer" }}
                      className='float-end'
                      src={DeleteIcon}
                      alt='Delete'
                    />
                  </div>

                  <center>
                    <img className='img-fluid mb-5' src={pendinguser} alt='Pending invite' />
                    <p className='mb-5'>
                      {subUserPermission.personname} has not accepted your invite yet.{" "}
                      <span onClick={sendRemainder} className='send_remainder'>
                        Send remainder
                      </span>
                    </p>
                  </center>
                </Card.Body>
              ) : null}
              <Card.Body>
                <div>
                  <div className='Name-team-row'>
                    <h4>{subUserPermission.personname}</h4>
                  </div>
                  <div className=' float-end p-1'>
                    <img
                      onClick={() => {
                        handleShow()
                      }}
                      style={{ cursor: "pointer" }}
                      className='float-end'
                      src={DeleteIcon}
                      alt='Delete'
                    />
                  </div>
                  <p className='d-flex faded-text'>Email ID: {subUserPermission.userId}</p>
                </div>
                <Divider />
                <div>
                  <h6 className='my-3' style={{ color: "#2D53DA" }}>
                    Menus
                  </h6>
                  <div>
                    {subUserPermission ? (
                      <>
                        {subUserPermission.menuList.map((MenuList, index) => {
                          return (
                            <div className='menu-list-row'>
                              <FormGroup className='menu-checkbox'>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={MenuList.access}
                                      disabled={onlyOneAccessTrue && MenuList.access}
                                      onChange={() => {
                                        handleMenuAccess(MenuList, index)
                                      }}
                                      sx={{
                                        color: "#2D53DA",
                                        "&.Mui-checked": {
                                          color: "#2D53DA"
                                        }
                                      }}
                                    />
                                  }
                                  label={MenuList.name}
                                />
                              </FormGroup>
                            </div>
                          )
                        })}
                      </>
                    ) : null}
                  </div>
                </div>
                <div></div>
                <div className='mt-3'>
                  <Table borderless responsive className='text-white mt-2'>
                    <thead>
                      <tr>
                        <th></th>
                        <th>View</th>
                        <th>Create</th>
                        <th>Delete</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subUserPermission ? (
                        <>
                          {subUserPermission.menuList.map((MenuList, index) => {
                            return (
                              <tr>
                                <th className='access-type'>{MenuList.name}</th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleViewChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.view}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleCreateChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.create}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleDeleteChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.delete}
                                  />
                                </th>
                                <th>
                                  {" "}
                                  <Switch
                                    size='small'
                                    disabled={!MenuList.access}
                                    onChange={() => {
                                      handleEditChange(MenuList, index)
                                    }}
                                    inputProps={{ "aria-label": "controlled" }}
                                    checked={MenuList.permission.edit}
                                  />
                                </th>
                              </tr>
                            )
                          })}
                        </>
                      ) : null}
                    </tbody>
                  </Table>
                </div>
              </Card.Body>
            </>
          )}
        </Card>
      </div>
      <Modal
        style={{ backdropFilter: "blur(6px)" }}
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='p-2' style={{ color: "#2D53DA" }}>
          <CloseIcon
            className=' mt-1'
            onClick={handleClose}
            style={{ color: "#2D53DA", cursor: "pointer", float: "right" }}
          />
          <div className='p-2 py-4'>
            <center>
              <p className='mb-4'>Are you sure you want to delete this member?</p>
              <Button
                className='deletebtn mx-3 my-2'
                style={{
                  backgroundColor: "#2D53DA",
                  border: "none",
                  width: "100px",
                  height: "40px"
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                className='deletebtn mx-3 my-2'
                onClick={() => {
                  deleteMember(userId)
                }}
                style={{
                  background: loading ? "#FFFFFF" : "#2D53DA",
                  color: loading ? "#808080" : "#FFFFFF",
                  border: loading ? "2px solid #FFFFFF" : "2px solid #2D53DA",
                  width: "100px",
                  height: "40px"
                }}
              >
                {loading ? (
                  <BarLoader
                    color='#808080'
                    className='bar-loader'
                    size={14}
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <></>
                )}
                Yes{""}
              </Button>
            </center>
          </div>
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClosePopup}
      >
        <Alert severity='success' color='info'>
          Remainder Send
        </Alert>
      </Snackbar>
    </>
  )
}
export default UserDetails
