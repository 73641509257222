export const ActionTypes = {
  RESET_TRACKING: "RESET_TRACKING",
  SET_SELECTED_ID: "SET_SELECTED_ID",
  SET_SHOW_ALERT: "SET_SHOW_ALERT",
  SET_ALL_TASK:"SET_ALL_TASK",
  SET_ALERT_MESSAGE: "SET_ALERT_MESSAGE",
  SET_PROJECTS: "SET_PROJECTS",
  SET_PROJECTS_LOADING: "SET_PROJECTS_LOADING",
  SET_PROJECTS_MODELS: "SET_PROJECTS_MODELS",
  SET_ALL_TRACKINGS: "SET_ALL_TRACKINGS",
  SET_APPROVAL_TRACKING_LIST: "SET_APPROVAL_TRACKING_LIST",
  SET_FETCHED_ALL_TRACKINGS_LIST: "SET_FETCHED_ALL_TRACKINGS_LIST",
  SET_FETCHED_MY_TRACKINGS_LIST: "SET_FETCHED_MY_TRACKINGS_LIST",
  SET_FETCHED_PROJECTS: "SET_FETCHED_PROJECTS",
  SET_FLOW_STATUS_LIST: "SET_FLOW_STATUS_LIST",
  SET_MEMBERS: "SET_MEMBERS",
  SET_TASK_TYPES: "SET_TASK_TYPES",
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  SET_FILTERS: "SET_FILTERS",
  SET_MY_CHART_DATA: "SET_MY_CHART_DATA",
  SET_INPUTFORMDATA: "SET_INPUTFORMDATA",
  SET_START_ALL_TREK_ID: "SET_START_ALL_TREK_ID",
  SET_START_MY_TREK_ID: "SET_START_MY_TREK_ID",
  SET_MY_TRACKINGS: "SET_MY_TRACKINGS",
  SET_WORK_FLOW_LIST: "SET_WORK_FLOW_LIST",
  SET_DOCUMENT_LIST: "SET_DOCUMENT_LIST",
  SET_DOCUMENT_DATA: "SET_DOCUMENT_DATA",
  SET_MY_TASK_PROJECT: "SET_MY_TASK_PROJECT",
  SET_ALL_TASK_PROJECT: "SET_ALL_TASK_PROJECT",
  SET_DOCUMENT_NAME_LIST: "SET_DOCUMENT_NAME_LIST",
  SET_MY_TASK_VIEW: "SET_MY_TASK_VIEW",
  SET_ALL_TASK_VIEW: "SET_ALL_TASK_VIEW",
  SET_PROJECT_VIEW: "SET_PROJECT_VIEW",
  SET_ARCHIVE_TABLE_DATA: "SET_ARCHIVE_TABLE_DATA",
  SET_APPROVAL_VIEW: "SET_APPROVAL_VIEW",
  SET_WORKFLOW_VIEW: "SET_WORKFLOW_VIEW",
  SET_MAIN_PANEL_HEIGHT: "SET_MAIN_PANEL_HEIGHT",
  SET_ALL_MEMBERS_FETCHED: "SET_ALL_MEMBERS_FETCHED",
  SET_GLOBAL_SEARCH: "SET_GLOBAL_SEARCH",
  SET_GLOBAL_SEARCH_RESULT: "SET_GLOBAL_SEARCH_RESULT",
  SET_TRACKING_ITEM_DATA: "SET_TRACKING_ITEM_DATA",
  SET_KPIPOINTS_DATA: "SET_KPIPOINTS_DATA",
  SET_SELECTED_PROJECT: "SET_SELECTED_PROJECT",
  SET_PROJECTMODELDATA: "SET_PROJECTMODELDATA",
  SET_REPORTSTASKS_DATA: "SET_REPORTSTASKS_DATA",
  SET_REPORT_TIMELOGGER_DATA: "SET_REPORT_TIMELOGGER_DATA",
  SET_REPORTSWEEKLY_DATA: "SET_REPORTSWEEKLY_DATA",
  SET_TEMPLATES_DATA: "SET_TEMPLATES_DATA",
  SET_ALL_PRIORITY_VIEW: "SET_ALL_PRIORITY_VIEW",
  SET_NEW_PROJECT_MODEL: "SET_NEW_PROJECT_MODEL",
  SET_ALL_TEMPLATES_DATA: "SET_ALL_TEMPLATES_DATA",
  SET_NEW_TEMPLATES_FORM: "SET_NEW_TEMPLATES_FORM",
  SET_PROJECT_VERSION_DATA: "SET_PROJECT_VERSION_DATA",
  SET_ALL_PRIORITY_DATA: "SET_ALL_PRIORITY_DATA",
  SET_PROJECT_DASHBOARD_DATA: "SET_PROJECT_DASHBOARD_DATA",
  SET_ACTIVE_PROJECT: "SET_ACTIVE_PROJECT",
  SET_ALL_PROJECT_DASHBOARD_DATA: "SET_ALL_PROJECT_DASHBOARD_DATA",
  SET_ALL_MOVE_TASKS: "SET_ALL_MOVE_TASKS",
  SET_NEW_DOCUMENT_MODEL: "SET_NEW_DOCUMENT_MODEL",
  SET_NEW_WORKFLOW_MODEL: "SET_NEW_WORKFLOW_MODEL",
  SET_ALL_BACKLOGS_TASKS_VIEW: "SET_ALL_BACKLOGS_TASKS_VIEW"
}
