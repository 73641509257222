import Select from "react-select"

export function SelectBox({ selectRef, ...props }) {
  const customFilter = (option, searchText) => {
    return option?.data?.label?.toLowerCase().startsWith(searchText.toLowerCase()) ? true : false
  }

  const themechange = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: "none"
    }
  })

  return (
    <>
      <Select
        className={props.onlyAssin ? "assign_field_box" : "select-box"}
        isClearable={false}
        {...props}
        theme={themechange}
        styles={{
          control: (base) => ({
            ...base,
            "&:hover": {
              borderColor: "none"
            },
            // height: "10px",
            backgroundColor: "none",
            border: "none"
          }),
          menuList: (base) => ({
            ...base,
            width: "auto",
            backgroundColor: "#FFFFFF"
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: "#FFFFFF",
            zIndex: "9999"
          }),
          option: (styles, { isFocused }) => {
            return {
              ...styles,
              backgroundColor: isFocused ? "#2D53DA" : null,
              color: "#3E3F3E",
              height: "auto",
              width: "auto",
              paddingTop: "0px"
            }
          },
          singleValue: (styles) => ({
            ...styles,
            color: "#3E3F3E"
          })
        }}
        filterOption={customFilter}
      />
    </>
  )
}
