import React, { useEffect, useRef, useState } from 'react';
import { Chart, CategoryScale, PointElement, LineController, LineElement, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import moment from 'moment';
import { useSelector } from 'react-redux';

Chart.register(CategoryScale, PointElement, LineController, LineElement, TimeScale);

const LineGraph = ({ MyChart, xAxisNames, fromDate, toDate, nextFiveDates, TaskNames }) => {
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [chartMonthYear, setChartMonthYear] = useState("")
    const [FilterFromDate, setFilterFromDate] = useState("")
    const [FilterToDate, setFilterToDate] = useState("")

    const formatChartDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        return `${month} ${year}`;
    };

    const filterChartDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = getMonthName(date.getMonth());
        const year = date.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    const getMonthName = (monthIndex) => {
        const months = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        return months[monthIndex];
    };

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }
        drawChart();
        setChartMonthYear(formatChartDate(fromDate))
        setFilterFromDate(filterChartDate(fromDate))
        setFilterToDate(filterChartDate(toDate))
    }, [nextFiveDates]);

    const trackingClientId = useSelector(
        (state) => state.Auth.user.clientId
    );

    const xAxisNamesStrings = xAxisNames !== "" && xAxisNames?.map(item => Object.values(item)[0]?.toString());

    const newDates = nextFiveDates;
    const drawChart = () => {
        const chartCanvas = chartRef.current.getContext('2d');
        chartInstanceRef.current = new Chart(chartCanvas, {
            type: 'line',
            data: {
                labels: xAxisNamesStrings,
                datasets: [
                    {
                        data: MyChart?.filter(item => {
                                if (FilterFromDate && FilterToDate) {
                                    const reportedDate = moment(filterChartDate(item.datetime));
                                    return reportedDate.isBetween(moment(FilterFromDate), moment(FilterToDate), null, '[]');
                                }
                            })
                            .map((item) => {
                                let xVal = xAxisNames[item.currentStatus] || {}
                                return {
                                    x: Object.values(xVal).toString(),
                                    y: moment(item.datetime).format('D'),
                                };
                            }),
                        borderColor: '#5B8FF9',
                        pointRadius: 5,
                        pointHoverRadius: 12,
                },
            ],
        },
        options: {
            legend: {display: false},
            interaction: {
                mode: 'index'
            },
            plugins: {
                legend: {
                display: false // Set display to false to hide the legend
                }
            },
            scales: {
                x: {
                    type: 'category',
                    position: 'bottom',
                    title: {
                        display: true,
                        text: 'Status',
                        color: "#fff",
                        padding: {
                            top: 16
                        }
                    },
                    ticks: {
                        color: '#fff'
                    },
                    labels: xAxisNamesStrings,
                },
                y: {
                    type: 'category',
                    title: {
                        display: true,
                        text: 'Working dates',
                        color: "#fff",
                        padding: {
                            bottom: 30 
                        }
                    },
                    ticks: {
                        color: '#fff'
                    },
                    labels: newDates.reverse(),
                },
            },
            elements: {
                point: {
                    backgroundColor: 'white',
                    borderColor: '#5B8FF9',
                    borderWidth: 1,
                    color: "red"
                },
                line: {
                    backgroundColor: 'white',
                    borderColor: '#5B8FF9',
                    borderWidth: 1,
                },
            },
            responsive: true,
        },
        });
    };

    return (
        <div className='myDashboard_line_chart_container'>
            <div className='chart_task_container'>
                <p className='chart_task_title'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {chartMonthYear}
                </p>
                <p className='chart_task_title'>{trackingClientId}-{TaskNames}</p>
            </div>
            <canvas ref={chartRef} className='myDashboard_line_chart' />
        </div>
    );
};

export default LineGraph;

