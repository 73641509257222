import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useSelector } from "react-redux";
import { ReactComponent as EditIcon } from "../../../../../assets/images/icons/blue_edit_icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/icons/delete_blue_icon.svg";
import ProjectModelWrap from "./style"
import { ConfirmationBox } from '../../../../Reuseable/ConfimationBox';
import { useFetch } from '../../../../../Hooks/useFetch';

function TemplatesCard({setTaskview, setTrackingItemData, setTempView, item, setDeleteModelId, deleteConfimrationHandler, props}) {

  const trackingClientId = useSelector(
    (state) => state.Auth.user.clientId
  );
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false);
  const { data: allTemplateTableDataRes, isLoading: loading } = useFetch("get-template", `/7_templatelist/${trackingClientId}`, () => { }, () => { }, false, false)
  let allTemplateTableData = allTemplateTableDataRes?.data ?? []
  let Templatelid = item?.templateid;

  return (
    <ProjectModelWrap>
      <ConfirmationBox
        heading="Are you sure to delete this ?"
        hidePopup={(e) => setDeleteConfirmationShow(false)}
        show={deleteConfirmationShow}
        confirmationResponse={deleteConfimrationHandler}
      />
      <Container className="project_model_con">
        <div className="d-flex justify-content-between me-lg-5">
          <div>
            <div className="d-flex mb-3">
              <div className="fw-bold">Template ID:</div>
              <div className="ms-2">{item?.templateid}</div>
            </div>
            <div className="mb-3 d-flex">
              <div className="fw-bold mb-2">Template name:</div>
              <div className="ms-2">{item?.templatetitle}</div>          
            </div>
            <div className="">
              <div className="fw-bold">Template details:</div>
              <div className="">
                <div className="templates_note">
                  <span dangerouslySetInnerHTML={{ __html: item?.notes}} /> 
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex'>
            <EditIcon className="me-4 cursor_pointer"
              height={20} style={{ fill: "#2D53DA" }}
              onClick={(e) => {
                const selectedTemplate = allTemplateTableData.find(
                  (element) => element.templateid === Templatelid
                );
                setTempView("View Templates");
                setTaskview("View Templates"); 
                setTrackingItemData(selectedTemplate);
              }}
            />
            <DeleteIcon
              className='cursor_pointer'
              onClick={(e) => {
                setDeleteModelId(Templatelid)
                setDeleteConfirmationShow(true);

              }}
              style={{ fill: "#2D53DA" }}
            />
          </div>
        </div>
        <div className='view_more_content'>
          <p>View more</p>
        </div>
      </Container>
    </ProjectModelWrap>
  );
}

export default TemplatesCard