import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Alert, Spinner } from "react-bootstrap"
import Tables from "../../../../Reuseable/Tables"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setShowAlert,
  setAllProjects,
  setAllProjectsLoading,
  setProjectView,
  setBacklogs,
  setArchiveTableData,
  setReportTasksData,
} from "../../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../../Reuseable/ConfimationBox"
// import viewIcon from "../../../../assets/images/view.svg";
// import trashIcon from "../../../../assets/images/icons/bluedelete12.svg";
import { Buffer } from "buffer"
import axios from "axios"
// import norecordsvg from "../../../../assets/images/norecord.svg"
import NoDataFound from "../../Tracking/NoDataFound"
import { v4 as uuid } from "uuid"
import { useSessionStorage } from "react-use"
import ProjectsModalView from "../../Projects/style"
import moment from "moment"
import { TaskListColumns } from "./TaskListColumns"
import { TaskListHeaders } from "./TaskListheaders"
import usePrevious from "../../../../../Hooks/usePrevious"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"
import { client } from "../../../../.."

export default function ReportTasksTableData({ showProject, setShowProject, permission }) {
  // const createPermission = permission.create

  const dispatch = useDispatch()

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    true
  )
  let allTasksTypes = allTasksTypesRes?.data ?? []

  let allTrackingsList = useSelector(state => state.Tracking.allTrackings)
  // const allReportTasksData = useSelector((state) => state.Tracking.allReportTasksData);
  let myTrackingList = useSelector(state => state.Tracking.myTrackings)
  let allBacklogs = useSelector(state => state.Tracking.allBacklogs)
  let allArchiveTableData = useSelector(state => state.Tracking.allArchiveTableData)
  let allProjects = useSelector(state => state.Tracking.projects)
  const allProjectsLoading = useSelector(state => state.Tracking.projectsLoading)
  const allFlowStatus = useSelector(state => state.Tracking.allFlowStatus)
  const allMembers = useSelector(state => state.Tracking.allMembers)
  const allTaskTypes = useSelector(state => state.Tracking.allTaskTypes)
  const user = useSelector(state => state.Auth.user)
  const authToken = useSelector(state => state.Auth.authToken)
  const filters = useSelector(state => state.Tracking.filters)
  const [searchValue, setSearchValue] = useState("")
  const [searchDueDate, setSearchDueDate] = useState("")
  const [searchfromDate, setSearchFromDate] = useState("")
  // const [loading, setLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false)
  const trackingClientId = useSelector(state => state.Auth.user.clientId)
  let allStatus = useSelector(state => state.Tracking.allFlowStatus)
  let allWorkFlow = useSelector(state => state.Tracking.allWorkFlow)
  const [showView, setShowView] = useState(false)
  const [showFormView, setShowFormView] = useState(false)
  const [trackingItemData, setTrackingItemData] = useSessionStorage("backLogsTrackingData", {})
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [clonetaskData, setCloneTask] = useState({})
  const [updatedData, setUpdatedData] = useSessionStorage("projectUpdatedData", {})

  const view = useSelector(state => state.Tracking.projectView)
  const selectedProject = useSelector(state => state.Tracking.allTaskSelectedProject)

  const previousProject = usePrevious(selectedProject) || allProjects[0]?.trackingprojectid

  const isProjectExist = project => {
    const result = allProjects.find(e => e.trackingprojectid == project || e.number == project)
    return result !== undefined ? true : false
  }
  const project = isProjectExist(selectedProject)
    ? selectedProject
    : previousProject || allProjects[0]?.trackingprojectid

  let projectView = showProject ? "add project" : view
  const selected_menu = "Tablebacklogs"
  const backlogsView = "backlogs view"

  const projectViewRender = value => {
    return dispatch(setProjectView(value))
  }

  const updateAllProject = newProject => {
    dispatch(setAllProjects([...allProjects, newProject]))
    return [...allProjects, newProject]
  }

  const { data: allReportTasksDataRes, isLoading: loading } = useFetch(
    key.get_reportTasks_data,
    `/13_reports/taskreport/${trackingClientId}`,
    () => {},
    () => {},
    false,
    !client.getQueryData([key.get_reportTasks_data])?.status
  )
  let allReportTasksData = allReportTasksDataRes?.data ?? []

  const [columns, setColumns] = useState()

  const includeUuid = data => {
    return Object.entries(data).map(([k, d]) => {
      return { ...d, id: uuid() }
    })
  }

  const [selectedRows, setSelectedRows] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const formatDate = dateString => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = getMonthName(date.getMonth())
    const year = date.getFullYear()
    return `${month} ${day}, ${year}`
  }

  const getMonthName = monthIndex => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    return months[monthIndex]
  }

  const getTableData = allReportTasksData
    .filter(item => {
      if (searchValue) {
        let trackingnumber = trackingClientId + "-" + item.trackingid
        let trackingnumber1 = trackingClientId + item.trackingid
        return (
          trackingnumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          trackingnumber1.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      }

      if (searchfromDate && searchfromDate.length && searchDueDate && searchDueDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isBetween(searchfromDate, searchDueDate, "day", "[]")) {
          return item
        }
      } else if (searchfromDate && searchfromDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isSameOrAfter(searchfromDate, "day", "[]")) {
          return item
        }
      } else if (searchDueDate && searchDueDate.length) {
        const reportedDate = moment(formatDate(item.reporteddate))
        if (moment(reportedDate).isSameOrBefore(searchDueDate, "day", "[]")) {
          return item
        }
      } else {
        return item
      }
    })
    .map((item, i) => {
      let task = allTasksTypes.find(e => e.id === item.type)
      if (task) item.taskName = task.label
      const Work_Flow = Object.values(allWorkFlow).flatMap(obj => Object.values(obj))
      let workflow = Work_Flow.find(e => e.workFlowId == item.workflowid)
      if (workflow) item.workflow = workflow.workFlow
      const statusName =
        item.workflow && item.workflow[item.trackingprojectstatusid]
          ? item.workflow[item.trackingprojectstatusid]
          : ""
      item.status_name = statusName ? Object.values(statusName) : ""
      return (
        <tr key={i} val={item}>
          <td style={{ cursor: "pointer" }} className='text-center'>
            <strong className='mobHead'>Task number:</strong>
            <span
              className=''
              onClick={() => {
                setShowView(true)
                setTrackingItemData(item)
              }}
            >
              {trackingClientId}-{item.trackingid}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Task name :</strong>
            <span>{item?.title === "" ? "-" : item?.title}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Created date</strong>
            <span>
              {item.reporteddate === ""
                ? "-"
                : moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
            </span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Project name :</strong>
            <span>{item.projectname === "" ? "-" : item.projectname}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Timeline :</strong>
            <span>{item.timeline === "" ? "-" : item.timeline}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Log time :</strong>
            <span>{item.loggedTime === "" ? "-" : item.loggedTime}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Remaining time :</strong>
            <span className=''>{item.remainingTime === "" ? "-" : item.remainingTime}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Status : </strong>
            <span className='text-capitalize'>
              {item.status_name === "" ? "-" : item.status_name}
            </span>
          </td>
        </tr>
      )
    })

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: "",
  })

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters.projects,
    assignedtoFilter: filters.assignedTo,
    statusFilter: filters.status,
    priorityFilter: filters.priority,
    reportedByFilter: filters.reportedBy,
  })

  const selected_tab = "my_tasks"
  const [showFilter, setShowFilter] = useState(false)
  const [viewTaskData, setViewTaskData] = useSessionStorage("ProjectViewData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [cardui, setCardui] = useSessionStorage("cardOrList", "cardui")
  const [startAllTrackingId, setStartAllTrackingId] = useState(
    useSelector(state => state.Tracking.startAllTrackingId)
  )
  const [startMyTrackingId, setStartMyTrackingId] = useState(
    useSelector(state => state.Tracking.startMyTrackingId)
  )

  const isLoading = (columnName, ans) => {
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.name === columnName) {
        return {
          [uuid()]: { ...value, loader: ans },
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })
    setColumns(updatedColumns)
  }

  useEffect(() => {
    setViewTaskData(prevState => {
      return {
        ...prevState,
        label: trackingItemData.label,
        projectid: trackingItemData.trackingprojectid,
        startdate: trackingItemData.startdate,
        duedate: trackingItemData.duedate,
        percentage: trackingItemData.percentage,
        priority: trackingItemData.priority,
        createdby: trackingItemData.createdby,
        projectdescription: trackingItemData.projectdescription,
        budget: trackingItemData.budget,
        workflow: trackingItemData.workflow,
        fileurls: trackingItemData.fileurls,
        teammates: trackingItemData.teammates,
      }
    })
    setCloneTask(prevState => {
      return {
        ...prevState,
        title: trackingItemData.cloneTitle,
        priority: trackingItemData.priority,
      }
    })
    setUpdatedData(prevState => {
      return {
        ...prevState,
        fileurls: trackingItemData.fileurls,
        filesize: trackingItemData.filesize,
      }
    })
  }, [JSON.stringify(trackingItemData)])

  const handleFromDate = value => {
    setSearchFromDate(formatDate(value))
    if (!value) {
      setSearchFromDate([])
    }
  }

  const handleDueSearch = value => {
    setSearchDueDate(formatDate(value))
    if (!value) {
      setSearchDueDate([])
    }
  }

  const handleSearch = value => {
    setSearchValue(value)
  }

  return (
    <>
      <ProjectsModalView>
        <div className='trackingPage'>
          <Tables
            setProjectTable={true}
            columns={
              <TaskListColumns
                trackingSortings={trackingSortings}
                setTrackingSorting={setTrackingSorting}
                selectedRows={selectedRows}
                selectAll={selectAll}
              />
            }
            data={getTableData}
            showInfiniteScroll={false}
            header={
              <TaskListHeaders
                searchValue={searchValue}
                handleSearch={handleSearch}
                handleFromDate={handleFromDate}
                handleDueSearch={handleDueSearch}
                setSearchFromDate={setSearchFromDate}
                searchfromDate={searchfromDate}
                searchDueDate={searchDueDate}
                userRole={user.rolename}
                userRoleType={user.roletype}
                allMembers={allMembers}
                allProjects={allProjects}
                allReportTasksData={allReportTasksData}
                refreshAllTracking={() => {
                  // getAllProjects()
                }}
                // createPermission={createPermission}
                selectedRows={selectedRows}
                selectAll={selectAll}
              />
            }
            notfound={"No Reports Found"}
            loading={loading}
            refreshLoading={refreshLoading}
          />
        </div>
      </ProjectsModalView>
    </>
  )
}
