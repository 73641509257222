import React, { useEffect, useState } from "react"
import { Spinner, Alert } from "react-bootstrap"
import Tables from "../../../../../Reuseable/Tables"
import ProjectVersionsView from "./style"
import { setProjectVersionList } from "../../../../../../redux/Tracking/actionCreator"
import { useDispatch, useSelector } from "react-redux"
import "bootstrap/dist/css/bootstrap.min.css"
import { ProjectTaskListColumn } from "./ProjectTaskListColumn"
import { useFetch } from "../../../../../../Hooks/useFetch"
import { key } from "../../../../../../data/queryKeys"
import moment from "moment"
import ProjectModelView from "../../../ProjectModel/style"
import { api } from "../../../../../../Services/api"

export const ProjectTaskList = ({ projectTaskList, loading }) => {
  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const allProjectVersion = useSelector((state) => state.Tracking.allProjectVersion)
  const user = useSelector((state) => state.Auth.user)
  const [showAddNewTaskModal, setShowAddNewTaskModal] = useState(false)
  const [versionTitle, setVersionTitle] = useState("")
  const [versionDescription, setVersionDescription] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })
  const getTableData = projectTaskList.map((item, i) => {
    const Work_Flow = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))
    let workflow = Work_Flow.find((e) => e.workFlowId == item.workflowid)
    if (workflow) item.workflow = workflow.workFlow
    const statusName =
      item.workflow && item.workflow[item.trackingprojectstatusid]
        ? item.workflow[item.trackingprojectstatusid]
        : ""
    item.status_name = statusName ? Object.values(statusName) : ""
    return (
      <tr key={i} val={item}>
        <td className='value' style={{ cursor: "pointer" }}>
          <strong className='mobHead'>Task No:</strong>
          <span className=''>
            {item.trackingclientid}-{item.trackingid}
          </span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Title :</strong>
          <span>{item.title === "" ? "-" : item.title}</span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Task Type</strong>
          <span>
            {allTaskTypes
              .filter((e) => e.id === item.type)
              .map((e) => e.label)
              .join(", ")}
          </span>
        </td>
        <td className='value'>
          <strong className='mobHead'>Status :</strong>
          <span>{item.status_name === "" ? "-" : item.status_name}</span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Timeline :</strong>
          <span>{item.timeline === "" ? "-" : item.timeline}</span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Reported On :</strong>
          <span>
            {item.reporteddate === ""
              ? "-"
              : moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
          </span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Reported By :</strong>
          <span>
            {allMembers.find((member) => member.userId === item.reportedby)?.personname || "-"}
          </span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Assigned To :</strong>
          <span>
            {allMembers.find((member) => member.userId === item.assignedto)?.personname || "-"}
          </span>
        </td>
        <td className='value'>
          <strong className='mobHead'> Priority :</strong>
          <span>
            {item.priority === "blocker"
              ? "Blocker Level 1"
              : item.priority === "blocker2"
              ? "Blocker Level 2"
              : item.priority === ""
              ? "-"
              : item.priority}
          </span>
        </td>
      </tr>
    )
  })

  return (
    <>
      <ProjectVersionsView>
        {showAlertBox && (
          <Alert
            variant='success'
            className='text-center alert-sucess alert mt-3 mb-1'
            dismissible
            closeVariant='white'
            onClick={(e) => setShowAlertBox(false)}
          >
            {alertMessage}
          </Alert>
        )}
        <div className='d-flex justify-content-between mt-3'>
          <div>
            <p className='myDashboard_current_title'>Tasks</p>
          </div>
          <div></div>
        </div>
        {loading ? (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' className='spinner' />
          </div>
        ) : (
          <ProjectModelView>
            <Tables
              data={getTableData}
              columns={
                <ProjectTaskListColumn
                  trackingSortings={trackingSortings}
                  setTrackingSorting={setTrackingSorting}
                />
              }
              setProjectTable={true}
              myDashBoard='myDashBoard'
              notfound={"No Tasks Found"}
              // responsive = {true}
            />
          </ProjectModelView>
        )}
      </ProjectVersionsView>
    </>
  )
}
