import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import Tables from "../../../../Reuseable/Tables"
import { api } from "../../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import { ProjectDashboardColumns } from "./ProjectDashboardColumns"
import MydashboardModalView from "./style"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { SelectBox } from "../../../../Reuseable/SelectBox"
import { key } from "../../../../../data/queryKeys"
import { useFetch } from "../../../../../Hooks/useFetch"
import { QueryClient } from "react-query"

export default function ProjectMyTeam({ permission, datas, projectNow, allProjects }) {
  const client = new QueryClient()
  const authToken = useSelector((state) => state.Auth.authToken)
  const [loading, setLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const [teammateEdit, setTeammateEdit] = useState(false)
  const [projectMembersUpdate, setProjectMembersUpdate] = useState([])
  const [nameColors, setNameColors] = useState({})
  const index = allProjects?.findIndex(
    (element) => element.trackingprojectid === projectNow?.trackingprojectid
  )
  const selectRef = useRef()
  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data

  const user = useSelector((state) => state.Auth.user)
  const trackinguserid = user.userId

  useEffect(() => {
    setProjectMembersUpdate({
      teammates: projectNow?.teammates
    })

    if (projectNow && projectNow.teammates) {
      const colors = {}
      projectNow.teammates.forEach((item) => {
        const firstLetter = item.label[0].toUpperCase()
        if (!colors[firstLetter]) {
          colors[firstLetter] = generateRandomColor()
        }
      })
      setNameColors(colors)
    }
  }, [projectNow, trackinguserid])

  const generateRandomColor = () => {
    let hex = Math.floor(Math.random() * 0xffffff)
    return "#" + hex.toString(16)
  }

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const tickCloseIconCSS = {
    fontSize: "17px",
    marginTop: "-2px",
    marginLeft: "1%"
  }

  const updateTask = async (name, value) => {
    let Obj = {
      label: projectNow.label,
      projectdescription: projectNow.projectdescription,
      startdate: projectNow.startdate,
      duedate: projectNow.duedate,
      createdby: projectNow.createdby,
      priority: projectNow.priority,
      budget: projectNow.budget,
      percentage: projectNow.percentage,
      workflow: projectNow.workflow,
      teammates: name === "teammates" ? value : projectNow.teammates,
      isPriority: projectNow.isPriority ? projectNow.isPriority : false,
      fileurls: projectNow.fileurls,
      versionlist: projectNow.versionlist ? projectNow.versionlist : []
    }

    if (value) {
      api
        .patch("/trackingprojects/" + trackingClientId + "/" + projectNow.trackingprojectid, Obj, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            if (index !== -1 || index === 0) {
              allProjects[index][name] = value
              let newObj = [...allProjects]
              // dispatch(setAllProjects(newObj));
              setProjectMembersUpdate({ teammates: value })
              client.setQueryData(["get-AllProjects"], (oldData) => {
                return {
                  ...oldData,
                  data: newObj
                }
              })
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  const getTableData = projectNow?.teammates?.map((item, i) => {
    const firstLetter = item.label[0].toUpperCase()
    const color = nameColors[firstLetter]

    return (
      <tr key={i} val={item}>
        <td>
          <div className='d-flex align-items-center'>
            <div className='team-name-circle' style={{ background: color }}>
              <span>{item.label[0]}</span>
            </div>
            <div className='ms-2'>{item.label}</div>
          </div>
        </td>
      </tr>
    )
  })
  let myteamoptions = allMembers
    // ?.filter((item) => item?.userRole?.toLowerCase() === "admin")
    ?.map((item) => {
      item.value = item.label
      return { label: item.personname, value: item.userId, id: item.userId }
    })

  return (
    <>
      <MydashboardModalView>
        <div className='trackingPage'>
          <h5 className='mt-3  project_sub_head'>My Team</h5>
          <div className=' mb-2 '>
            <div className='d-flex align-items-center'>
              <div className='w-50' onClick={() => setTeammateEdit(true)}>
                <SelectBox
                  selectRef={selectRef}
                  placeholder={"Add Teammates"}
                  value={teammateEdit === true ? projectMembersUpdate.teammates : []}
                  options={
                    teammateEdit === true
                      ? myteamoptions?.map((item) => {
                          item.value = item.label
                          return item
                        })
                      : []
                  }
                  onChange={(event) => {
                    setProjectMembersUpdate(event)
                  }}
                  isMulti
                />
              </div>
              {teammateEdit === true && (
                <>
                  <div
                    className='ms-2 text-center edit-cross'
                    onClick={(e) => {
                      setTeammateEdit(false)
                      updateTask("teammates", projectMembersUpdate)
                    }}
                  >
                    <CheckIcon style={tickCloseIconCSS} />
                  </div>
                  <div className='ms-2 text-center edit-cross'>
                    <CloseIcon style={tickCloseIconCSS} onClick={() => setTeammateEdit(false)} />
                  </div>
                </>
              )}
            </div>
          </div>

          <Tables
            setProjectTable={true}
            myDashBoard='myDashBoard'
            columns={
              <ProjectDashboardColumns
                // handleSearch={handleSearch}
                trackingSortings={trackingSortings}
                setTrackingSorting={setTrackingSorting}
              />
            }
            data={getTableData?.length > 0 ? getTableData : []}
            showInfiniteScroll={false}
            notfound={"No teammates Found"}
            loading={loading}
            refreshLoading={refreshLoading}
          />
        </div>
      </MydashboardModalView>
    </>
  )
}
