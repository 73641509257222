import React, { useEffect, useState } from "react"
import AddNewPriority from "./AddNewPriority"
import { setAddPriorityView } from "../../../../redux/Tracking/actionCreator"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Spinner } from "react-bootstrap"
import Tables from "../../../Reuseable/Tables"
import { PriorityListColumns } from "./PriorityListColumns"
import PriorityViewWrap from "./style"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { client } from "../../../.."

const PriorityTable = () => {
  const dispatch = useDispatch()
  let addPriorityView = useSelector(state => state.Tracking.addPriorityView)
  const trackingClientId = useSelector(state => state.Auth.user.clientId)
  const allMembers = useSelector(state => state.Tracking.allMembers)

  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  const taskViewRender = value => {
    dispatch(setAddPriorityView(value))
  }

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: "",
  })

  const { data: allPriorityDataRes, isLoading: loading } = useFetch(
    key.get_priority_data,
    `/3_prioritylist/${trackingClientId}`,
    null,
    null,
    false,
    !client.getQueryData([key.get_priority_data])?.status
  )

  const allPriorityData = allPriorityDataRes?.data ?? []

  const formatDate = dateString => {
    const date = new Date(dateString)
    const day = date.getDate()
    const month = getMonthName(date.getMonth())
    const year = date.getFullYear()
    return `${day} ${month} ${year}`
  }

  const getMonthName = monthIndex => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ]
    return months[monthIndex]
  }

  const getTableData = allPriorityData.map((item, i) => {
    item.cloneTitle = "Clone - " + item.label
    return (
      <tr key={i} val={item} className='text-center'>
        <td style={{ cursor: "pointer" }}>
          <strong className='mobHead'>Priority ID :</strong>

          {item.priorityid}
        </td>
        <td>
          <strong className='mobHead'>Priority name :</strong>
          <span>{item?.priorityname}</span>
        </td>
        <td>
          <strong className='mobHead'>Version number :</strong>
          <span>
            <span>{item?.modelversionnumber}</span>

            {/* {item.reportedByName ||
                            allMembers.map((member) => {
                                if (member.userId === item.reportedby) {
                                    return member.personname;
                                }
                                return "";
                            })
                        } */}
          </span>
        </td>
        <td>
          <strong className='mobHead'>Project name :</strong>
          <span>
            <span>{item?.projectname}</span>
          </span>
        </td>
        <td>
          <strong className='mobHead'>SLA :</strong>
          <span>
            <span>{item?.sla}</span>
          </span>
        </td>
        <td>
          <strong className='mobHead'>Status :</strong>
          <span>
            <span>{item?.status}</span>
          </span>
        </td>
        <td>
          <strong className='mobHead'>Created by :</strong>
          <span>
            {item.createdby !== ""
              ? allMembers.map(member => {
                  if (member.userId === item.createdby) {
                    return member.personname
                  }
                  return ""
                })
              : "-"}
          </span>
        </td>
        <td>
          <strong className='mobHead'>Created on :</strong>
          <span>{item.reporteddate === "" ? "-" : formatDate(item.createdon)}</span>
        </td>
        <td>
          <strong className='mobHead'>Updated date :</strong>
          <span>{item.updateddate === "" ? "-" : formatDate(item.updateddate)}</span>
        </td>
        <td>
          <strong className='mobHead'>Updated by :</strong>
          <span>
            {item.updatedby !== ""
              ? allMembers.map(member => {
                  if (member.userId === item.updatedby) {
                    return member.personname
                  }
                  return ""
                })
              : "-"}
          </span>
        </td>
      </tr>
    )
  })

  console.log("addPriorityView", addPriorityView)
  return (
    <>
      {showAlertBox && (
        <Alert
          variant='success'
          className='text-center alert-sucess alert mt-3 mb-1'
          dismissible
          closeVariant='white'
          onClick={e => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}
      {addPriorityView === "New Priority" ? (
        <AddNewPriority
          setShowAlertBox={setShowAlertBox}
          setAlertMessage={setAlertMessage}
          setTaskview={taskViewRender}
        />
      ) : !loading || addPriorityView === "All Priority" ? (
        <PriorityViewWrap>
          <div className='priority_page'>
            <Tables
              responsive={true}
              setProjectTable={true}
              columns={
                <PriorityListColumns
                  trackingSortings={trackingSortings}
                  setTrackingSorting={setTrackingSorting}
                />
              }
              data={getTableData}
              showInfiniteScroll={false}
              notfound={"No Backlogs Found"}
              loading={loading}
            />
          </div>
        </PriorityViewWrap>
      ) : (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' className='spinner' />
        </div>
      )}
    </>
  )
}

export default PriorityTable
