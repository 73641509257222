import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Modal, Form, Row, Col, Button } from "react-bootstrap"
import { api } from "../../../../../Services/api"
import BarLoader from "react-spinners/ClipLoader"

export const Clone = ({
  showClone,
  setShowClone,
  item,
  setShowAlertBox,
  setAlertMessage,
  clonetaskData,
  setCloneTask,
  setShowAlert,
  onTaskView,
  setProjectView,
  getAllProjects
}) => {
  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const authToken = useSelector((state) => state.Auth.authToken)
  const user = useSelector((state) => state.Auth.user)
  const [loading, setLoading] = useState(false)

  const SubmitForm = () => {
    setLoading(true)
    let Obj = {
      projectname: clonetaskData.title,
      startdate: item.startdate,
      duedate: item.duedate,
      createdby: user.userName,
      priority: clonetaskData.priority,
      budget: item.budget,
      percentage: item.percentage,
      workflow: "default",
      teammates: item.teammates,
      projectdescription: item.projectdescription,
      fileurls: ""
    }

    api
      .post("/trackingprojects/" + trackingClientId, Obj, {
        headers: {
          Authorizationtoken: ` ${authToken}`
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          setShowClone(false)
          setShowAlertBox(true)
          setAlertMessage(
            "(" +
              trackingClientId +
              "-" +
              response.data.data.trackingprojectid +
              ") New Project Clone has been created successfully"
          )
          dispatch(setShowAlert(true))
          setLoading(false)
          onTaskView ? setProjectView() : setProjectView("all projects")
          getAllProjects()
        }
      })
  }

  let priorityOptions = [
    { label: "New", id: "new" },
    { label: "In progress", id: "in progress" },
    { label: "Completed", id: "completed" }
  ]

  return (
    <Modal show={showClone} onHide={(e) => setShowClone(false)} size='md' keyboard={false} centered>
      <Modal.Header closeButton className='border-0'>
        <Modal.Title>
          Clone task :{trackingClientId}-{item.trackingid}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          autoComplete='off'
          onSubmit={(e) => {
            SubmitForm()
            e.preventDefault()
          }}
        >
          <Row>
            <Col>
              <Form.Group controlId='title'>
                <Form.Label> Title: </Form.Label>
                <Form.Control
                  type='text'
                  value={clonetaskData.title}
                  placeholder='Enter Title'
                  name='title'
                  maxLength='80'
                  onChange={(e) =>
                    setCloneTask((prevState) => {
                      return { ...prevState, title: e.target.value }
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Form.Group controlId='assigned_to'>
                <Form.Label> Status : </Form.Label>
                <Form.Control
                  as='select'
                  name='assigned_to'
                  value={clonetaskData.priority}
                  onChange={(e) =>
                    setCloneTask((prevState) => {
                      return { ...prevState, priority: e.target.value }
                    })
                  }
                >
                  <option value=''>Select</option>
                  {priorityOptions.map((item, index) => (
                    <option value={item.id} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-2 mt-3'>
            <Col className='text-center' sm={12}>
              <Button
                type='button'
                size='sm'
                onClick={(e) => setShowClone(false)}
                className='fs-5 btn mx-2 my-2'
                variant='secondary'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                size='sm'
                className=' fs-5 mx-2 my-2 loginSubmitBtn btn btn-orange'
                disabled={clonetaskData.title ? false : true}
                variant='orange'
              >
                <div className="d-flex justify-content-center align-items-center gap-2 flex-nowrap">
                  {loading ? (
                    <BarLoader
                      color='#808080'
                      className='bar-loader'
                      size={14}
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <></>
                  )}
                  Submit
                </div>
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
