import React, { useState } from "react"
import backlogs_priority from "../../../../../assets/images/backlogs-priority.svg"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"

export const ArchiveListColumns = ({
  trackingSortings,
  setTrackingSorting,
  handleColumnSearch,
  searchColumn
}) => {
  const { data: archiveHeaderRes } = useFetch(
    key.get_archive_headers,
    "/masterdata/12",
    () => {},
    () => {},
    false,
    true
  )

  const allArchiveHeaderNames = archiveHeaderRes?.formStructureData

  const applyTrackingNumberSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "trackingid",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, trackingNumberSorting: "down" }
      })
  }

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        typeSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" }
      })
  }

  const applyReportedBySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "reportedby",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        prioritySorting: "",
        assignedToSorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, reportedBySorting: "down" }
      })
  }

  const applyStatusSorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "status",
        trackingNumberSorting: "",
        projectNameSorting: "",
        typeSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        assignedToSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, statusSorting: "down" }
      })
  }

  const [clickSearchIcon, setClickSearchIcon] = useState(false)
  const [assingedSearchIcon, setAssingedSearchIcon] = useState(false)
  let ArchivecolumnValues = []
  if (allArchiveHeaderNames && allArchiveHeaderNames.length) {
    for (let i = 0; i < allArchiveHeaderNames.length; i++) {
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column1)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column2)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column3)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column4)
      // ArchivecolumnValues.push(allArchiveHeaderNames[i].column5)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column6)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column7)
      ArchivecolumnValues.push(allArchiveHeaderNames[i].column8)
    }
  }
  return (
    <>
      {ArchivecolumnValues.map((item, index) => {
        const handleSortingClick = () => {
          if (item === "Task No.") {
            applyTrackingNumberSorting(trackingSortings.trackingNumberSorting)
          } else if (item === "Status") {
            applyStatusSorting(trackingSortings.statusSorting)
          } else if (item === "Reported Date") {
            applyReportedBySorting(trackingSortings.reportedBySorting)
          } else if (item === "Due Date") {
            applyReportedBySorting(trackingSortings.reportedBySorting)
          }
          // else if (item === "Assigned To") {
          //     applyAssignToSorting(trackingSortings.assignedToSorting);
          // }
          else if (item === "Priority") {
            applyPrioritySorting(trackingSortings.prioritySorting)
          }
        }
        return (
          <th
            // className={
            //   item === "Status" ||
            //   item === "Reported Date" ||
            //   item === "Reported By" ||
            //   item === "Due Date" ||
            //   item === "Assigned To" ||
            //   item === "Priority"
            //     ? "text-center"
            //     : ""
            // }
            key={index}
            onClick={handleSortingClick}
          >
            {/* {item === "Reported By" || item === "Assigned To" ? <img src={backlogs_search} alt="backlogs_search" className="px-1 column_search_icon" onClick={() => {
                            if (item === "Reported By") { setClickSearchIcon(!clickSearchIcon); setAssingedSearchIcon(false) }
                            
                            if (item === "Assigned To") { setAssingedSearchIcon(!assingedSearchIcon); setClickSearchIcon(false);}
                        }}
                        /> : <></>} */}
            {item}
            {item === "Status" || item === "Due Date" || item === "Priority" ? (
              <img
                src={backlogs_priority}
                alt='backlogs_search'
                className='px-1 column_search_icon'
              />
            ) : (
              <></>
            )}
            {(item === "Reported By"
              ? clickSearchIcon
              : item === "Assigned To"
              ? assingedSearchIcon
              : "") && (
              <div className='column_search_input_box'>
                <input
                  type='text'
                  placeholder='Search name'
                  className='column_search_input'
                  name={searchColumn}
                  value={searchColumn ? searchColumn : ""}
                  onChange={(e) => handleColumnSearch(e.target.value)}
                />
              </div>
            )}
          </th>
        )
      })}
    </>
  )
}
