import React, { useEffect, useState } from "react"
import { Spinner, Form, Alert } from "react-bootstrap"
import Tables from "../../../../../Reuseable/Tables"
import ProjectVersionsView from "./style"
import { setProjectVersionList } from "../../../../../../redux/Tracking/actionCreator"
import { useDispatch, useSelector } from "react-redux"
import { api } from "../../../../../../Services/api"
import { ProjectVersionsColumns } from "./ProjectVersionsColumns"
import UIModal from "../../../../../Reuseable/Modal"
import BarLoader from "react-spinners/ClipLoader"
import moment from "moment"
import { client } from "../../../../../.."
import { key } from "../../../../../../data/queryKeys"

export const ProjectVersions = ({
  data,
  projectNow,
  setShowAddNewWorkflow = () => {},
  permission
}) => {
  const trackingprojectid = projectNow.trackingprojectid
  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const [loading, setLoading] = useState(false)
  let allProjectVersion = useSelector((state) => state.Tracking.allProjectVersion)
  const user = useSelector((state) => state.Auth.user)
  const [showAddNewTaskModal, setShowAddNewTaskModal] = useState(false)
  const [versionTitle, setVersionTitle] = useState("")
  const [versionDescription, setVersionDescription] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)

  const createPermission = permission?.create

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })
  const getTableData = allProjectVersion
    ?.sort((a, b) => b.versionid - a.versionid)
    ?.map((item, i) => {
      return (
        <tr key={i} val={item}>
          <td className='text-center' style={{ cursor: "pointer" }}>
            <strong className='mobHead'>Version:</strong>
            <span className='ps-1'>{item?.versionid}</span>
          </td>
          <td className='description_version_td'>
            <strong className='mobHead'>Description :</strong>
            <span>
              {/* {item.description === "" || item.description === null || item.description === undefined ? (
                        <span dangerouslySetInnerHTML={{ __html: "-" }} />
                        ) : (
                        <span dangerouslySetInnerHTML={{ __html: item.description }} />
                        )} */}
              {item.releasenote === "" ? "-" : item.releasenote}
            </span>
          </td>
          <td>
            <strong className='mobHead'>Aliases</strong>
            <span>{item.title === "" ? "-" : item.title}</span>
          </td>
          <td>
            <strong className='mobHead'>Created On :</strong>
            <span>
              {item.reporteddate === ""
                ? "-"
                : moment(item.releasedate).format("MMM D, YYYY hh:mm A")}
            </span>
          </td>
          <td>
            <strong className='mobHead'>Created by :</strong>
            <span className=''>{item.createdby === "" ? "-" : item.createdby}</span>
          </td>
        </tr>
      )
    })

  const SubmitForm = () => {
    setLoading(true)
    let Obj = {
      releasenote: versionDescription,
      title: versionTitle,
      createdby: user.userName
    }
    api.post("/projectversion/" + trackingprojectid, Obj).then(function (response) {
      if (response.status === 200) {
        setLoading(false)
        setShowAlertBox(true)
        setShowAddNewTaskModal(false)

        client.invalidateQueries({
          queryKey: [key.get_project_versions]
        })

        setAlertMessage("New version has been created successfully")
        setShowAddNewTaskModal(false)
      }
    })
  }

  return (
    <>
      <ProjectVersionsView>
        {showAlertBox && (
          <Alert
            variant='success'
            className='text-center alert-sucess alert mt-3 mb-1'
            dismissible
            closeVariant='white'
            onClick={(e) => setShowAlertBox(false)}
          >
            {alertMessage}
          </Alert>
        )}
        <div className='d-flex justify-content-between mt-3 mb-3'>
          <div className='align-items-center d-flex'>
            <p className='myDashboard_current_title project_sub_head'>Versions</p>
          </div>
          {createPermission ? (
            <div>
              <button
                onClick={() => setShowAddNewTaskModal(!showAddNewTaskModal)}
                className='version_create_btn'
              >
                Create version
              </button>
            </div>
          ) : (
            ""
          )}
        </div>
        {loading ? (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' className='spinner' />
          </div>
        ) : (
          <Tables
            data={getTableData}
            columns={
              <ProjectVersionsColumns
                trackingSortings={trackingSortings}
                setTrackingSorting={setTrackingSorting}
              />
            }
            setProjectTable={true}
            myDashBoard='myDashBoard'
            notfound={"No data Found"}
            responsive={true}
          />
        )}

        <UIModal
          title={"Create Version"}
          showModal={showAddNewTaskModal}
          setShowModal={setShowAddNewTaskModal}
        >
          <div className='version_modal_container p-2'>
            <p>
              Publishing a new version saves a snapshot of the code and the configuration of the
              $LATEST version. You need to deploy code changes in $LATEST before you can create a
              new version. Any triggers you added to the function are not saved to the new version.
              You can't change the new version's code. Choose Publish to confirm. To change
              SnapStart, navigate to SLATEST and choose the Configuration tab. On the General
              configuration pane, choose Edit.
            </p>
            <div>
              <label className='text-white'>Version title</label>
              <Form.Control
                type='text'
                placeholder=''
                onChange={(e) => setVersionTitle(e.target.value)}
              />
              <label className='text-white mt-3'>
                Version description - <i>optional</i>
              </label>
              <Form.Control
                as='textarea'
                rows={3}
                onChange={(e) => setVersionDescription(e.target.value)}
              />
            </div>
            <div className='version_buttons mt-4'>
              <button
                onClick={() => setShowAddNewTaskModal(false)}
                className='me-4 version_create_btn'
              >
                Cancel
              </button>
              <button
                className='version_create_btn'
                onClick={() => {
                  SubmitForm()
                }}
              >
                {loading ? (
                  <BarLoader
                    className='bar-loader'
                    color='#808080'
                    size={14}
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <></>
                )}{" "}
                Create
              </button>
            </div>
          </div>
        </UIModal>
      </ProjectVersionsView>
    </>
  )
}
