import Styled from "styled-components";

const DocumentWrap = Styled.div`
.modal-image{
  display: flex;
  align-items: center;
  justify-content: center;
}
file-conatiner{
  height: 100vh;
}
.doc-block{
    display: inline-flex;
}
.file{
    display: inline-flex;
    flex-direction: row;    
  }
  .filename{
    width: 130px;
 font-size: 13px;
 color: ${({ theme }) => theme["themeColor"]};
 opacity: 0.8;
 letter-spacing: .2px;
  }
  .file-card{
    padding: 0;
  }
  .file_details{
    overflow: hidden;
    width: 150px;
    height: 60px;
    background-color: ${({ theme }) => theme["searchBar"]}; 
    display: inline-flex;
    flex-direction: row;
  }

  .icon{
     position: absolute;
    float: right;
    left: 120px;
  }
  .content-wrapper{
    overflow-x: auto ! important;
  }
  .btn:active{
    color : transparent;
    background-color: transparent;
  }
  .file_block{
    opacity: 0.7;
  }
  .closeicon{
    margin-top: 2px;
    color: #2D53DA;
    float: left;
  }
   .btn-close {
    color: white !important;
  }
  .cardIcon {
    font-size: 12px;
  }
  .image-card{
    border-radius: 2px;
  }
  .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: none;
    &::after {
      display: none !important;
    }
}
    .dropdown-toggle:focus {
        background-color: transparent;
        border: none;
        outline: none;
      }
    
      .dropdown-menu.show {
        height: auto;
        min-height: 40px !important;
        min-width: 120px !important;
        overflow-x: hidden;
      }
  .dropdown-item {
  padding: 4px 0px 2px 0px;
  &:hover {
    background-color: hsla(201, 82%, 62%, 0.2) !important;
  }
 }
  .cardIcon {
    color: #2D53DA;
    cursor: pointer;
    background-color: transparent;
  }
  .dropenu {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    button {
      padding: 2px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border: none;
      outline: none;
      border-radius: 8px;
      font-size: 14px;
      display: inline-block;
    }
  }
  .dropdown-toggle {
    background-color: transparent;
    outline: none;
    border: none;
}
.dropdown-toggle::after {
display: none;
}
.table-view-card
{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    margin-top: 10px;
}
.mobHead{
    display: none;
}
.documentPage h3{
    font: normal normal 600 15px/26px Montserrat;
    color: #FF9B15;
    margin-top: 10px;
}
.document-btn{
    background: #FF9B15;
    border: #FF9B15;
    font: normal normal 600 15px/20px Montserrat;
    padding: 6px 16px 6px 16px;
    float: right;
    margin-bottom: 5px;
}
.document-btn:active{
    background: #FF9B15;
    border: #FF9B15;
}
.icon-color{
    color: #ff9b15;
    width: 16px;
}
.nav-tabs .nav-link {
    border: none;
}
.tasks-tabs .nav-link.active {
    color:  #FF9B15;
    border: none;
}
.tasks-tabs  .nav-link:hover {
    border: none;
}
.workflowPage table thead th {
    white-space: nowrap;
    width: 22%;
}
@media (max-width: 1023px) {
    .mobHead{
        display: unset;
    }
    .mobSubhead{
        margin-left: 10px;
    }
    .table thead th {
        display: none;
    }
    .actionBtns{
        text-align: center;
    }
    .table tbody tr td {
        white-space: break-spaces;
        padding: 10px 15px !important;
        width: auto !important;
        display: block;
    }
}

`;

export default DocumentWrap;