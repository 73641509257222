import React, { useState } from "react"
import backlogs_priority from "../../../../../assets/images/backlogs-priority.svg"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"
import { client } from "../../../../.."

export const BacklogsListColumns = ({
  trackingSortings,
  setTrackingSorting,
  searchColumn,
  handleColumnSearch
}) => {
  const [backlogsSearchIcon, setBacklogsSearchIcon] = useState(false)

  const { data: allBackLogsHeaderNamesRes } = useFetch(
    key.get_backlogs_headers,
    "/masterdata/11",
    (res) => {
      const { formStructureData } = res
      const sortedFormHeaders = formStructureData.sort((a, b) => {
        return a.column1.localeCompare(b.column1)
      })
      client.setQueryData([key.get_backlogs_headers], (oldData) => {
        return {
          ...oldData,
          formStructureData: sortedFormHeaders
        }
      })
    },
    () => {},
    false,
    false
  )

  const allBacklogsHeaderNames = allBackLogsHeaderNamesRes?.formStructureData ?? []

  const applyPrioritySorting = (state) => {
    setTrackingSorting((prevState) => {
      return {
        ...prevState,
        selectedSortingItem: "priority",
        trackingNumberSorting: "",
        projectNameSorting: "",
        reportedBySorting: "",
        prioritySorting: "",
        statusSorting: ""
      }
    })
    if (state == "down")
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "up" }
      })
    else
      setTrackingSorting((prevState) => {
        return { ...prevState, prioritySorting: "down" }
      })
  }

  let BacklogscolumnValues = []

  for (let i = 0; i < allBacklogsHeaderNames.length; i++) {
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column1)
    // BacklogscolumnValues.push(allBacklogsHeaderNames[i].column2)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column3)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column4)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column5)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column6)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column7)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column8)
    BacklogscolumnValues.push(allBacklogsHeaderNames[i].column9)
  }

  return (
    <>
      <th className='backlogs_headers_table' style={{ width: "20px" }}></th>
      {BacklogscolumnValues.map((item, index) => {
        const handleSortingClick = () => {
          if (item === "Priority") {
            applyPrioritySorting(trackingSortings.prioritySorting)
          }
        }
        return (
          <th
            className='text-start backlogs_headers_table'
            // style={backlogs_headers_table}
            key={index}
            onClick={() => {
              handleSortingClick()
            }}
          >
            {item === "Priority" ? (
              <div className='backlogs_priority'>
                <span>{item}</span>
                <img src={backlogs_priority} alt='backlogs_priority' />
              </div>
            ) : (
              <>{item}</>
            )}

            {item === "Created by" && backlogsSearchIcon && (
              <div className='column_search_input_box'>
                <input
                  type='text'
                  placeholder='Search name'
                  className='column_search_input'
                  name={searchColumn}
                  value={searchColumn ? searchColumn : ""}
                  onChange={(e) => handleColumnSearch(e.target.value)}
                />
              </div>
            )}
          </th>
        )
      })}
    </>
  )
}
