import Styled from 'styled-components';

const ProjectVersionsView = Styled.div`

.card-body {
    .row{
        div{
            margin-bottom:0px !important;
        }
    }
    padding:0px !important;
}
.table-view-card {
    /* background-color: transparent; */
    background-color: ${({ theme }) => theme["tableViewCard"]};

}
.template_modal_container.modal .modal-content {
    width: 550px;
    border-radius: 10px;
    background-color: white;
    padding: 12px 12px 0px 12px;
  }
.template_select_btn {
    width: 150px;
    height: 35px;
    background: #2D53DA
}
.version_description {
    width: 80%;
    height: 75px;
}
.version_buttons{
    display: flex;
    justify-content:end;
}
.description_version_td{
    width:35%;
}
@media (min-width: 992px) {
.mobHead
    {
        display:none;
    }
}

`

export default ProjectVersionsView;