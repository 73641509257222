import React from "react"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from "chart.js"
import { Bar } from "react-chartjs-2"
import { getRandomColor } from "../../../../../Utilities/commonFunctions"

export const ProjectDashboradBarChart = ({ convertedMonthTypeCounts, monthTypeCounts }) => {
  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // cutoutPercentage: 20,
    // cutout: '10%',

    plugins: {
      legend: {
        position: "top",
        align: "end",
        labels: {
          boxWidth: 10,
          color: "black"
        }
      },
      title: {
        display: true,
        text: "Tasks",
        position: "top",
        align: "start",
        color: "black"
      }
    },
    scales: {
      x: {
        ticks: {
          color: "black"
        }
      },
      y: {
        ticks: {
          color: "black",
          stepSize: 3
        }
      }
    }
  }

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]

  const datasets = []

  monthTypeCounts?.forEach((entry) => {
    const key = Object.keys(entry)[0]
    const values = entry[key]

    let label = ""
    switch (key) {
      case "1":
        label = "Bug/Issue"
        break
      case "2":
        label = "New Task"
        break
      case "3":
        label = "Request for Change"
        break
      case "4":
        label = "Other"
        break
      default:
        label = "Other"
    }

    const datasetData = labels.map((month) => {
      const entry = values.find((item) => Object.keys(item)[0] === month)
      const value = entry ? entry[month] : 0
      return { x: month, y: value }
    })

    datasets.push({
      label: label,
      data: datasetData,
      backgroundColor: getRandomColor(),
      borderWidth: 1,
      barPercentage: 0.5,
      categoryPercentage: 0.5
    })
  })

  const data = {
    labels,
    datasets: datasets
  }

  return (
    <div className='project_barchart_con'>
      <Bar options={options} data={data} className='project_barchart_canvas' />
    </div>
  )
}
