import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Col, Container, Row } from "react-bootstrap"
import { Alert, Grid } from "@mui/material"
import { PricingPlansWrap } from "../../Login/PricingPlans"
import Plan from "../../Login/Plan"
import { planInformationPlans } from "../../Login/Pricings"
import Subscribe from "../../Layout/subscription/Subscribe"
import UIModal from "../../../Reuseable/Modal"
import { api } from "../../../../Services/api"
import { client } from "../../../.."
import { key } from "../../../../data/queryKeys"
import { useFetch } from "../../../../Hooks/useFetch"

const PlanInformation = () => {
  const [alertMessage, setAlertMessage] = useState("")
  const [showUpgrade, setShowUpgrade] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState("Free")
  const user = useSelector((state) => state.Auth.user)
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const darkmode = useSelector((state) => state.Layout.darkmode)

  const { data: planInformation } = useFetch(
    key.get_trial_period,
    `/trackingadmin/clients/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false,
    (data) => data?.client
  )

  const plans = planInformationPlans.map((pricingPlan) => {
    return pricingPlan.tier === planInformation?.subscriptionPlan
      ? { ...pricingPlan, checked: true }
      : pricingPlan
  })

  const dispatch = useDispatch()

  const handlePlans = (id) => {
    // const plan = plans.find((plan) => plan.id === id)?.tier;
    // setSubscriptionPlan(plan);
    // setAlertMessage("Click the Upgrade button to change your plan");
  }

  const createPlanSubscription = async (data) => {
    try {
      const url = "/trekrpayment/create"
      const response = await api.post(url, data)
      if (response) {
        client.invalidateQueries({
          queryKey: [key.get_trial_period]
        })
      }
      return true
    } catch (error) {
      console.log("error", error)
    }
  }

  const changePlanStatus = async (data) => {
    try {
      const url = "/trekrpayment/change"
      const response = await api.post(url, data)
      if (response) {
        client.invalidateQueries({
          queryKey: [key.get_trial_period]
        })
      }
      return true
    } catch (error) {
      console.log("error", error)
    }
  }

  const setSubscriptionPlan = async (plan) => {
    let data = {
      subscriptionPlan: plan,
      clientid: trackingClientId,
      emailid: user.userId
    }

    if (selectedPlan === "") {
      dispatch(setAlertMessage("Please select a plan"))
      return
    }

    console.log(
      "setSubscriptionPlan  planInformation?.subscriptionStatus-->",
      planInformation?.subscriptionStatus
    )
    if (planInformation?.subscriptionStatus === "") {
      const res = await createPlanSubscription(data)
      if (res) {
        changePlanStatus(data)
      }
    } else {
      changePlanStatus(data)
    }

    return false
  }
  return (
    <>
      <h5 className='mb-4'>Plan Information</h5>

      {alertMessage && (
        <Alert severity='info' dismissible onClick={(e) => setAlertMessage("")}>
          {alertMessage}
        </Alert>
      )}

      <PricingPlansWrap darkmode={darkmode}>
        <Container>
          <Row>
            {plans.map((plan) => {
              return (
                <Col
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={3}
                  key={plan.id}
                  className={`d-flex justify-content-center pb-2 pb-sm-4`}
                >
                  <Plan
                    tier={plan.tier}
                    price={plan.price}
                    title={plan.title}
                    duration={plan.duration}
                    benefits={plan.benefits}
                    checked={plan.checked}
                    handlePlans={handlePlans}
                    planInfoPage={true}
                    planId={plan.id}
                    mobileScreen={false}
                    button={plan.button}
                    setShowUpgrade={setShowUpgrade}
                    setSelectedPlan={setSelectedPlan}
                    setSubscriptionPlan={setSubscriptionPlan}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </PricingPlansWrap>

      <UIModal
        title={"Upgrade Your Plan"}
        showModal={showUpgrade}
        setShowModal={setShowUpgrade}
        size='xl'
      >
        <Subscribe
          subscriptionPlan={selectedPlan}
          setShowSubscribe={setShowUpgrade}
          currentStep={() => {}}
        />
      </UIModal>
    </>
  )
}

export default PlanInformation

const Information = ({ info1, info2 }) => {
  return (
    <Grid item xs={12} sx={{ display: "flex", gap: "50px" }}>
      <span style={{ width: "180px" }}>{info1}</span>
      <span>{info2}</span>
    </Grid>
  )
}
