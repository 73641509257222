import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Button, Spinner } from "react-bootstrap"
import Searchbox from "../../../../Reuseable/Searchbox"
import { setFilters } from "../../../../../redux/Tracking/actionCreator"
import plus from "../../../../../assets/images/icons/plus.svg"
import download from "../../../../../assets/images/icons/download1.svg"
import RefreshIcon from "@mui/icons-material/Refresh"

export const ProjectsListHeader = ({
  setProjectView,
  searchValue,
  handleSearch,
  userRole,
  userRoleType,
  allMembers,
  cardView,
  refreshAllTracking,
  loading,
  allProjects,
  setTrackingFilters,
  createPermission
}) => {
  const item = allProjects.map((ele) => ele)

  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  if (allProjects?.length > 0) {
    let result = []
    allProjects?.forEach((element) => {
      result.push({ label: element.label, value: element.id })
    })
    allProjects = result
  }

  if (allMembers?.length > 0) {
    let result = []
    allMembers?.forEach((element) => {
      result.push({ label: element.personname, value: element.trackinguserid })
    })
    allMembers = result
  }

  function removeHTML(str) {
    var tmp = document.createElement("DIV")
    tmp.innerHTML = str
    return tmp.textContent || tmp.innerText || ""
  }
  const downloadList = () => {
    var A = [
      [
        "Project ID",
        "Project Name",
        // "Description",
        "Estimated Start Date",
        "Estimated Due Date",
        "Created By",
        "Priority"
      ]
    ]
    var csvRows = []

    item?.forEach((element) => {
      // console.log('item.forEach ~ element projectdescription', removeHTML(element.projectdescription))
      // element.label = element.label.replace(/,/g, "");
      A.push([
        element.trackingprojectid,
        element.label.replace(/,/g, ""),
        // (removeHTML(element.projectdescription)).replace(/,/g, "").trim(),
        element.startdate,
        element.duedate,
        element.createdby.replace(/,/g, ""),
        element.priority
      ])
    })

    for (var i = 0, l = A.length; i < l; ++i) {
      csvRows.push(A[i].join(","))
    }

    var csvString = csvRows.join("\n")
    var a = document.createElement("a")
    a.href = "data:attachment/csv," + encodeURIComponent(csvString)
    a.target = "_blank"
    a.download = `${trackingClientId}_project_list.csv`
    document.body.appendChild(a)
    a.click()
  }

  return (
    <>
      <Row>
        <Col lg={3} md={12}>
          {" "}
          <Searchbox
            handleSearch={handleSearch}
            value={searchValue}
            placeholder={"Project name"}
          />{" "}
        </Col>
        <Col lg={9} md={12} className='text-end addTrack'>
          {/* <Link to="/new-tracking" > */}
          {createPermission && (
            <Button
              className='me-1'
              onClick={() => setProjectView("add project")}
              style={{
                padding: "0px 0px",
                backgroundColor: "transparent",
                borderColor: "transparent"
              }}
            >
              <img src={plus} alt='plus' style={{ cursor: "pointer" }} />
            </Button>
          )}
          <Button
            className='me-1 btn-rad'
            style={{
              padding: "0px 0px",
              backgroundColor: "#2D53DA",
              borderColor: "#2D53DA",
              width: "28px",
              height: "28px"
            }}
            onClick={() => {
              refreshAllTracking()
            }}
          >
            {loading ? <Spinner size='sm' variant='light' animation='border' /> : <RefreshIcon />}
          </Button>
          <Button
            className='me-1'
            style={{
              padding: "0px 0px",
              backgroundColor: "transparent",
              borderColor: "transparent"
            }}
            onClick={(e) => {
              downloadList()
            }}
          >
            <img src={download} alt='dwnld' style={{ cursor: "pointer" }} />
          </Button>
        </Col>
      </Row>
    </>
  )
}
