import { createGlobalStyle } from "styled-components"

export const GlobalStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  body {
    font-family: ${({ theme }) => theme["font-stack"]}!important;
  }
  body:has(#registrationForm) {
    background-color: #151d25;
  }
  /* body::-webkit-scrollbar{
    display:none;
    scrollbar-width: none;
  } */
  /* To fix the table white background  */
  table.mb-0.table.table-sm.md.lg.xl.xxl.table-striped.table-bordered.table-hover {
    --bs-table-bg: unset !important;
  }
  input.form-control.search-control.border-start-0::placeholder {
    color: #CCCCCC !important;
  }
  #workflowname::placeholder {
    color: #CCCCCC !important;
  }
  .modal-body {
  .table-view-card {
    .card-body{
      .table-responsive{
        overflow: unset;
      }
      background-color: ${({ theme }) => theme["tableViewCard"]};
      overflow: hidden auto;
      max-height: 60vh;
      padding: 0px;
    }
    .card-body::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 10px;
      width: 5px;
    }    
    .card-body::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: transparent;
    }
  }
}
  .taskconatiner,
  .projectconatiner,
  .dragContainer {
    overflow-x: hidden;
    width: 100%;
    max-height: calc(100vh - 140px);
    overflow-y: scroll;
  }
  .taskconatiner::-webkit-scrollbar,
  .projectconatiner::-webkit-scrollbar,
  .dragContainer::-webkit-scrollbar {
    height: 6px;
    width: 8px;
    background-color: transparent;
  }
  .taskconatiner::-webkit-scrollbar-thumb:horizontal,
  .projectconatiner::-webkit-scrollbar-thumb:horizontal,
  .dragContainer::-webkit-scrollbar-thumb:horizontal {
    background-color: #fff;
    border-radius: 10px;
  }  
  .taskconatiner::-webkit-scrollbar-thumb:vertical,
  .projectconatiner::-webkit-scrollbar-thumb:vertical,
  .dragContainer::-webkit-scrollbar-thumb:vertical {
    background-color: #fff;
    border-radius: 10px;
    width: 5px;
  } 
  .version_modal_container.modal .modal-content {
    width: 550px;
    border-radius: 10px;
    background-color: #3e3f3e;
    padding: 12px;
  } 
  .cancelbttn{
    color: ${({ theme }) => theme["canbtnclr"]} ! important;
  }
  .btn-close{
    opacity:1;
  }
  h1, h2, h3, h4, h5, h6, 
  .h1, .h2, .h3, .h4, .h5, .h6
  {
    font-family: ${({ theme }) => theme["font-stack"]}!important;
    color: ${({ theme }) => theme["themeColor"]};
  }
  .dashboard-heading{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .due-date{
    color: ${({ theme }) => theme["dateColor"]};
  }
  .select-options{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius:12px;
    color: ${({ theme }) => theme["themeColor"]};
  }
  * {
    &:focus {
        outline: inherit;
    }
    box-sizing: border-box;
  }
  .form-select{
    border: 1px solid  ${({ theme }) => theme["normalColor"]};

  }
  .form-select:focus {
    border-color: #ced4da;
    outline: 0;
    box-shadow: inherit;
  }
  .mandatorySign{
    color: #2D53DA;
  }
  .select-box{
    width: 100%;
    border: 1px solid  ${({ theme }) => theme["normalColor"]};
    border-radius: 5px;
    &:hover {
      border: 1px solid  ${({ theme }) => theme["focusColor"]};
      }
    .css-qc6sy-singleValue{
      color: ${({ theme }) => theme["inputColor"]};
    }
    input{
      color: ${({ theme }) => theme["inputColor"]}!important;
    }  
  }
  .backlogs_radio_btns{
    display: flex;
    gap: 3rem;
    margin-top: 0.4rem;
  }
  .start_date .ant-picker-suffix{
    color: #FFFFFF;
  }
  .end_date .ant-picker-suffix{
    color: #FFFFFF;
  }
  .assign_field_box{
    width: 100%;
  }
  .add-new-task{
    color: ${({ theme }) => theme["inputColor"]};
  }
  .loginSubmitBtn
  {
    position:relative;
  }
  .loginSubmitBtn .spinner-grow
  {
    position: absolute;
    left: 10%;
    top: 50%;
    margin-top: -7px;
  }

  .loginformbody .bar-loader{
    margin-right: 5px;
  }
  .loginformbody .text-center .btn 
  {
    width:178px;
    height: 38px;
  }
  .quickTask {
    padding: 0px 0px !important;
    width: 80px !important;
    height: 24px !important;
  }
  .quickCancel {
    padding: 0px 0px !important;
    width: 80px !important;
    height: 24px !important;
    color: #FFFFFF !important;
  }

  .btn{
    &.btn-orange {
      color: #fff;
      background-color:#2D53DA;
      border-color:#2D53DA;
      margin: 0 auto;
      font-size: 14px;
      padding: 4px;
      &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme["orange-hover"]};
        border-color:${({ theme }) => theme["orange-hover"]};
      }
    }
  }
  .btn_backlogs_sec{
    display: flex;
  }
  .btn_backlogs_orange {
      color: #fff;
      background-color:#2D53DA;
      border-color:#2D53DA;
      margin: 0 auto;
      font-size: 14px;
      padding: 4px;
      width: 155px;
      height: 35px;
      &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme["orange-hover"]};
        border-color:${({ theme }) => theme["orange-hover"]};
    }
  }

  .trackingviewtbody{
    background-color: ${({ theme }) => theme["tableViewCard"]} !important;
  }

  .trackingviewtable > :not(caption) > * > * {
    background-color: ${({ theme }) => theme["tableViewCard"]} !important;
  }

  a {
    color: ${({ theme }) => theme["orange-color"]};
    text-decoration: none;
    &:hover {
      color: ${({ theme }) => theme["orange-hover"]};
      text-decoration: none;
    }
  }
  .btn-close {
    &:focus {
      box-shadow: inherit !important;
    }
  }
  .modal {
    .modal-content {
      border-radius: 10px;
      background-color: ${({ theme }) => theme["tableViewCard"]};
    }
  }

  .fade.modal.show:has(#otp-modal-body) {
    .modal-content {
          border-radius: 10px;
          background-color: white;
      }
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px)
  }

  .loginformbody{
    background-color: ${({ theme }) => theme["tableViewCard"]};
  }

  .loginformbody .form-label{
    color: ${({ theme }) => theme["themeColor"]};
    margin-top: 15px;
  }

  .menu-icon{
    color: #2d53da !important;
  }

  .loader-layout{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner{
    color: #2d53da !important;
  }

  .loginformbody .form-control{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border: 1px solid  ${({ theme }) => theme["normalColor"]};
    border-radius: 5px;
    color: ${({ theme }) => theme["inputColor"]};
  }  
  .sc-hKMtZM{
      border: 1px solid  ${({ theme }) => theme["normalColor"]};
      &:hover{
        border: 1px solid  ${({ theme }) => theme["focusColor"]};
      }
      .rdw-editor-toolbar{
        border: 1px solid  ${({ theme }) => theme["normalColor"]};
      &:hover{
        border: 1px solid  ${({ theme }) => theme["focusColor"]};
      }
    }
  }
  .loginformbody .form-check-label{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .card-bd{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    color: ${({ theme }) => theme["themeColor"]};
  }
  .form-check-input:checked{
    background-color:#2D53DA;
    border-color:#2D53DA;
  }
  .form-check-input:focus{
    border-color: #d5d5d5;
    box-shadow: 0 0 0 0.25rem ${({ theme }) => theme["tableViewCard"]};
  }
  .btn-rad{
    border-radius: 5px;
  }
  .for_assigned_to{
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme["normalColor"]} !important;
    &:hover{
      border: 1px solid ${({ theme }) => theme["focusColor"]} !important;
    }
  }
  .refresh_btn{
    color: ${({ theme }) => theme["normalColor"]};
    border: none;
    &:hover{
      color: ${({ theme }) => theme["focusColor"]};
    }
  }
  .d-block{
    color: #2D53DA;
  }
  .cursor
  {
    cursor : pointer;
  }

  .form-control {
    ::placeholder{
        color: #7b8080;
      }
    &:focus {
      box-shadow: none !important;
    }
  }
  .btn-check {
    &:focus {
      + {
        .btn {
          box-shadow: none !important;
          outline: 0;
          box-shadow: none;
        }
      }
    }
  }
  .btn {
    &:focus {
      box-shadow: none !important;
      outline: 0;
      box-shadow: none;
    }
  }

  button:focus {
    outline: inherit;
  }

  *{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .rage_filter_date{
    width: 50%;
  }
  .reported_from_date{
    width: 100%;
    height: 38px;
    border-radius: 5px;
    border:1px solid hsl(0, 0%, 80%);
    box-shadow: none;
    &:focus{
      border: 2px solid #2684FF;
    }
  }
  button,
  html,
  input,
  select,
  textarea {
    font-family: ${({ theme }) => theme["font-stack"]}!important;
  }

  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input::-ms-clear,
  input::-ms-reveal {
    display: none;
  }

  input[type="text"]::-ms-clear {
    display: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-control input::-webkit-input-placeholder {
    color: ${({ theme }) => theme["gray-color"]};
  }

  .form-control input::-moz-placeholder {
    color: ${({ theme }) => theme["gray-color"]};
  }

  .form-control input:-ms-input-placeholder {
    color: ${({ theme }) => theme["gray-color"]};
  }

  .form-control input:-moz-placeholder {
    color: ${({ theme }) => theme["gray-color"]};
  }

  input:focus {
    outline: inherit;
  }

  select:focus {
    outline: inherit;
  }

  .btn.focus, .btn:focus {
    outline: inherit;
    box-shadow: inherit;
  }
  .form-control:focus {
    border: 1px solid  ${({ theme }) => theme["focusColor"]};        
  }
  .navbar-dark {
    .top-menu-links {
      color: ${({ theme }) => theme["orange-color"]};
    }

    .navbar-nav {
      .nav-link {
        color: ${({ theme }) => theme["darkMode-lightColor"]} ;
        &:active,
        &:hover,
        &:focus {
          color: ${({ theme }) => theme["darkMode-lightColor"]};
        }
      }
    }

    .dropdown-menu
    {
      padding: 0; 
      .dropdown-item {
        padding: 5px 20px;
        .top-menu-links {
          color: #212529;
        }
        &.active,&:active {
          background-color: ${({ theme }) => theme["orange-color"]};
          .top-menu-links {
            color: ${({ theme }) => theme["darkMode-lightColor"]};
            background-color: ${({ theme }) => theme["orange-color"]};
            &.active {
              color: ${({ theme }) => theme["darkMode-lightColor"]};
            }
        }
        }
      }
    }
  }

  .navbar-light {
    .top-menu-links {
      color: ${({ theme }) => theme["orange-color"]};
    }
    .navbar-nav {
      .nav-link {
        color: #000;
        &:active,
        &:hover,
        &:focus {
          color: #000;
        }
      }
    }
    .dropdown-menu {
      min-width: 328px;
      position: absolute;
      padding: 0;
      -webkit-box-shadow: 0 2px 10px #00000033;
      box-shadow: 0 2px 10px #00000033;
      border: 1px solid #ccc;
      -webkit-border-radius: 10px;
      border-radius: 10px;
      .dropdown-item {
        text-align: left;
        padding: 10px 15px;
      }
    }
    .add_plus_container .dropdown-menu {
      min-width: 3px !important;
    }
    .dropdown-menu
    {
      padding: 0;
      .dropdown-item {
        padding: 5px 20px;
        .top-menu-links {
          color: #212529;
        }
      &.active,
      &:active {
        background-color: ${({ theme }) => theme["orange-color"]};
        .top-menu-links {
          color: ${({ theme }) => theme["darkMode-lightColor"]};
          background-color: ${({ theme }) => theme["orange-color"]};
          &.active {
            color: ${({ theme }) => theme["darkMode-lightColor"]};
          }
        }
      }
    }
  }
  }
  .darkmode {
    .sidebar {
      .nav {
        .dropmenuitems {
          .nav-link {
            color: ${({ theme }) => theme["darkMode-lightColor"]};
          }
        }
      }
      .backtoMainSideBar {
        background: #000000;
        .backLinkMain {
          .backlink {
            color: ${({ theme }) => theme["darkMode-lightColor"]};
            svg {
              path {
                fill: ${({ theme }) => theme["darkMode-lightColor"]};
              }
            }
            .backTxt {
            }
            &:hover {
              color: ${({ theme }) => theme["orange-color"]};
              background-color: transparent;
              svg {
                path {
                  fill: ${({ theme }) => theme["orange-color"]};
                }
              }
            }
          }
        }
      }
    }
  }
  .nav-tabs {
    border-bottom: inherit;
    .nav-link {
      color: ${({ theme }) => theme["tabLink"]};
      &.active {
          color:  ${({ theme }) => theme["tabLink"]};
          background-color: ${({ theme }) => theme["tabBg"]};
          border-color: ${({ theme }) => theme["tabLink"]} ${({ theme }) =>
  theme["tabLink"]} transparent;
      }
    }
  }
  .tab-content .tab-pane.active
  {
    color:  ${({ theme }) => theme["panel-heading"]};
  }
  .alert-success{
    background-color : #008000;
    border: 1px solid #008000;
    color : #fff;
  }
  .clone{
    color: ${({ theme }) => theme["themeColor"]};
    
  }
  .modal-body .form-label{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .modal-body .form-control{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    color: ${({ theme }) => theme["themeColor"]};
    border: 1px solid #808080;
    opacity: 1;
  }
  .card-bd{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    color: ${({ theme }) => theme["themeColor"]};
  }
  .close-icon{
    color: ${({ theme }) => theme["themeColor"]};
    opacity: 0.90;
  }
  .bar-loader{
    margin-right: 5px;
  }
  .modal-body p{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .search-box{
    background: #FFFFFF;
  }
  .search-box{
    background: ${({ theme }) => theme["searchcolor"]} ;
    border-radius: 5px;
    border-color: ${({ theme }) => theme["searchcolor"]};
  }
  .search-control{
    background-color: ${({ theme }) => theme["searchcolor"]};
    color: ${({ theme }) => theme["themeColor"]};
    border-radius: 5px;
    border-color: ${({ theme }) => theme["searchcolor"]};
  }
  .search-control:focus{
    background-color: ${({ theme }) => theme["searchcolor"]};
    color: ${({ theme }) => theme["themeColor"]};
    border-color: ${({ theme }) => theme["searchcolor"]};
  }
  .search-icon{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .add-new{
    color: ${({ theme }) => theme["themeColor"]};
    font-weight: 600;
  }

  .multi-select{          
    .css-1s2u09g-control{
          background-color: ${({ theme }) => theme["tableViewCard"]};       
          border : 1px solid;
          border-radius: 5px;
          color: ${({ theme }) => theme["gray-color"]};
        
    }

  }
  .dashed-line:before {
    position: absolute;
    border-bottom: 2px dashed  ${({ theme }) => theme["themeColor"]};
    content: "";
    display: block;
    width: 100%;
    height: 0%;
    left: 0;
    bottom: 0;
    transition: all 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }
  .rounded-button-absolute {
    position: absolute;
    bottom: -12px;
    left: 10px;
    border-radius: 100%;
    padding: 3px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .addcircle{
    margin-left: -20px;
    width: 28px;
    height: 28px;
  }
  .removeicon{
    float: right;
    cursor: pointer;
    margin-top: 10px;
    margin-right: -15px;
    width: 20px;
    height: 20px;
  }
  .hovering{
    margin: 15px 0px 0px 0px;
  }
  .check_task_number{
    display: flex;
    /* justify-content: center; */
    align-items: center;
    text-align: center !important;
    gap: 1rem;
  }
  .backlogs_checkbox{
    width: 25px;
    height: 18px;
    color: #fff;
    accent-color: #2D53DA;
    cursor: pointer;
  }
  .backlogs_id_value{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .select_all_backlogs{
    color: #000;
    font: normal normal normal 14px/20px;
    background-color: transparent;
    outline: none;
    border: none;
    &:hover{
      color: #000;
      /* background-color: #2D53DA !important; */
    }
  }
  .disabeled_btn{
    background-color: transparent !important;
  }
  .backlogs_go_btn{
    width: 155px;
    height: 35px;
    border: none;
    outline: none;
    color: #fff;
    font: normal normal medium 14px/20px;
    background: #2D53DA 0% 0% no-repeat padding-box !important;
    border-radius: 5px;
    opacity: 1;
  }
  .move_to_refined{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    padding-bottom: 1rem;
  }
  .move_to_refined_btn{
    color: #2D53DA;
    font: normal normal 600 12px/20px;
    padding: 5px 12px;
    background-color: transparent;
    border: 1px solid #2D53DA;
    outline: none;
    border-radius: 5px;
    &:hover{
      background-color: transparent;
      border: 1px solid #2D53DA;
      color: #2D53DA;
    }
  }
  .date_filter_con{
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  .move_to_refined_btn.btn.disabled{
    color: #2D53DA;
    background-color: transparent !important;
    border: 1px solid #2D53DA;
    outline: none;
    border-radius: 5px;
  }
  .priority-red {
    color: #FF4A00;
  }
  .priority-Lightgreen{
    color: #53FF00;
  }
  .priority-orange{
    color: #F49638;
  }
  .priority-white{
    color: #F0EC94;
  }
  .assigned_to{
    color: #399EF5;
  }
  .isPriority_check{
    margin-top: 0.3rem;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .isPriority_check .form-check-input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }
  .filter_date .ant-picker-input > input {
    color: ${({ theme }) => theme["themeColor"]};
  }
  .prev_img_show{
    height: 200px !important;
  }
  .prev_img_show_container{
    display: flex;
    flex-direction: column;
    padding: 4px 16px !important;
  }
  .myDashboard_current_title{
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-bottom: 0rem;
  }
  .myDashboard_line_chart_container{
    width: 510px;
    height: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* UI Properties */
    background: #3E3F3E 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #1C1C1C;
    border-radius: 5px;
    opacity: 1;
  }

  .myDashboard_line_chart{
    width: 80% !important;
    height: 80% !important;
  }
  .chart_task_container{
    width: 75%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 6px;
  }
  .chart_task_title{
    display: flex;
    color: white;
  }
  .kpi_header{
    background: #3C80C4 !important;
  }
  .download_csv_file_btn{
    width: 185px;
    background-color: transparent;
    border: none;
    outline: none;
    color: #2D53DA;
    opacity: 1;
    &:hover{
      background-color: transparent;
      color: #2D53DA;
    }
  }
  .download_csv_file_btn.btn.disabled{
    background-color: transparent !important;
  }
  .download_print_btn.btn.disabled{
    background-color: transparent !important;
  }
  .download_print_btn{
    background-color: transparent;
    border: none;
    outline: none;
    color: #2D53DA;
    opacity: 1;
    &:hover{
      background-color: transparent;
      color: #2D53DA;
    }
  }
  .search_date_filter{
    width: 210px;
    height: 35px;
    border: none;
  }
  .report_date_filters{
    border: 1px solid #ced4da;
    border-radius: 5px;
  }
  .search_date_filter .ant-picker-input > input {
    color: ${({ theme }) => theme["themeColor"]};
    left: 16%;
  }
  .search_date_filter.ant-picker .ant-picker-suffix {
    position: absolute;
    left: 0;
  }
  .search_date_filter .ant-picker-input input::placeholder{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .search_name_filter{
    width: 210px;
    height: 35px;
    margin-bottom: 0rem !important;
  }
  .report_task_header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .report_task_date_filter{
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .report_task_action_btns{
    display: flex;
    gap: 4px;
    align-items: center;
  }
  /* .download_disable_btn{
    c
  } */
  .float_close_icon{
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    background: transparent;
    border-radius: 50%;
    color: red !important;
    position: absolute;
    right: 0;
    top: 0;
    margin-top: -1.3rem;
  }
  .template_close_icon{
    cursor: pointer;
    width: 20px !important;
    height: 20px !important;
    background: transparent;
    border-radius: 50%;
    color: #000 !important;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .backlogs_modal{
    color: ${({ theme }) => theme["themeColor"]};
    background-color: #fff;
    padding: 1.3rem 1rem 3rem 1rem;
    border-radius: 0px 0px 5px 5px;
  }
  .backlogs_modal_close{
    padding-bottom: 0rem;
    background-color: #fff;
  }
  .backlogs_modal_head{
    color: #000000 !important;
    margin-bottom: 2.5rem;
    font-size: 18px;
  }
  .backlogs_move_model.modal .modal-content {
    border-radius: 10px;
    background-color: #fff !important;
  }
  .backlogs_move_model_close{
    color: #000 !important;
  }
  .backlogs_move_content{
    color: #000 !important;
  }
  .column_search_icon{
    cursor: pointer;
  }
  .column_search_input_box{
    width: 12%;
    position: absolute;
    margin-top: 0.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .column_search_input{
    padding: 2px 4px;
    background: #535353;
    border: none;
    border-radius: 8px;
  }
  .template_conatiner{
    display: flex;
    gap: 1rem;
  }
  .template_content_container{
    display: flex;
    flex-direction: column;
  }
  .template_content_box{
    cursor: pointer;
    width: 235px;
    min-height: 250px;
    background-color: #fff;
    padding: 8px;
    border: 1px solid #707070;
  }
  .template_select_name{
    display: flex;
    justify-content: center;
    color: #fff;
    padding-top: 10px;
  }
  .developer_content{
    color: #000 !important;
    font-weight: 650;
    font-size: 10px;
    margin-bottom: 0rem;
  }
  .developer_sub_content{
    font-size: 8px;
    padding-left: 1rem !important;
  }
  .template_modal_container.modal .modal-content {
    width: 550px;
    border-radius: 10px;
    background-color: white;
    padding: 12px 12px 12px 12px;
  }
  .template_view_icon{
    display: flex;
    justify-content: end;
  }
  .userInput{
    width: 100%;
  }
  .template_modal_body{
    display: flex;
    gap: 2rem;
    padding: 18px;
    border-radius: 10px;
  }
  .template_select_content{
    font-size: 15px;
    letter-spacing: 0.15px;
    color: #141414;
    opacity: 1;
    padding-top: 5px;
  }
  .developer_notes_content{
    font-size: 10px !important; 
    font-weight: 700;
    p{
      color: black;
      background: unset;
      margin-bottom: 0rem !important;
      span{
          font-size: 10px !important; 
          color: black !important;
          background: transparent !important;
      }
    }
    ul {
      color: black;
      background: unset;
      padding-left: 1rem;
    }
    li{
      font-weight: 400 !important;
      font-size: 8px !important; 
      color: black;
      background: unset;
      span{
        font-size: 8px !important; 
        color: black !important;
        background: transparent !important;
      }
    }
  }
  .template_select_btn{
    width: 155px;
    height: 35px;
    letter-spacing: 0.14px;
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    background: #2D53DA 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    outline: none;
    opacity: 1;
  } 
  .version_buttons{
    display: flex;
    justify-content:end;
  }
  .version_create_btn{
    background-color: #2D53DA;   
    padding: 5px 30px;
    border: none;
    color: white;
    outline: none;
    border-radius: 5px;
  }
  .modal .modal-content{
    .approval_model_bodytable{
    .table>:not(caption)>*>* {
        background-color: transparent !important
    }
  }}
  @media (max-width: 480px){
    .version_modal_container.modal .modal-content {
    width: 95%;
    margin: auto;
    border-radius: 10px;
    background-color: #3e3f3e;
    padding: 12px;
  } 
  }
  @media (max-width: 700px){
    .modal{
        margin-left: 0px;
    }
  }
  @media (max-width: 1200px){
  .modal-body {
    .table-view-card {
      .card-body{
        .table-responsive{
          overflow: unset;
        }
        background-color: ${({ theme }) => theme["tableViewCard"]};
        overflow: auto;
        max-height: 60vh;
        padding: 0px;
      }     
    }
}
  }
`
