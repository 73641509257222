import React, { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import ActivityWrap from "./style"
import { Button, Nav, Tab, ListGroup } from "react-bootstrap"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import BarLoader from "react-spinners/ClipLoader"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { ConfirmationBox } from "../../../../Reuseable/ConfimationBox"
import { WysiwygEditor } from "../../../../Reuseable/WysiwygEditor"
import { api } from "../../../../../Services/api"
import { setAllTrackings } from "../../../../../redux/Tracking/actionCreator"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js"

export const Activity = ({ item, history }) => {
  const dispatch = useDispatch()
  const allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  const myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  // const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus);
  // const allMembers = useSelector((state) => state.Tracking.allMembers);
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    true
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []

  const [commentEditor, setCommentsEditor] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [comment, setComment] = useState("")
  const [updatedComment, setUpdatedComment] = useState("")
  const [commentsList, setCommentsList] = useState(item.comments)
  const [showEdit, setShowEdit] = useState(false)

  // const originalString = "<p>@TestUser Hi Completed</p>"
  // const stringWithoutMentions = comment.replace(/@\w+/g, "").trim()

  const userid = useSelector((state) => state.Auth.user.userId)
  const authToken = useSelector((state) => state.Auth.authToken)
  const [selectedComment, setSelectedComment] = useState("")
  const index = allTrackingsList.findIndex((element) => element.trackingid === item.trackingid)
  const myTaskIndex = myTrackingList.findIndex((element) => element.trackingid === item.trackingid)
  const [loading, setLoading] = useState(false)
  const [searchedMembers, setSearchedMembers] = useState([])
  const [changed, setChanged] = useState(false)
  const [showList, setShowList] = useState(false)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  let [getTaggedUsers, setgetTaggedUsers] = useState([])

  const commentRef = useRef()

  useEffect(() => {
    var regex = /@/gi,
      result = []
    let users = []
    while ((result = regex.exec(comment))) {
      users.push(result)
    }
    if (users.length > 0) {
      setChanged(false)
      setShowList(true)
      setSearchedMembers(
        allMembers?.filter((item) => {
          return (
            item?.personname?.toLowerCase().includes(
              comment
                ?.toLowerCase()
                ?.slice(users[users.length - 1]?.index + 1)
                .trim()
                .replace("</p>", "")
            ) ||
            item?.trackinguserid?.toLowerCase().includes(
              comment
                ?.toLowerCase()
                ?.slice(users[users.length - 1]?.index + 1)
                .trim()
                .replace("</p>", "")
            )
          )
        })
      )
    } else {
      setShowList(false)
    }
  }, [comment])

  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAt = function (index, replacement) {
    return this.substring(0, index) + replacement + " </p>"
  }

  const selectTaggedUser = (name, comment) => {
    var str = comment

    let users = []
    var regex = /@/gi
    var result = []

    while ((result = regex.exec(comment))) {
      users.push(result)
    }

    if (users.length > 0) {
      const lastIndex = users[users.length - 1].index
      setComment(str.replaceAt(lastIndex + 1, name) + "")
    }
    setChanged(true)
    setShowList(false)
  }
  const loaderstyle = {
    background: loading ? "#FFFFFF" : "#f1f1f1",
    color: loading ? "#808080" : "#9d9d9d",
    border: loading ? "2px solid #FFFFFF" : "2px solid #d3d3d3",
    borderColor: loading ? "FFFFFF" : "#d3d3d3"
  }
  let his = []

  if (history) {
    for (let i = history.length; i > 0; i--) {
      his.push(history[i - 1])
    }
  }

  const onDeleteHandler = (response) => {
    if (response) {
      api
        .delete("/tracking/" + trackingClientId + "/" + item.trackingid + "/comment", {
          headers: {
            authorizationtoken: `${authToken}`
          },
          data: {
            cid: selectedComment
          }
        })
        .then((response) => {
          setLoading(false)
          setCommentsList(response.data.data.comments)
          if (index !== -1 || index === 0) {
            allTrackingsList[index].comments = response.data.updatedData.comments
            dispatch(setAllTrackings([...allTrackingsList]))
          }
          if (myTaskIndex !== -1 || myTaskIndex === 0) {
            myTrackingList[myTaskIndex].comments = response.data.updatedData.comments
          }
          setCommentsEditor(false)
        })
        .catch((error) => {
          return error
        })
    }
  }
  const updateComment = (id) => {
    setLoading(true)
    api
      .patch(
        "/tracking/" + trackingClientId + "/" + item.trackingid + "/comment",
        {
          cid: id,
          comment: updatedComment,
          timezone: timezone
        },
        {
          headers: {
            authorizationtoken: `${authToken}`
          }
        }
      )
      .then((response) => {
        setLoading(false)
        setComment("")
        setShowEdit(false)
        setCommentsList(response.data.updatedData.comments)
        if (index !== -1 || index === 0) {
          allTrackingsList[index].comments = response.data.updatedData.comments
          dispatch(setAllTrackings([...allTrackingsList]))
        }
        if (myTaskIndex !== -1 || myTaskIndex === 0) {
          myTrackingList[myTaskIndex].comments = response.data.updatedData.comments
        }
        setCommentsEditor(false)
      })
      .catch((error) => {
        return error
      })
  }

  const saveComment = (comment) => {
    let users = allMembers.filter((item) => {
      return comment.toLowerCase().includes(userid.toLowerCase())
    })
    setLoading(true)
    api
      .post(
        "/tracking/" + trackingClientId + "/" + item.trackingid + "/comment",
        {
          comment: comment,
          timezone: timezone,
          taggedUsers: getTaggedUsers.length === 0 ? users : getTaggedUsers
        },
        {
          headers: {
            authorizationtoken: `${authToken}`
          }
        }
      )
      .then((response) => {
        if (response.data.status) {
          setLoading(false)
          setComment("")
          setgetTaggedUsers([])
          setCommentsList(response.data.updatedData.comments)
          setCommentsEditor(false)
          if (index !== -1 || index === 0) {
            allTrackingsList[index].comments = response.data.updatedData.comments
            dispatch(setAllTrackings([...allTrackingsList]))
          }
          if (myTaskIndex !== -1 || myTaskIndex === 0) {
            myTrackingList[myTaskIndex].comments = response.data.updatedData.comments
          }
        } else return response.data.message
      })
      .catch((error) => {
        return error
      })
  }

  useEffect(() => {
    if (commentEditor) {
      commentRef.current.focusEditor()
    }
  }, [commentEditor])

  return (
    <ActivityWrap>
      <div className='text-start mt-5 p-2 my-3'>
        <strong className='tr-color'>Activity</strong>
        <Tab.Container id='left-tabs-example' defaultActiveKey='comments'>
          <Nav variant='pills' className='mt-2'>
            <Nav.Item>
              <Nav.Link eventKey='comments'>Comments</Nav.Link>
            </Nav.Item>
            <Nav.Item className='ms-2'>
              <Nav.Link eventKey='history'>History</Nav.Link>
            </Nav.Item>
          </Nav>
          <Tab.Content className='mt-3'>
            <Tab.Pane eventKey='comments'>
              {commentEditor ? (
                <div className='mb-4 editor-section position-relative'>
                  <WysiwygEditor
                    editorRef={commentRef}
                    handleReturn={(e, comm) => {
                      if (e.keyCode === 13) {
                        e.preventDefault()
                        saveComment(comm)
                        setCommentsEditor(false)
                      }
                    }}
                    value={comment}
                    onChange={(value) => {
                      setComment(value)
                    }}
                    changed={changed}
                    hideToolbar={true}
                  />
                  {comment != "" &&
                  comment != undefined &&
                  searchedMembers.length > 0 &&
                  showList ? (
                    <ListGroup
                      style={{
                        position: "absolute",
                        width: "50%",
                        overflow: "hidden",
                        overflowY: "auto",
                        height: 165,
                        marginTop: "-32px"
                      }}
                    >
                      {searchedMembers.length > 0 ? (
                        <>
                          {searchedMembers.map((item, index) => {
                            return (
                              <ListGroup.Item className="comment-dropdown"
                                variant='secondary'
                                style={{
                                  cursor: "pointer",
                                }}
                                key={index}
                                onClick={(e) => {
                                  selectTaggedUser(item.personname, comment)
                                  let getUserId = allMembers.find(
                                    (emailId) => emailId.personname === item.personname
                                  )
                                  setgetTaggedUsers((prevTaggedUsers) => {
                                    if (!prevTaggedUsers.includes(getUserId.userId)) {
                                      return [...prevTaggedUsers, getUserId.userId]
                                    } else {
                                      return prevTaggedUsers
                                    }
                                  })
                                  setSearchedMembers([])
                                }}
                              >
                                {item.personname}
                                <br />
                                <small>{item.trackinguserid}</small>
                              </ListGroup.Item>
                            )
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </ListGroup>
                  ) : (
                    <></>
                  )}
                  <div className='text-end'>
                    <Button
                      size='sm'
                      sx={{ display: "flex", alignItems: "center" }}
                      variant='secondary'
                      style={loaderstyle}
                      className='titleupdate me-1 ps-3 pe-3 border-none'
                      disabled={comment === "" || comment.length === 8 ? true : false}
                      onClick={(e) => {
                        saveComment(comment)
                      }}
                    >
                      {loading ? (
                        <BarLoader
                          color='#808080'
                          className='bar-loader'
                          size={14}
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        <></>
                      )}
                      <CheckIcon />
                      {""}
                    </Button>
                    <Button
                      size='sm'
                      variant='secondary'
                      className='titlecancel ps-3 pe-3  border-none'
                      onClick={(e) => {
                        setCommentsEditor(false)
                        setComment("")
                        setgetTaggedUsers([])
                      }}
                    >
                      <CloseIcon />
                    </Button>
                  </div>
                </div>
              ) : !showEdit ? (
                <div className='add-comment'>
                  <input
                    type='text'
                    placeholder='Add a comment...'
                    className='form-control mb-4'
                    onClick={(e) => {
                      setCommentsEditor(true)
                      setShowEdit(false)
                    }}
                  />
                </div>
              ) : (
                ""
              )}

              {Object.keys(commentsList)
                .sort((item1, item2) => (item1.datetime < item2.datetime ? 1 : -1))
                .map((key) => {
                  let item = commentsList[key]
                  return (
                    <div className='mt-3' key={key}>
                      <div className='d-flex'>
                        <div className='flex-shrink-0'>
                          <span className='imgfirstLetr text-capitalize'>
                            {" "}
                            {item.personname[0]}
                          </span>
                        </div>
                        <div className='flex-grow-1 ms-3'>
                          <div className='comments-section'>
                            <div className='mb-2'>
                              <span className='me-3'>
                                <strong>{item.personname}</strong>
                              </span>
                              <span>{item.datetime}</span>
                            </div>
                            <div className='editCommentPart position-relative'>
                              {showEdit && selectedComment === key ? (
                                <>
                                  <WysiwygEditor
                                    value={item.comment}
                                    onChange={(value) => setUpdatedComment(value)}
                                    changed={changed}
                                    hideToolbar={true}
                                  />
                                  <div className='text-end'>
                                    <Button
                                      size='sm'
                                      sx={{
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                      variant='secondary'
                                      style={loaderstyle}
                                      className='titleupdate me-1 ps-3 pe-3 border-none'
                                      disabled={
                                        updatedComment === "" || updatedComment.length === 8
                                          ? true
                                          : false
                                      }
                                      onClick={(e) => updateComment(key)}
                                    >
                                      {loading ? (
                                        <BarLoader
                                          color='#808080'
                                          className='bar-loader'
                                          size={14}
                                          role='status'
                                          aria-hidden='true'
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      <CheckIcon />
                                      {""}
                                    </Button>
                                    <Button
                                      size='sm'
                                      variant='secondary'
                                      className='titlecancel ps-3 pe-3  border-none'
                                      onClick={(e) => setShowEdit(false)}
                                    >
                                      <CloseIcon />
                                    </Button>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <p className='mt-2 mb-1'>
                                    <small
                                      dangerouslySetInnerHTML={{
                                        __html: item.comment
                                      }}
                                    />{" "}
                                  </p>
                                  {userid === item.trackinguserid ? (
                                    <p>
                                      <Button
                                        style={{ color: "#2D53DA" }}
                                        variant='link'
                                        size='sm'
                                        onClick={(e) => {
                                          setShowEdit(true)
                                          setCommentsEditor(false)
                                          setSelectedComment(key)
                                          setUpdatedComment(item.comment)
                                        }}
                                      >
                                        <EditIcon />
                                      </Button>
                                      <Button
                                        style={{ color: "#2D53DA" }}
                                        variant='link'
                                        size='sm'
                                        onClick={(e) => {
                                          setShowDelete(true)
                                          setSelectedComment(key)
                                        }}
                                      >
                                        <DeleteIcon />{" "}
                                      </Button>
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
            </Tab.Pane>
            <Tab.Pane eventKey='history'>
              {his?.map((value, index) => {
                return (
                  <div key={index}>
                    {Object.keys(value?.actions)?.map((action, index1) => {
                      let personName = value?.personname ?? ""
                      return (
                        <div key={index1} className='mb-1'>
                          <div className='d-flex'>
                            <div className='flex-shrink-0'>
                              <span className='imgfirstLetr text-capitalize'> {personName[0]}</span>
                            </div>
                            <div className='flex-grow-1 ms-3'>
                              <div className='history-section ps-0'>
                                <div>
                                  <strong>{value?.personname}</strong>
                                  {action === "status_changed" ? (
                                    <>
                                      {" "}
                                      changed the <strong>Status</strong>{" "}
                                    </>
                                  ) : action === "assignedto_changed" ? (
                                    <>
                                      {" "}
                                      assigned task to{" "}
                                      <strong>
                                        {allMembers.find(
                                          (e) => e.userId === value.actions[action].current_status
                                        )
                                          ? allMembers.find(
                                              (e) =>
                                                e.userId === value.actions[action].current_status
                                            ).personname
                                          : "None"}
                                      </strong>
                                    </>
                                  ) : action === "created_new" ? (
                                    <> created new task</>
                                  ) : action === "timeline_changed" ? (
                                    <>
                                      {" "}
                                      updated the <strong>timeline</strong>{" "}
                                    </>
                                  ) : action === "priority_changed" ? (
                                    <>
                                      {" "}
                                      changed status <strong>priority</strong>
                                    </>
                                  ) : action === "type_changed" ? (
                                    <>
                                      {" "}
                                      changed task <strong>Type</strong>{" "}
                                    </>
                                  ) : (
                                    <></>
                                  )}{" "}
                                  <span className='ms-3'>{value.datetime}</span>
                                </div>
                                {
                                  // action === 'status_changed' ? <p className="status"><span className='old-status'>{value.actions[action].previous_status!=='1' && value.actions[action].previous_status!=='' ? allFlowStatus.find(e => e.id ===  value.actions[action].previous_status ).label : "Queue"} </span> &nbsp;  →   &nbsp; <span className='new-status'> {allFlowStatus.find(e => e.id ===  value.actions[action].currentStatus).label} </span> </p>
                                  action === "assignedto_changed" ? (
                                    <p className='assignedto'>
                                      <span className=''>
                                        {value.actions[action].previous_status !== "" &&
                                        allMembers.find(
                                          (e) =>
                                            e.trackinguserid ===
                                            value.actions[action].previous_status
                                        )
                                          ? allMembers.find(
                                              (e) =>
                                                e.trackinguserid ===
                                                value.actions[action].previous_status
                                            ).personname
                                          : value.actions[action].previous_status}
                                      </span>{" "}
                                      &nbsp; → &nbsp;{" "}
                                      <span className=''>
                                        {allMembers.find(
                                          (e) =>
                                            e.trackinguserid ===
                                            value.actions[action].current_status
                                        )
                                          ? allMembers.find(
                                              (e) =>
                                                e.trackinguserid ===
                                                value.actions[action].current_status
                                            ).personname
                                          : value.actions[action].current_status}
                                      </span>
                                    </p>
                                  ) : action === "timeline_changed" ? (
                                    <p>
                                      <span>
                                        {value.actions[action].previous_status !== "" ? (
                                          <>{value.actions[action].previous_status} hours</>
                                        ) : (
                                          <>None</>
                                        )}{" "}
                                      </span>{" "}
                                      &nbsp; → &nbsp;{" "}
                                      <span>{value.actions[action].current_status} hours</span>
                                    </p>
                                  ) : action === "priority_changed" ? (
                                    <p>
                                      <span className='old-status text-capitalize'>
                                        {value.actions[action].previous_status !== ""
                                          ? value.actions[action].previous_status
                                          : "None"}{" "}
                                      </span>{" "}
                                      &nbsp; → &nbsp;{" "}
                                      <span className='new-status text-capitalize'>
                                        {" "}
                                        {value.actions[action].current_status}{" "}
                                      </span>
                                    </p>
                                  ) : action === "type_changed" ? (
                                    <p>
                                      <span className=' text-capitalize'>
                                        {value.actions[action].previous_status !== "" &&
                                        allTaskTypes.find(
                                          (e) => e.id === value.actions[action].previous_status
                                        )
                                          ? allTaskTypes.find(
                                              (e) => e.id === value.actions[action].previous_status
                                            )?.label
                                          : "None"}
                                      </span>{" "}
                                      &nbsp; → &nbsp;{" "}
                                      <span className=''>
                                        {
                                          allTaskTypes.find(
                                            (e) => e.id === value.actions[action].current_status
                                          )?.label
                                        }
                                      </span>
                                    </p>
                                  ) : (
                                    <></>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
      <ConfirmationBox
        heading={"Are you sure to delete?"}
        hidePopup={(e) => setShowDelete(false)}
        show={showDelete}
        confirmationResponse={onDeleteHandler}
      />
    </ActivityWrap>
  )
}
