import React from "react"
import ScheduleIcon from "@mui/icons-material/Schedule"
import { Modal, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { userLogout } from "../../../redux/Auth/actionCreator"
import { resetTracking } from "../../../redux/Tracking/actionCreator"
import { useNavigate } from "react-router-dom"

export const SessionBox = ({ show, setShow }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const returnToLogin = () => {
    let result = {
      isLoggedIn: false,
      authToken: "",
      user: "",
      menuList: []
    }
    dispatch(userLogout(result))
    dispatch(resetTracking())
    window.location.href = "https://www.tymeplus.co/"
    setShow(false)
  }

  return (
    <Modal
      show={show}
      onHide={(e) => setShow(false)}
      size='sm'
      keyboard={false}
      style={{ backgroundColor: "rgb(95, 93, 93, 0.95)" }}
      backdrop='static'
      centered
    >
      <Modal.Body className='text-center m-2'>
        <ScheduleIcon style={{ fontSize: 40, color: "#2D53DA" }} />
        <h5 className='mt-2'>Your Session has expired due to inactivity</h5>
        <p>
          Click{" "}
          <Button
            variant='link'
            onClick={() => returnToLogin()}
            style={{ color: "#2D53DA" }}
            className='p-0'
          >
            here
          </Button>{" "}
          to return to login page
        </p>
        <Button className='btn-orange' onClick={() => returnToLogin()}>
          OK
        </Button>
      </Modal.Body>
    </Modal>
  )
}
