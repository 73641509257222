import React, { useEffect } from "react"
import Main from "../components/Pages/Layout/main"
import { checkIfValid } from "../Utilities/commonFunctions"
import jwt_decode from "jwt-decode"
import { useDispatch } from "react-redux"
import { userLogin } from "../redux/Auth/actionCreator"
import { api } from "../Services/api"
import { useNavigate } from "react-router"
import { setSessionTimeout } from "../redux/Auth/actionCreator"
import MoonLoader from "react-spinners/MoonLoader"
import logo from "../assets/images/logo/Tymeplus Task 2.svg"

// var createGuest = require("cross-domain-storage/guest")

const RedirecttoMarketPlace = () => {
  window.location.href = "https://www.waila.co"
  return
}

const ProtectedRouteAuth = () => {
  const [isValidLogin, setValidLogin] = React.useState(false)
  const [loading, setLoading] = React.useState(true)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userDetails = async () => {
    try {
      const response = await api.get("/trackinguserauth", {
       
        headers: {
          authorizationtoken: window.localStorage.getItem("token") || ""
        }
      })
      {console.log("response -->", response)};

      if (response.status === 200) {
        if (response.data.status === true) {
          let token = window.localStorage.getItem("token");
          let userRoleId  = window.localStorage.getItem("userRoleId");
          const user = jwt_decode(token)
          let Menus = [];
          response.data?.data?.menulist[userRoleId].forEach((item) => {
            if (item.key === "task" && item.subMenu && item.subMenu.length > 0) {
                item.subMenu.forEach((subItem) => {
                  Menus.push(subItem);
                });
            }
          });
          let result = {
            authToken: token,
            user: user,
            menuList: Menus
          }
          dispatch(userLogin(result))
          setValidLogin(true)
          navigate("/home")
          setTimeout(() => {
            setLoading(false)
          }, [2000])
        }
      } else {
        setLoading(false)
        dispatch(setSessionTimeout(true))
      }
    } catch (error) {
      setLoading(false)
      dispatch(setSessionTimeout(true))
    }
  }

  useEffect(() => {
    setTimeout(() => {
      userDetails()
    }, [500])
  }, [])

  return (
    <>
      {loading ? (
        <div className='loader-layout'>
          <img loading="eager" className='d-block' src={logo} alt='menunew' width={200} height={100} />
          <MoonLoader color='#2d53da' size={40} />{" "}
        </div>
      ) : (
        <>{isValidLogin && <Main />}</>
      )}
    </>
  )
}

export default ProtectedRouteAuth
