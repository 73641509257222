import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import Search from "../Search/Search";
import deteteIcon from "../../../../assets/images/AdminImages/Teams/noun-delete-1123191.svg";
import { getRandomColor } from "../../../../Utilities/commonFunctions";

const bussinessTeamDetails = [
  {
    id: 1,
    name: "John smith",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Authorizer",
  },
  {
    id: 2,
    name: "Anna Pearce",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Maker",
  },
  {
    id: 3,
    name: "Jack Jones",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Maker",
  },
  {
    id: 4,
    name: "Amelia Brown",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Authorizer",
  },
  {
    id: 5,
    name: "Levi smith",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Authorizer",
  },
  {
    id: 6,
    name: "Mia Harris",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Maker",
  },
  {
    id: 7,
    name: "Lucas William",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Maker",
  },
  {
    id: 8,
    name: "Oliva Rovberts",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Authorizer",
  },
  {
    id: 9,
    name: "John smith",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Authorizer",
  },
  {
    id: 10,
    name: "John smith",
    email: "johnsmith@gmail.com",
    phoneNo: "8434234234",
    role: "Maker",
  },
];

const CurrentTeams = ({ teams }) => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12} sm={12} md={4}>
          <div className="d-flex flex-column">
            {teams.map((team) => {
              return <TeamCard key={team.id} teamName={team.teamName} />;
            })}
          </div>

          <div className=""></div>
        </Col>

        <Col md={8} className="text-white">
          <Card className="card-bd my-1">
            <Card.Body>
              <div
                className="fw-semibold fs-5 mb-2"
                style={{ color: "#2D53DA" }}
              >
                Development Team
              </div>
              <Search placeholder="Search Name" />

              <Table borderless responsive className="text-white mt-2">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone no.</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {bussinessTeamDetails.map((bussinessTeamDetail) => {
                    return (
                      <tr key={bussinessTeamDetail.id}>
                        <td>{bussinessTeamDetail.name}</td>
                        <td>{bussinessTeamDetail.email}</td>
                        <td>{bussinessTeamDetail.phoneNo}</td>
                        <td>{bussinessTeamDetail.role}</td>
                        <td>
                          <div className="d-flex justify-content-center">
                            <img src={deteteIcon} alt="Delete" />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CurrentTeams;


const TeamCard = ({ teamName }) => {
  return (
    <Card className="card-bd my-1">
      <Card.Body>
        <span
          className="team-name-circle"
          style={{ background: getRandomColor() }}
        >
          {teamName[0]}
        </span>{" "}
        {teamName}
      </Card.Body>
    </Card>
  );
};
