import React, { useState, useEffect } from "react";
import { Card, Container, Modal, Alert } from "react-bootstrap";
import Search from "../../Search/Search";
import UserDetails from "./UserDetails";
import { Divider } from "@mui/material";
import UserlistWrapper from "./style";
import { useMedia } from "react-use";
import CloseIcon from "@mui/icons-material/Close";

const UserCard = ({
  memnberList,
  setsubUserPermission,
  setInvitepending,
  index,
  setDeleteUser,
  CardShow,
  setactive,
  active,
  responsiveCard,
}) => {
  const [profileColor, setProfileColor] = useState("");

  const checkPendingInvite = () => {
    if (memnberList.status === "Unverified") {
      setInvitepending(true);
    }
  };

  useEffect(() => {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);
    setProfileColor(color);
  }, []);

  return (
    <div
      className={
        responsiveCard
          ? "my-1"
          : memnberList.userId === active
          ? "active-card my-1"
          : "my-1"
      }
      style={{ cursor: "pointer", padding: "15px" }}
      onClick={() => {
        setsubUserPermission(memnberList);
        setDeleteUser(index);
        checkPendingInvite();
        CardShow();
        setactive(memnberList.userId);
      }}
    >
      <div style={{ display: "inline-flex" }}>
        <div
          className="team-name-circle me-2"
          style={{ background: profileColor }}
        >
          <center>{memnberList.personname[0]}</center>
        </div>
        <div className="box mb-2">
          {memnberList.personname}
          <div className="userid-box mt-1" style={{ wordBreak: "break-all" }}>
            {memnberList.userId}
          </div>
        </div>
      </div>
      {memnberList.status === "Unverified" ? (
        <span
          className="float-end"
          style={{ color: "#2D53DA", fontSize: "12px" }}
        >
          Pending invite
        </span>
      ) : null}
    </div>
  );
};

const UserList = ({
  alertMessage,
  setAlertMessage,
  showAlertBox,
  setShowAlertBox,
  memberDetails,
  setShowNoUser,
  setMemberDetails,
  subUserPermission,
  setsubUserPermission,
  setactive,
  active,
}) => {
  const [invitePending, setInvitepending] = useState(false);
  const [deleteUser, setDeleteUser] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [cardshow, setCardShow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("");
      setShowAlertBox(false);
    }, 2500);
  }, [showAlertBox]);

  const CardClose = () => setCardShow(false);
  const CardShow = () => setCardShow(true);
  const responsiveCard = useMedia("(max-width: 767px)");

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  return (
    <div>
      {showAlertBox && (
        <Alert
          variant="success"
          className="text-center alert-sucess alert mt-3 mb-1"
          dismissible
          closeVariant="white"
          onClick={(e) => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <UserlistWrapper>
        <Container fluid>
          <div className="user_data_container">
            <div className="user_id_lists_card">
              <div className="card-background mt-1">
                <div className="scrollbar_custom">
                  <div className="user-namelist d-flex flex-column">
                    <Card className="card-bd">
                      <div className="userlist-header">
                        <div className="Userlist-Search">
                          <span className="mt-1 mx-2">Filter:</span>
                          <Search
                            userFilter="userFilter"
                            type="userFilter"
                            placeholder="Search "
                            handleSearch={handleSearch}
                          />
                        </div>
                      </div>
                      <Card.Body style={{ padding: "0px" }}>
                        {memberDetails
                          .filter((memnberList) => {
                            let personname = memnberList.personname;
                            return personname
                              .toLowerCase()
                              .includes(searchValue.toLowerCase());
                          })
                          .map((memnberList, index) => {
                            return (
                              <div
                                style={{ background: "transparent" }}
                                key={index}
                              >
                                <Divider className={"divider" + index} />
                                <UserCard
                                  memnberList={memnberList}
                                  setsubUserPermission={setsubUserPermission}
                                  setInvitepending={setInvitepending}
                                  index={index}
                                  setDeleteUser={setDeleteUser}
                                  CardShow={CardShow}
                                  setactive={setactive}
                                  active={active}
                                  responsiveCard={responsiveCard}
                                />
                              </div>
                            );
                          })}
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
            <div className="user_details_lists_card">
              {responsiveCard ? (
                <Modal
                  show={cardshow}
                  onHide={CardClose}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <div className="p-2" style={{ color: "#2D53DA" }}>
                    <CloseIcon
                      className=" mt-1"
                      onClick={CardClose}
                      style={{
                        color: "#2D53DA",
                        cursor: "pointer",
                        float: "right",
                      }}
                    />
                    <UserDetails
                      setShowNoUser={setShowNoUser}
                      setsubUserPermission={setsubUserPermission}
                      setDeleteUser={setDeleteUser}
                      setMemberDetails={setMemberDetails}
                      memberDetails={memberDetails}
                      setShowAlertBox={setShowAlertBox}
                      subUserPermission={subUserPermission}
                      invitePending={invitePending}
                      setAlertMessage={setAlertMessage}
                      deleteUser={deleteUser}
                      setactive={setactive}
                    />
                  </div>
                </Modal>
              ) : (
                <div>
                  <div className="">
                    <UserDetails
                      setShowNoUser={setShowNoUser}
                      setsubUserPermission={setsubUserPermission}
                      setDeleteUser={setDeleteUser}
                      setMemberDetails={setMemberDetails}
                      memberDetails={memberDetails}
                      setShowAlertBox={setShowAlertBox}
                      subUserPermission={subUserPermission}
                      invitePending={invitePending}
                      setAlertMessage={setAlertMessage}
                      deleteUser={deleteUser}
                      setactive={setactive}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </Container>
      </UserlistWrapper>
    </div>
  );
};

export default UserList;
