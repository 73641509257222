import {
  BsThreeDotsVertical,
  BsFillEyeFill,
  BsFillTrashFill,
} from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import CardContainer from "../Cards/CarduiStyle";
import moment from "moment";
import CircleIcon from '@mui/icons-material/Circle';

const PriorityLevel = ({ props }) => {

  const minor = "3, 218, 198"
  const medium = "255, 147, 0"
  const major = "187, 134, 252"
  const blocker = "207, 102, 121"
  const blocker2 = "245, 20, 61"

  const priorityColorBg = (rgb) => {
    return `rgba(${rgb}, .5)`
  }

  const priorityColorDot = (rgb) => {
    return `rgba(${rgb}, 1)`
  }

  const bgColor = () => {
    if (props.TaskLevel === "minor" || props.TaskLevel === "Minor") return priorityColorBg(minor)
    if (props.TaskLevel === "medium" || props.TaskLevel === "Medium") return priorityColorBg(medium)
    if (props.TaskLevel === "major" || props.TaskLevel === "Major") return priorityColorBg(major)
    if (props.TaskLevel === "blocker" ||
      props.TaskLevel === "Blocker" ||
      props.TaskLevel === "Blocker Level 1" ||
      props.TaskLevel === "blocker Level 1"
    ) return priorityColorBg(blocker)
    if (props.TaskLevel === "blocker2" ||
      props.TaskLevel === "Blocker2" ||
      props.TaskLevel === "Blocker Level 2" ||
      props.TaskLevel === "blocker Level 2"
    ) return priorityColorBg(blocker2)
  }

  const dotColor = () => {
    if (props.TaskLevel === "minor" || props.TaskLevel === "Minor") return priorityColorDot(minor)
    if (props.TaskLevel === "medium" || props.TaskLevel === "Medium") return priorityColorDot(medium)
    if (props.TaskLevel === "major" || props.TaskLevel === "Major") return priorityColorDot(major)
    if (props.TaskLevel === "blocker" ||
      props.TaskLevel === "Blocker" ||
      props.TaskLevel === "Blocker Level 1" ||
      props.TaskLevel === "blocker Level 1"
    ) return priorityColorDot(blocker)
    if (props.TaskLevel === "blocker2" ||
      props.TaskLevel === "Blocker2" ||
      props.TaskLevel === "Blocker Level 2" ||
      props.TaskLevel === "blocker Level 2"
    ) return priorityColorDot(blocker2)
  }

  return (
    <div className="task-level">
      <span className="px-1 py-0 rounded d-inline-flex justify-content-center align-items-center gap-1 text-capitalize"
        style={{ backgroundColor: bgColor() }}><CircleIcon sx={{ fontSize: ".7rem", color: dotColor() }} />
        {props.TaskLevel}
      </span>
      {props.timeline && (
        <div>
          Timeline: <span>{props.timeline}hr</span>
        </div>
      )}
    </div>
  )
}

const Card = (props) => {
  /********************* Add Hyper Link TRWH-1114 *****************/
  const {
    setTaskview,
    setTrackingItemData,
    setDeleteConfirmationShow,
    itemID,
    column,
    assignedTo,
    assignedToMe
  } = props;


  return (
    <>
      <CardContainer>
        <div className="cards"
          onClick={(e) => {
            setTaskview("taskview");
            setTrackingItemData({ ...column?.items?.find((element) => element.trackingid === itemID) });
          }}
        >
          {/* header section */}
          {/* <div className="level_and_timeline"> */}
          <PriorityLevel props={props} />
          {/* <p style={{color: "red"}}>{props.timeline}</p> */}
          {/* </div> */}

          <div className="cardBody">
            <div className="cardheader">
              <h1>
                {props.clientId}-{props.trackId}
              </h1>

              {/* <div style={{ rotate: "90deg", color: "#2D53DA" }} ><DragIndicatorIcon /></div> */}

              <div className="created-on">
                Created on: <span>{moment(props.date).format("MMM D, YYYY")}</span>
              </div>

              <Dropdown className="d-inline-block dropView">
                <Dropdown.Toggle
                  id="dropdown-autoclose-outside"
                  className="imgfirstLetrDropdwnTogle"
                  onSelect={() => null}
                >
                  <BsThreeDotsVertical
                    variant="primary"
                    className="cardIcon"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    eventKey={1}
                    style={{
                      backgroundColor: "transparent",
                      color: "#2D53DA",
                    }}
                    onClick={(e) => {
                      setTaskview("taskview");
                      setTrackingItemData(column?.items?.find((element) => element.trackingid === itemID));
                    }}
                  >
                    <BsFillEyeFill className="plusIcon" /> View
                  </Dropdown.Item>
                  {props.deletePermission && <Dropdown.Item
                    eventKey={2}
                    className="colsebtn"
                    style={{
                      backgroundColor: "transparent",
                      color: "red",
                    }}
                    onClick={(e) => {
                      setDeleteConfirmationShow(true);
                      setTrackingItemData(column?.items?.find((element) => element.trackingid === itemID));
                    }}
                  >
                    <BsFillTrashFill className="plusIcon" /> Delete
                  </Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="cardcontent">
              <p>{props.title}</p>
            </div>
          </div>


          {/* card footer section */}


          {!assignedToMe && assignedTo && <div className="d-flex justify-content-start align-items-center gap-1 cardcontent">
            <div className="Icon">
              {assignedTo.trim()[0]}
            </div>
            <div className="assignedTo">{assignedTo}</div>
          </div>}

        </div>
      </CardContainer>
    </>
  );
};

export default Card;

