import React, { useState } from "react"
import { useEffect } from "react"
import Card from "./Projectcard"
import CardWrap from "./Projectindex_style"
import { useRef } from "react"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import { api } from "../../../../../Services/api"
import { BsPlusLg } from "react-icons/bs"
import QuickProject from "../../NewTracking/QuickProject"
import { Skeleton } from "@mui/material"

const CardUI = ({
  data,
  columns,
  setColumns,
  isLoading,
  setShowAlertBox,
  setAlertMessage,
  createPermission,
  ...props
}) => {
  function setProjectView(data) {
    props.setProjectView(data)
  }
  function setShowView(data) {
    props.setShowView(data)
  }
  function setTrackingItemData(data) {
    props.setTrackingItemData(data)
  }
  function setDeleteConfirmationShow(data) {
    props.setDeleteConfirmationShow(data)
  }

  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const authToken = useSelector((state) => state.Auth.authToken)

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      let [removed] = sourceItems.splice(source.index, 1)
      removed.priority = destColumn.name.toLowerCase()
      const obj = removed

      api
        .patch("/trackingprojects/" + trackingClientId + "/" + obj.trackingprojectid, obj, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("drag n drop response", response)
          }
        })
        .catch((error) => {
          console.error("project edit error", error)
        })

      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      })
    }
  }

  const onMouseHandler = (event) => {
    if (event.deltaY) {
      if (event.deltaY < 0) {
        cardSectionRef.current.scrollLeft -= 50
      } else {
        cardSectionRef.current.scrollLeft += 50
      }
    } else {
    }
    return false
  }

  const cardSectionRef = useRef(null)

  useEffect(() => {
    if (cardSectionRef.current) {
      cardSectionRef.current.addEventListener("mousewheel", onMouseHandler)
    }

    const cardSection = document.querySelectorAll(".cardScroll")

    cardSection.forEach((element) => {
      element.addEventListener("mousewheel", (event) => {
        event.stopPropagation()
        event.stopImmediatePropagation()
        return false
      })
    })
  }, [cardSectionRef, columns])

  useEffect(() => {
    window.scrollTo(0, 0)
    // document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  // console.log("layout", localStorage.getItem("Trekr-Layout"))
  // const layout = localStorage.getItem("Trekr-Layout")
  // console.log('CardUI  layout', layout)

  return (
    <>
      <CardWrap className='dragContainer'>
        <div className='cardWrap'>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div key={index + 1000}>
                  <h4 className='ps-3'>{column.name}</h4>
                  <div
                    ref={cardSectionRef}
                    className='cardScroll mt-1'
                    //  style={{ margin: 8 }}
                  >
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            className={`${index === 3 ? "section closed" : "section"}`}
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            // style={{
                            //   background: snapshot.isDraggingOver
                            //     ? "lightblue"
                            //     : "lightgrey",
                            //   padding: 4,
                            //   width: 250,
                            //   minHeight: 500
                            // }}
                          >
                            {column.name !== "Closed" && createPermission && (
                              <AddNewQuickProject
                                column={column}
                                setShowAlertBox={setShowAlertBox}
                                setAlertMessage={setAlertMessage}
                                isLoading={isLoading}
                              />
                            )}

                            {column.loader && <LoaderCard />}

                            {column.items.map((item, index) => {
                              return (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          // padding: 4,
                                          margin: "0 0 8px 0",
                                          // minHeight: "50px",
                                          // borderRadius: "5px",
                                          // backgroundColor: snapshot.isDragging
                                          //   ? "hsla(201, 82%, 62%, 0.3)"
                                          //   : "hsla(201, 82%, 62%, 0.8)",
                                          // color: "white",
                                          ...provided.draggableProps.style
                                        }}
                                      >
                                        <Card
                                          projectName={item.label}
                                          item={item}
                                          {...item}
                                          {...props}
                                          setProjectView={setProjectView}
                                          setShowView={setShowView}
                                          setTrackingItemData={setTrackingItemData}
                                          setDeleteConfirmationShow={setDeleteConfirmationShow}
                                        />
                                      </div>
                                    )
                                  }}
                                </Draggable>
                              )
                            })}
                            {provided.placeholder}
                          </div>
                        )
                      }}
                    </Droppable>
                  </div>
                </div>
              )
            })}
          </DragDropContext>
        </div>
      </CardWrap>
    </>
  )
}

export default CardUI

const AddNewQuickProject = ({ setShowAlertBox, setAlertMessage, column, isLoading }) => {
  const [showAddNew, setShowAddNew] = useState(false)
  return (
    <div className={`Error ${showAddNew && "p-0"}`}>
      {/* <div className="noData"> */}
      <div className={`emptyDtat ${!showAddNew && "d-flex justify-content-center"}`}>
        {!showAddNew ? (
          <button
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              textAlign: "center",
              background: "transparent",
              border: "none",
              outline: "none"
            }}
            onClick={(e) => {
              setShowAddNew(true)
            }}
          >
            <BsPlusLg />
            Add Project
          </button>
        ) : (
          <QuickProject
            setShowAddNew={setShowAddNew}
            column={column}
            setShowAlertBox={setShowAlertBox}
            setAlertMessage={setAlertMessage}
            isLoading={isLoading}
          />
        )}

        {/* <p>Project not found</p> */}
      </div>
      {/* </div> */}
    </div>
  )
}

const LoaderCard = () => {
  return (
    <div className={`Error`}>
      <div className={`emptyDtat`}>
        <Skeleton animation='wave' />
        <Skeleton animation='pulse' />
        <Skeleton animation='wave' />
      </div>
    </div>
  )
}
