import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Alert } from "react-bootstrap"
import plusIcon from "../../../../assets/images/icons/plusiconblue.svg"
import removeIcon from "../../../../assets/images/icons/minusiconblue.svg"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import WorkflowWrap from "./style"
import viewIcon from "../../../../assets/images/view.svg"
import trashIcon from "../../../../assets/images/icons/bluedelete12.svg"
import editIcon from "../../../../assets/images/edit.svg"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import { CardView } from "../Tracking/style"
import WorkFlowCardView from "./WorkFlowCardView"
import NewWorkFlow from "./NewWorkFlow"
import WorkflowView from "./WorkflowView"
import { defaultworkflowbutton } from "../../../../data/constant"
import { dateFormat } from "../../../../Utilities/commonFunctions"
import { useSessionStorage } from "react-use"
import { setWorkFlowView } from "../../../../redux/Tracking/actionCreator"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
// import { useWorkflow } from "../../../../Hooks/useWorkflow"

const Workflow = ({ showAddNewWorkflow, setShowAddNewWorkflow = () => {}, permission }) => {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const viewPermission = permission?.view
  const deletePermission = permission?.delete
  const authToken = useSelector((state) => state.Auth.authToken)
  const user = useSelector((state) => state.Auth.user)

  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  // const { handleWorkflowData } = useWorkflow()

  /***************************************************************************/

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  const { data: allWorkFlowRes, refetch: getAllWorkFlow } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    true
  )
  let allWorkFlow = allWorkFlowRes?.data?.workflowList ?? []

  const { data: allWorkflowHeadersNamesRes } = useFetch(
    key.get_workflows_headers,
    "/masterdata/6",
    () => {},
    () => {},
    false,
    false
  )

  let allWorkflowHeadersNames = allWorkflowHeadersNamesRes?.formStructureData ?? []

  /****************************************************************************/

  const [loadingList, setLoadingList] = useState(false)
  const [Loading, setLoading] = useState(false)
  const show = useSelector((state) => state.Tracking.workflowView)

  const dispatch = useDispatch()

  const setShow = (view) => {
    dispatch(setWorkFlowView(view))
  }
  const [selected, setSelected] = useState(null)
  const [workflowname, setWorkflowname] = useState("")
  const [alertMessage, setAlertMessage] = useState("")
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [cardui, setCardui] = useSessionStorage("cardOrList", "cardui")
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [trackingItemData, setTrackingItemData] = useState()
  const [openView, setOpenView] = useState(false)
  const [viewData, setViewData] = useState("")
  const [editNode, setEditNode] = useState(false)
  const [updateData, setUpdateData] = useState([{ status: "" }])
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [editData, setEditData] = useState("")

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
      setDeleteAlert(false)
    }, 2500)
  }, [])

  useEffect(() => {
    if (showAlertBox) {
      window.scrollTo(0, 0)
    }
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
      setDeleteAlert(false)
    }, 2500)
  }, [showAlertBox])

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ]
  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = today.getMonth()
  let dd = today.getDate()
  let monthName = monthNames[mm]
  let hours = (today.getHours() < 10 ? "0" : "") + today.getHours()
  let minutes = (today.getMinutes() < 10 ? "0" : "") + today.getMinutes()
  let TimeType = ""

  if (hours <= 11) {
    TimeType = "AM"
  } else {
    TimeType = "PM"
  }
  if (hours > 12) {
    hours = hours - 12
  }
  if (hours == 0) {
    hours = 12
  }

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  const formattedDate =
    monthName + " " + dd + "," + yyyy + " " + hours + ":" + minutes + " " + TimeType

  const [inputFields, setInputFields] = useState([{ status: "" }])

  const [inputFieldsFocus, setInputFieldsFocus] = useState([{ status: false }])

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    setLoading(true)
    let workflowarray = []
    inputFields.forEach((e, i) => {
      workflowarray.push({ [i.toString()]: e.status })
    })

    if (workflowarray.length <= 1) {
      setShowAlertBox(true)
      setDeleteAlert(true)
      setLoading(false)
      setAlertMessage("Atleast two step is required for creating a Workflow")
      return
    }
    const WorkflowObject = {
      createdOn: formattedDate,
      updatedOn: formattedDate,
      createdBy: user.userName,
      workFlowName: workflowname,
      workFlow: workflowarray,
      // "workflowStatus": "in active"
      projectId: "Not Assigned"
    }
    api
      .post("/trekrworkflow", WorkflowObject, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then((response) => {
        console.log("handleSubmit  response", response)

        if (response.status === 200) {
          handleClose()
          getAllWorkFlow()
          // fetchworkflowdata();
          setWorkflowname("")
          setInputFields([{ status: "" }])
          setLoadingList(false)
          setLoading(false)
          setShowAlertBox(true)
          setAlertMessage("Workflow Created Successfully")
          setShowAddNewWorkflow(false)
        }
      })
      .catch((error) => {
        setShowAddNewWorkflow(false)
        return error
      })
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    setLoading(true)
    let workflowarray = []
    inputFields.map((e, i) => {
      workflowarray.push({ [i.toString()]: e.status })
    })
    const WorkflowObject = {
      workFlowId: selected.workFlowId,
      workFlow: {
        createdBy: user.userName,
        workFlowName: workflowname,
        createdOn: selected.createdOn,
        updatedOn: formattedDate,
        workFlowId: selected.workFlowId,
        workFlow: workflowarray,
        workflowStatus: selected.workflowStatus
      }
    }

    api
      .patch("/trekrworkflow", WorkflowObject, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then((response) => {
        console.log("Response-->", response)
        handleClose()
        getAllWorkFlow()
        // fetchworkflowdata();
        setWorkflowname("")
        setInputFields([{ status: "" }])
        setInputFieldsFocus([{ status: false }])
        setSelected(null)
        setLoading(false)
        setLoadingList(false)
        setShowAddNewWorkflow(false)
      })
      .catch((error) => {
        return error
      })
  }

  const deleteData = (e) => {
    if (e) {
      const WorkflowObject = {
        workFlowId: trackingItemData
      }
      api
        .delete("/trekrworkflow", {
          headers: {
            authorizationtoken: `${authToken}`
          },
          Body: WorkflowObject,
          data: WorkflowObject
        })
        .then((response) => {
          console.log("Response-->", response)
          // fetchworkflowdata();
          setLoadingList(false)
          setShowAlertBox(true)
          setAlertMessage(trackingItemData + " - has been deleted successfully")
          getAllWorkFlow()
        })
        .catch((error) => {
          setDeleteAlert(true)
          setShowAlertBox(true)
          setAlertMessage(error.response.data.message)
          return error
        })
    }
  }

  const handleSplChar = (e) => {
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "+" ||
      e.key === "-" ||
      e.key === "." ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }

  const loaderstyle = {
    float: "right",
    background: Loading ? "#FFFFFF" : "#2D53DA",
    color: Loading ? "#808080" : "#FFFFFF",
    border: Loading ? "2px solid #FFFFFF" : "2px solid #2D53DA"
  }

  const handleFormChange = (index, event) => {
    let inputdata = [...inputFields]
    inputdata[index][event.target.name] = event.target.value
    setInputFields(inputdata)
  }

  const addWorkflowStep = () => {
    let newfield = { status: "" }
    setInputFields([...inputFields, newfield])
    setInputFieldsFocus([...inputFieldsFocus, { status: false }])
  }

  const removeWorkflowStep = (index) => {
    let removetempdata = [...inputFields]
    removetempdata.splice(index, 1)
    setInputFields(removetempdata)
    let field = [...inputFieldsFocus]
    field.splice(index, 1)
    setInputFieldsFocus(field)
  }

  const handleClose = () => {
    setShow(false)
  }

  const handleShow = () => {
    setShow(true)
  }

  const WorkflowEdit = (value) => {
    setSelected(value)
    setShowDetails(false)
    if (value !== null) {
      setWorkflowname(
        value.workFlowName !== null || value.workFlow !== undefined ? value.workFlowName : ""
      )
      let editData = []
      value.workFlow !== null && value.workFlow !== undefined
        ? value.workFlow.map((e, i) => {
            Object.keys(e).map((n) => {
              editData.push({ status: e[n] })
            })
          })
        : editData.push({ status: "" })
      setInputFields(editData)
      handleShow()
    }
  }

  const showDetail = (value) => {
    setSelected(null)
    if (value !== null) {
      setShowDetails(true)
      setWorkflowname(
        value.workFlowName !== null || value.workFlow !== undefined ? value.workFlowName : ""
      )
      let viewData = []
      value.workFlow !== null && value.workFlow !== undefined
        ? value.workFlow.map((e) => {
            Object.keys(e).map((n) => {
              viewData.push({ status: e[n] })
            })
          })
        : viewData.push({ status: "" })
      setInputFields(viewData)
      handleShow()
    }
  }

  const showAddWorkflow = () => {
    handleShow()
    setSelected(null)
    setShowDetails(false)
    setWorkflowname("")
    setInputFields([{ status: "" }])
    setInputFieldsFocus([{ status: false }])
  }

  let WorkFlowHeaderName = []

  for (let i = 0; i < allWorkflowHeadersNames.length; i++) {
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column1)
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column2)
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column3)
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column4)
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column5)
    WorkFlowHeaderName.push(allWorkflowHeadersNames[i].column6)
  }
  const WorkflowColumns = () => {
    return WorkFlowHeaderName.map((value, index) => <th key={index}>{value}</th>)
  }

  const WorkflowgetData = () =>
    inputFields &&
    Object.keys(allWorkFlow).map((key, index) => {
      return allWorkFlow[key].map((value) => {
        let project_name = allProjectsData.find((e) => e.trackingprojectid == value.projectId)
        if (project_name) value.project_name = project_name.label
        else value.project_name = "Not Assigned"

        return (
          <React.Fragment>
            <tr setAlertMessage={setAlertMessage} key={index}
            onClick={() => {
              setOpenView(true)
              setViewData({
                value: value,
                key: key
              })
            }}
            >
              <td style={{ cursor: "pointer" }}>
                <strong className='mobHead'>Workflow ID :</strong>
                <span
                  className='mobSubhead'
                  style={{ textDecoration: "underline" }}
                  onClick={(e) => {
                    setOpenView(true)
                    setViewData({
                      value: value,
                      key: key
                    })
                  }}
                >
                  {value.workFlowId !== null && value.workFlowId !== undefined
                    ? value.workFlowId
                    : value.workFlowid !== null && value.workFlowid !== undefined
                    ? value.workFlowid
                    : ""}
                </span>
              </td>
              <td>
                <strong className='mobHead'>Workflow Name :</strong>
                <span className='mobSubhead'>
                  {value.workFlowName === null || value.workFlowName === undefined
                    ? ""
                    : value.workFlowName}
                </span>
              </td>
              <td>
                <strong className='mobHead'>Project :</strong>
                <span className='mobSubhead'>{value.project_name}</span>
              </td>
              <td>
                <strong className='mobHead'>Created by :</strong>
                <span className='mobSubhead'>{value.createdBy}</span>
              </td>
              <td>
                <strong className='mobHead'>Created on :</strong>
                <span className='mobSubhead'>{dateFormat(value.createdOn)}</span>
              </td>
              {value.workFlowId === "default" ? (
                <td className='actionBtns'>
                  {defaultworkflowbutton.map((item, index) => (
                    <Button
                      size='sm'
                      title='View'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1 defaultbtn'
                      key={index}
                    >
                      <img
                        src={item.icon}
                        alt='item'
                        onClick={() =>
                          index === 0
                            ? (setOpenView(true),
                              setViewData({
                                value: value,
                                key: key
                              }))
                            : ""
                        }
                      />
                    </Button>
                  ))}
                </td>
              ) : (
                <td className='actionBtns'>
                  <>
                    <Button
                      size='sm'
                      title='View'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                    >
                      <img
                        alt='img'
                        src={viewIcon}
                        onClick={() => {
                          setOpenView(true)
                          setViewData({
                            value: value,
                            key: key
                          })
                        }}
                      />
                    </Button>
                    {/* <Button
                      size='sm'
                      title='View'
                      variant='link'
                      className='text-decoration-none pe-1 ps-1'
                    >
                      <img
                        alt='img'
                        src={editIcon}
                        onClick={() => {
                          WorkflowEdit(value)
                        }}
                      />
                    </Button> */}
                    {deletePermission && (
                      <Button
                        size='sm'
                        title='View'
                        variant='link'
                        className='text-decoration-none pe-1 ps-1'
                        onClick={e => e.stopPropagation()}
                      >
                        <img
                          alt='img'
                          src={trashIcon}
                          onClick={() => {
                            setDeleteConfirmationShow(true)
                            setTrackingItemData(
                              value.workFlowId !== null && value.workFlowId !== undefined
                                ? value.workFlowId
                                : value.workFlowid !== null && value.workFlowid !== undefined
                                ? value.workFlowid
                                : ""
                            )
                          }}
                        />
                      </Button>
                    )}
                  </>
                  {/* ))} */}
                </td>
              )}
            </tr>
          </React.Fragment>
        )
      })
    })

  const EditNodeData = (data) => {
    let editData = []
    data !== null && data !== undefined
      ? data.map((e, i) => {
          Object.keys(e).map((n) => {
            editData.push({ status: e[n] })
          })
        })
      : editData.push({ status: "" })
    setUpdateData(editData)
  }

  const handleCardChange = (index, event, key) => {
    let inputdata = [...updateData]
    console.log(updateData)
    inputdata[index][event.target.name] = event.target.value
    setUpdateData(inputdata)
  }

  const handleCardUpdate = () => {
    let workflowarray = []
    updateData.map((e, i) => {
      workflowarray.push({ [i.toString()]: e.status })
    })

    const WorkflowObject = {
      workFlowId: editData.workFlowId,
      workFlow: {
        createdBy: user.userName,
        workFlowName: editData.workFlowName,
        createdOn: editData.createdOn,
        updatedOn: formattedDate,
        workFlowId: editData.workFlowId,
        workFlow: workflowarray,
        workflowStatus: editData.workflowStatus
      }
    }
    api
      .patch("/trekrworkflow", WorkflowObject, {
        headers: {
          authorizationtoken: `${authToken}`
        }
      })
      .then((response) => {
        console.log("Response-->", response)
        getAllWorkFlow()
        // fetchworkflowdata();
        setUpdateData([{ status: "" }])
        setEditData(null)
      })
      .catch((error) => {
        return error
      })
  }

  return (
    <>
      {showAlertBox ? (
        <>
          {deleteAlert ? (
            <Alert
              variant='danger'
              closeVariant='white'
              className='text-center alert-sucess'
              dismissible
              onClick={(e) => setShowAlertBox(false)}
            >
              {alertMessage}
            </Alert>
          ) : (
            <Alert
              variant='success'
              closeVariant='white'
              className='text-center alert-sucess'
              dismissible
              onClick={(e) => setShowAlertBox(false)}
            >
              {alertMessage}
            </Alert>
          )}{" "}
        </>
      ) : (
        <></>
      )}

      <ConfirmationBox
        heading='Are you sure to delete this ?'
        hidePopup={(e) => setDeleteConfirmationShow(false)}
        show={deleteConfirmationShow}
        confirmationResponse={deleteData}
        setAlertMessage={setAlertMessage}
      />

      <>
        {show || showAddNewWorkflow ? (
          <>
            {!showAddNewWorkflow && (
              <h5 className='d-flex justify-content-start align-items-center mb-1'>
                <ArrowCircleLeftIcon
                  className='me-3'
                  onClick={() => {
                    handleClose()
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#2D53DA",
                    fontSize: "30px"
                  }}
                />
                <div className='title add-new-task'>
                  {showDetails
                    ? "Workflow Details"
                    : selected === null
                    ? "Add Workflow"
                    : "Update Workflow"}
                </div>
              </h5>
            )}

            <NewWorkFlow
              handleSubmit={handleSubmit}
              handleUpdate={handleUpdate}
              selected={selected}
              setWorkflowname={setWorkflowname}
              workflowname={workflowname}
              showDetails={showDetails}
              inputFields={inputFields}
              handleFormChange={handleFormChange}
              addWorkflowStep={addWorkflowStep}
              removeIcon={removeIcon}
              plusIcon={plusIcon}
              handleSplChar={handleSplChar}
              removeWorkflowStep={removeWorkflowStep}
              loaderstyle={loaderstyle}
              Loading={Loading}
            />
          </>
        ) : (
          <WorkflowWrap>
            <CardView>
              <div
                className='topContent'
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div className='view'>
                  <h2
                    onClick={(e) => setCardui("cardui")}
                    className={`Cardbtn ${cardui === "cardui" ? "active" : ""}`}
                  >
                    Card
                  </h2>
                  <span>|</span>
                  <h2
                    onClick={(e) => setCardui("List")}
                    className={`Listbtn ${cardui === "List" ? "active" : ""}`}
                  >
                    List
                  </h2>
                </div>
                {createPermission && (
                  <div className='addTask'>
                    <button
                      style={{
                        color: "#FFFFFF",
                        fontSize: "15px",
                        padding: "5px 12px",
                        backgroundColor: "#2D53DA",
                        border: "none",
                        outline: "none",
                        borderRadius: "5px"
                      }}
                      onClick={(e) => {
                        showAddWorkflow()
                      }}
                    >
                      + Add new workflow
                    </button>
                  </div>
                )}
              </div>
            </CardView>
            {cardui === "cardui" ? (
              <>
                <WorkFlowCardView
                  setDeleteConfirmationShow={setDeleteConfirmationShow}
                  setTrackingItemData={setTrackingItemData}
                  WorkflowEdit={WorkflowEdit}
                  showDetail={showDetail}
                  handleFormChange={handleFormChange}
                  user={user}
                  formattedDate={formattedDate}
                  setViewData={setViewData}
                  viewData={viewData}
                  setOpenView={setOpenView}
                  openView={openView}
                  editNode={editNode}
                  setEditNode={setEditNode}
                  setUpdateData={setUpdateData}
                  updateData={updateData}
                  EditNodeData={EditNodeData}
                  setEditData={setEditData}
                  editData={editData}
                  handleCardUpdate={handleCardUpdate}
                  handleCardChange={handleCardChange}
                  editPermission={editPermission}
                  deletePermission={deletePermission}
                  createPermission={createPermission}
                />
              </>
            ) : (
              <div className='workflowPage'>
                <Tables
                  setProjectTable={true}
                  setAlertMessage={setAlertMessage}
                  columns={WorkflowColumns()}
                  data={Object.keys(allWorkFlow).length > 0 ? WorkflowgetData() : ""}
                  showInfiniteScroll={false}
                  notfound={"No Trackings Found"}
                  loading={loadingList}
                />
              </div>
            )}
            {openView ? (
              <WorkflowView
                setOpenView={setOpenView}
                openView={openView}
                viewData={viewData}
                setViewData={setViewData}
                setEditNode={setEditNode}
                EditNodeData={EditNodeData}
                setEditData={setEditData}
                handleCardUpdate={handleCardUpdate}
                handleCardChange={handleCardChange}
                updateData={updateData}
                editNode={editNode}
                allWorkFlow={allWorkFlow}
                editPermission={editPermission}
                createPermission={createPermission}
              />
            ) : null}
          </WorkflowWrap>
        )}
      </>
    </>
  )
}
export default Workflow
