import MyDashboard from "./components/Pages/Portal/MyDashboard"
import TemplatesTabledata from "./components/Pages/Portal/Templates"
import Projects from "./components/Pages/Portal/Projects"
import Tracking from "./components/Pages/Portal/Tracking"
import MyTasks from "./components/Pages/Portal/Mytasks"
import { Dashboard } from "./components/Pages/Portal/Dashboard"
import AdminDashBoard from "./components/Pages/Admin/User/AdminDashBoard"
import CloseAccount from "./components/Pages/CloseAccount"
import ArchiveTableData from "./components/Pages/Portal/Archive"
import ProjectModel from "./components/Pages/Portal/ProjectModel"
import PriorityTable from "./components/Pages/Portal/Priority"
import BacklogsData from "./components/Pages/Portal/Backlogs"
import Workflow from "./components/Pages/Portal/Workflow"
import { Approval } from "./components/Pages/Portal/Approval"
import ReportTasksTableData from "./components/Pages/Portal/Reports/Tasks"
import TimeloggerTableData from "./components/Pages/Portal/Reports/Timelogger"
import WeeklyOverviewTableData from "./components/Pages/Portal/Reports/WeeklyOverview"
import Documents from "./components/Pages/Portal/Documents"
import { Settings } from "./components/Pages/Portal/Settings"
import { Calender } from "./components/Pages/Portal/Calender"
import Teams from "./components/Pages/Admin/Teams/Teams"
import KpiTableData from "./components/Pages/Portal/KpiPoinsTable"
import { useSelector } from "react-redux"
import { Route, useNavigate } from "react-router"
import { useDispatch } from "react-redux"
import { setProjectView } from "./redux/Tracking/actionCreator"

export const useProtectedRoutes = () => {
  const sidebarData = useSelector((state) => state.Auth.menuList)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const ReportSubNames =
    sidebarData &&
    sidebarData?.filter((element) => {
      return element.child
    })

  const project = sidebarData && sidebarData?.find((data) => data.key === "Projects")
  const workFlow = sidebarData && sidebarData?.find((data) => data.key === "WorkFlow")
  const document = sidebarData && sidebarData?.find((data) => data.key === "Documents")
  const myTask = sidebarData && sidebarData?.find((data) => data.key === "My Task")
  const allTask = sidebarData && sidebarData?.find((data) => data.key === "All Task")
  const archive = sidebarData && sidebarData?.find((data) => data.key === "Archive")
  const approval = sidebarData && sidebarData?.find((data) => data.key === "Approval")
  const backlogs = sidebarData && sidebarData?.find((data) => data.key === "Backlogs")
  const projectModel = sidebarData && sidebarData?.find((data) => data.key === "Project model")
  const mydashboard = sidebarData && sidebarData?.find((data) => data.key === "My dashboard")
  const kpiPoints = sidebarData && sidebarData?.find((data) => data.key === "KPI")
  const priority = sidebarData && sidebarData?.find((data) => data.key === "Priority")
  const template = sidebarData && sidebarData?.find((data) => data.key === "Template")
  const weeklyreport = ReportSubNames?.[0]?.child?.find(
    (data) => data.key === "Weekly overview"
  )?.key
  const taskreport = ReportSubNames?.[0]?.child?.find((data) => data.key === "Task")?.key
  const timeloggerreport = ReportSubNames?.[0]?.child?.find(
    (data) => data.key === "Timelogger"
  )?.key

  const setShowProject = () => {
    dispatch(setProjectView("add project"))
    navigate("/projects")
  }

  let protectedRoutes = [
    {
      path: "/mydashboard",
      route: (
        <Route path='/mydashboard' element={<MyDashboard permission={mydashboard?.permission} />} />
      )
    },
    {
      path: "/user",
      route: <Route path='/user' element={<AdminDashBoard />} />
    },
    {
      path: "/kpipoints",
      route: (
        <Route path='/kpipoints' element={<KpiTableData permission={kpiPoints?.permission} />} />
      )
    },
    {
      path: "/home",
      route: <Route path='/home' element={<Dashboard setShowProject={setShowProject} projectsPermission={project?.permission}/>} />
    },
    {
      path: "/workflow",
      route: <Route path='/workflow' element={<Workflow permission={workFlow?.permission} />} />
    },
    {
      path: "/projectmodel",
      route: (
        <Route
          path='/projectmodel'
          element={<ProjectModel permission={projectModel?.permission} />}
        />
      )
    },
    {
      path: "/projects",
      route: <Route path='/projects' element={<Projects permission={project?.permission} projectsPermission={project?.permission}/>}  />
    },

    {
      path: "/documents",
      route: <Route path='/documents' element={<Documents permission={document?.permission} />} />
    },
    {
      path: "/approval",
      route: <Route path='/approval' element={<Approval permission={approval?.permission} />} />
    },

    {
      path: "/myTasks",
      route: (
        <Route
          path='/myTasks'
          element={
            <MyTasks permission={myTask?.permission} backlogsPermission={backlogs?.permission} />
          }
        />
      )
    },

    {
      path: "/priority",
      route: (
        <Route path='/priority' element={<PriorityTable permission={priority?.permission} />} />
      )
    },

    {
      path: "/template",
      route: (
        <Route
          path='/template'
          element={<TemplatesTabledata permission={template?.permission} />}
        />
      )
    },

    {
      path: "/tracking",
      route: (
        <Route
          path='/tracking'
          element={
            <Tracking permission={allTask?.permission} backlogsPermission={backlogs?.permission} projectsPermission={project?.permission}/>
          }
        />
      )
    },

    {
      path: "/backlogs",
      route: <Route path='/backlogs' element={<BacklogsData permission={backlogs?.permission} />} />
    },

    {
      path: "/archive",
      route: (
        <Route path='/archive' element={<ArchiveTableData permission={archive?.permission} />} />
      )
    },

    {
      path: "/task_reports",
      route: (
        <Route
          path='/task_reports'
          element={<ReportTasksTableData permission={taskreport?.permission} />}
        />
      )
    },
    {
      path: "/timelogger",
      route: (
        <Route
          path='/timelogger'
          element={<TimeloggerTableData permission={timeloggerreport?.permission} />}
        />
      )
    },
    {
      path: "/weeklyreport",
      route: (
        <Route
          path='/weeklyreport'
          element={<WeeklyOverviewTableData permission={weeklyreport?.permission} />}
        />
      )
    },

    {
      path: "/settings",
      route: <Route path='/settings' element={<Settings />} />
    },
    {
      path: "/calender",
      route: <Route path='/calender' element={<Calender />} />
    },
    {
      path: "/teams",
      route: <Route path='/teams' element={<Teams />} />
    },
    {
      path: "/close-account",
      route: <Route path='/close-account' element={<CloseAccount />} />
    }
  ]

  const generalRoutes = ["/close-account", "/teams", "/calender", "/settings"]

  const allowedRoutes = protectedRoutes.filter((route) => {
    return (
      sidebarData?.find((data) => data.path === route.path)?.access ||
      generalRoutes.includes(route.path)
    )
  })

  return { allowedRoutes }
}
