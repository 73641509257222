import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Form, Spinner } from "react-bootstrap"
import { api } from "../../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import { setAllProjects } from "../../../../../redux/Tracking/actionCreator"
import { v4 as uuid } from "uuid"
import MydashboardModalView from "./style"
import ProgressBar from "react-bootstrap/ProgressBar"
import EditIcon from "../../../../../../src/assets/images/icons/noun-edit-3556018.svg"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { normalizeNumber } from "../../../../../Utilities/commonFunctions"

export default function ProjectEditableComponents({ showProject, permission, datas, allProjects }) {
  let data = datas
  const editPermission = permission?.edit

  const dispatch = useDispatch()
  const authToken = useSelector((state) => state.Auth.authToken)
  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false)
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  let allWorkFlow = useSelector((state) => state.Tracking.allWorkFlow)
  let allProjectDashboard = useSelector((state) => state.Tracking.allProjectDashboard)
  let Work_Flow = Object.keys(allWorkFlow).map((key) => allWorkFlow[key])
  const [showEditTask, setShowEditTask] = useState("")
  const [projectBudget, setProjectBudget] = useState({})
  const [projectBudgetUpdate, setProjectBudgetUpdate] = useState({})
  let projectNow = allProjects?.find((item) => item.trackingprojectid === data)
  const titleRef = useRef()
  const index = allProjects?.findIndex(
    (element) => element.trackingprojectid === projectNow?.trackingprojectid
  )

  useEffect(() => {
    setProjectBudget((prevState) => {
      return {
        ...prevState,
        budget: projectNow?.budget
      }
    })
    setProjectBudgetUpdate((prevState) => {
      return {
        ...prevState,
        budget: projectNow?.budget
      }
    })
  }, [projectNow])

  const Work_Flow1 = Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))

  let workflow_status = Work_Flow1.find((e) => e.workFlowId === allProjectDashboard.workflowid)
  const statusName = workflow_status?.workFlow
  allProjectDashboard.workflow_status = statusName ? Object.values(statusName) : ""

  const handleSplChar = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      editItem("budget")
      updateTask("budget", projectBudgetUpdate.budget)
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: false }
      })
    }
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }

  const editIconCSS = {
    marginLeft: "3%",
    cursor: "pointer",
    marginTop: "1%"
  }

  const tickCloseIconCSS = {
    fontSize: "17px",
    marginTop: "-2px",
    marginLeft: "1%"
  }

  const editItem = (item) => {
    if (item === "budget")
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: true }
      })
  }

  const updateTask = async (name, value) => {
    let Obj = {
      label: projectNow.label,
      projectdescription: projectNow.projectdescription,
      startdate: projectNow.startdate,
      duedate: projectNow.duedate,
      createdby: projectNow.createdby,
      priority: projectNow.priority,
      budget: name === "budget" ? value : projectNow.budget,
      percentage: projectNow.percentage,
      workflow: projectNow.workflow,
      teammates: projectNow.teammates,
      isPriority: projectNow.isPriority ? projectNow.isPriority : false,
      fileurls: projectNow.fileurls,
      versionlist: projectNow.versionlist ? projectNow.versionlist : []
    }

    if (value) {
      setLoading(true)
      api
        .patch("/trackingprojects/" + trackingClientId + "/" + projectNow.trackingprojectid, Obj, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then(function (response) {
          setLoading(false)
          if (response.status === 200) {
            if (index !== -1 || index === 0) {
              allProjects[index][name] = value
              let newObj = [...allProjects]
              dispatch(setAllProjects(newObj))
            }
          }
        })
        .catch((error) => {
          setLoading(false)
          console.error(error)
        })
    }
  }

  return (
    <>
      <MydashboardModalView>
        <div className='budget_timeline'>
          <div className='project_budget'>
            <h5 className='project_sub_head'>Budget</h5>
            <div className='budget_inside'>
              {showEditTask && showEditTask.editTitle ? (
                <div className='d-flex edit-content trackingdatarow'>
                  <Form.Control
                    type='text'
                    className='p-0 select-options'
                    ref={titleRef}
                    value={projectBudgetUpdate?.budget}
                    onChange={(e) =>
                      setProjectBudgetUpdate((prevState) => {
                        return { ...prevState, budget: normalizeNumber(e.target.value) }
                      })
                    }
                    onKeyDown={handleSplChar}
                  />
                  <div
                    className='ms-2 text-center edit-cross'
                    onClick={(e) => {
                      setShowEditTask((prevState) => {
                        return { ...prevState, editTitle: false }
                      })
                      updateTask("budget", projectBudgetUpdate?.budget)
                    }}
                  >
                    <CheckIcon style={tickCloseIconCSS} />
                  </div>
                  <div
                    className='ms-2 text-center edit-cross'
                    onClick={(e) => {
                      setShowEditTask((prevState) => {
                        return { ...prevState, editTitle: false }
                      })
                      setProjectBudgetUpdate((prevState) => {
                        return {
                          ...prevState,
                          budget: projectNow?.budget
                        }
                      })
                    }}
                  >
                    <CloseIcon style={tickCloseIconCSS} />
                  </div>
                </div>
              ) : (
                <span className='edit-content'>
                  {projectNow?.budget ? (
                    <h1 className='m-0 h_budget'>${projectNow?.budget}</h1>
                  ) : (
                    "0"
                  )}

                  {loading && <div className="ms-2"><Spinner animation='border' variant='info' /></div>}

                  {editPermission && !loading && (
                    <img
                      src={EditIcon}
                      alt='editIcon'
                      height={17}
                      width={17}
                      style={editIconCSS}
                      onClick={(e) => editItem("budget")}
                    />
                  )}
                </span>
              )}
            </div>
          </div>

          <div className='project_timeline'>
            <h5 className='project_sub_head'>Time line</h5>
            <div className='timeline_inside'>
              <div className='timeline_head'>
                <p>Due date {projectNow?.duedate}</p>
                <p>{projectNow?.percentage}%</p>
              </div>
              <ProgressBar now={projectNow?.percentage} />
            </div>
          </div>
        </div>
      </MydashboardModalView>
    </>
  )
}
