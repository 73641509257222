import Styled from "styled-components";

const LayoutWrap = Styled.div`
       
        .maincontainer-scroller
        {
            background-color: ${({ theme }) => theme["panel-color"]};
        }
        code {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h1 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h2 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h3 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h4 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h5 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        h6 {
            font-family: ${({ theme }) => theme["font-stack"]};
        }
        a:active,
        a:hover,
        a:focus {
        color: ${({ theme }) => theme["orange-color"]};
        }
        .text-orange
        {
            color:${({ theme }) => theme["orange-color"]};
        }
        .text-orange:hover
        {
            color: ${({ theme }) => theme["orange-hover"]};
        }
        .btn-close {
            &:focus {
                box-shadow: inherit;
            }
        }
       
        .modal-image{
            display: flex;
            align-items: center;
            justify-content: center;
          }
        .btn 
        {
            &.btn-orange {
                color: #fff;
                background-color: ${({ theme }) => theme["orange-color"]};
                border-color: ${({ theme }) => theme["orange-color"]};
                margin: 0 auto;
                font-size: 16px;
                &:hover {
                    color: #fff;
                    background-color: ${({ theme }) => theme["orange-hover"]};
                border-color:${({ theme }) => theme["orange-hover"]};
                }
            }
        }

        .btn-grey {
            color: #212529;
            background-color: #e9ecef;
            border: 1px solid #ced4da;
            margin: 0 auto;
            font-size: 16px;
            &:hover {
                color: #212529;
            }
        }

        .form-check-input {
            &:checked {
                background-color: ${({ theme }) => theme["orange-color"]};
                border-color: ${({ theme }) => theme["orange-color"]};
            }
            &:focus {
                border-color: ${({ theme }) => theme["orange-color"]};
                outline: 0;
                box-shadow: inherit;
            }
            &:checked[type=radio] {
                &:focus {
                    border-color: ${({ theme }) => theme["orange-color"]};
                }
            }
            cursor: pointer;
            border: 2px solid #0c0c0c40;
        }
        .form-check-input[type=checkbox] {
            border-radius: 2px;
        }
        .card {
            border: 0 solid #dedede;
            border-radius: 10px;
            box-shadow: 0px 2px 6px #00000029;
        }
        .main-panel.topmenu {
            margin-left: 0px;
        }
        .main-panel {
            margin-left: 200px;
            display: flex;
            flex-direction: column;
            min-width: 0;
            z-index: 11;
            padding: 15px;
            width: 100%;
            flex-grow: 1;
         
          }
      
        .page-body-wrapper {
             min-height: 100vh;
            /* overflow: auto; */
            /* overflow-y: auto; */
            padding-top: 70px;
            display: flex;
            flex-direction: row;
            padding-left: 0;
            padding-right: 0;
            }

        .content-wrapper {
            overflow-x: hidden;
            width: 100%;
            height: 100%;
        }
        .content-wrapper::-webkit-scrollbar {
            height: 6px;
            width: 8px;
            background-color: transparent;
        }
        .content-wrapper::-webkit-scrollbar-thumb:vertical {
            background-color: transparent;
            border-radius: 10px;
            width: 2px;
        } 
         .content-wrapper:has(#filterDropDown) {
            height: calc(100%);
         }
        .mobSideBar 
        {
            .main-panel {
                margin-left: 0px;
            }  

            .mobSideBar .sidebar.sidebarMain {
                width: 60px;
            }   

            .sidebarMain {
                .serchFieldMain {
                //   margin: 0 17px;
                display: none;
                  input.form-control {
                   // display: none;
                  }
                }
              }
              .sidebar {
                .nav {
                  .dropmenuitems {
                    .nav-link {
                      display: none;
                    }
                  }
                  .nav-link {
                    padding: 6px 10px 6px 17px;
                  }
                }
              }

            .sidebarMainLinks {
                width: 0px;
              }
              .sidebar.sidebarInerLinks {
                width: 60px;
              }
              .sidebar.sidebarMain {
                width: 0px;
              }
              .listArowIcon {
                display: none;
              }
              .navItemArrow {
                display: none;
              }
              .nav-link {
                span.dsktopView {
                  display: none;
                }
              }
        }    
        

        @media (max-width: 575px) {
            .main-panel {
                margin-left: 0;
            }
            .mobSideBar .main-panel {
                margin-left: 0;
            }
            .sidebar {
                width: 0;
            }
            .mobSideBar .sidebar.sidebarMain {
                width: 0;
            }
            .mobSideBar .sidebarMainLinks {
                // width: 0;
                width: 160px;
            }
            .mobSideBar .sidebarMain .serchFieldMain {
              //  display: none;
            }
            .mobSideBar .sidebar.sidebarMain {
                // box-shadow: 0 0 10px #00000020;
                // -webkit-transition: all 0.5s ease-out;
                width: 160px;
                z-index: 111;
            }
            .mobSideBar .nav-link span.dsktopView {
                display: inline-block;
            }
            
            .sidebarMain .serchFieldMain
                {
                    display: none;
                }
                .mobSideBar .sidebarMain .serchFieldMain {
                    display: inline-block;
                }

        }
        
`;

export default LayoutWrap;
