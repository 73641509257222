import { Checkbox, Chip, FormControlLabel, FormGroup, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined"
import { Card, Spinner } from "react-bootstrap"
import styled from "styled-components"
import { key } from "../../../data/queryKeys"
import { useFetch } from "../../../Hooks/useFetch"

const Plan = ({
  tier,
  price,
  duration,
  title,
  benefits,
  checked,
  setSubscriptionPlan,
  otpPage,
  planInfoPage = false,
  handlePlans,
  planId,
  mobileScreen,
  button,
  setShowUpgrade,
  setSelectedPlan
}) => {
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const { data: planInformation } = useFetch(
    key.get_trial_period,
    `/trackingadmin/clients/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false,
    (data) => data?.client
    )

  const darkmode = useSelector((state) => state.Layout.darkmode)

  const [loading, setLoading] = useState(false)

  const [showBenefits, setShowBenefits] = useState({
    benefits,
    show: true
  })

  const handleSubscriptionPlan = async (plan) => {
    setLoading(true)

    try {
      const success = await setSubscriptionPlan(plan)
      setLoading(false)

      if (success) {
        return Promise.resolve()
      } else {
        throw new Error("Failed to Upgrade plan")
      }
      
    } catch (error) {
      setLoading(false)
      return Promise.reject(error)
    }
  }

  useEffect(() => {
    if (mobileScreen) {
      setShowBenefits((prev) => {
        return { ...prev, show: false }
      })
    } else {
      setShowBenefits({ benefits, show: true })
    }
  }, [mobileScreen])

  return (
    <Card
      style={{
        width: otpPage ? "100%" : "24rem",
        height: "100%",
        background: otpPage ? "inherit" : "#1C242F",
        boxShadow: checked && "inset 0px 0px 5px 4px rgba(95,204,242,1)",
        color: "#ffffff"
      }}
    >
      <Card.Body
        style={{ cursor: "pointer", borderRadius: "8px", zIndex: 10 }}
        className='px-4 py-2 py-sm-4 border border-info'
        onClick={(e) => {
          e.stopPropagation()
          handlePlans(planId)
          mobileScreen &&
            setShowBenefits((prev) => {
              return { ...prev, show: true }
            })
        }}
      >
        <Card.Text>
          <div>
            {otpPage ? (
              <span className='tier' style={{ color: "white" }}>
                {tier}
              </span>
            ) : (
              <FormGroup>
                {planInfoPage ? (
                  <>
                    <span className='tier' style={{ color: "white" }}>
                      {tier}
                    </span>
                    <div className='current_plan_label'>
                      {checked ? <Chip label='Current plan' color='primary' /> : null}
                    </div>
                  </>
                ) : (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={<CheckBoxOutlinedIcon sx={{ color: "#5FCCF2" }} />}
                        size='medium'
                        sx={{ color: "#5FCCF2" }}
                        checked={checked}
                        onChange={() => {
                          handlePlans(planId)
                        }}
                      />
                    }
                    label={<span className='tier'>{tier}</span>}
                  />
                )}
              </FormGroup>
            )}
          </div>

          <div className='price'>
            ${price} <span className='dollar'>USD</span>
            {/* <div className=''>{duration}</div> */}
          </div>

          <div className='title mb-2'>{title}</div>

          {showBenefits.show ? (
            <>
              <ul className=''>
                {benefits?.map((benefit) => {
                  return <li key={benefit.id}>{benefit.name}</li>
                })}
              </ul>

              {button === "upgrade" && (
                <NextButton
                  darkmode={darkmode}
                  className='position-absolute bottom-0'
                  onClick={(e) => {
                    e.stopPropagation()
                    if (planInformation?.subscriptionStatus === "incomplete" || planInformation?.subscriptionStatus === "") {
                      setShowUpgrade(true)
                      setSelectedPlan(tier)
                    } else {
                      handleSubscriptionPlan(tier)
                    }
                  }}
                >
                  {loading ? (
                    <>
                      <Spinner className='me-2' animation='grow' size='sm' />
                      Upgrade
                    </>
                  ) : (
                    "Upgrade"
                  )}
                </NextButton>
              )}

              {checked && mobileScreen && (
                <NextButton darkmode={darkmode} onClick={(e) => e.stopPropagation()} type='submit'>
                  Next
                </NextButton>
              )}
            </>
          ) : (
            <>
              <ul className=''>
                {benefits?.map((benefit) => {
                  return benefit.id <= 2 && <li key={benefit.id}>{benefit.name}</li>
                })}
              </ul>

              <ViewMore
                darkmode={darkmode}
                onClick={(e) => {
                  e.stopPropagation()
                  setShowBenefits((prev) => {
                    return { ...prev, show: true }
                  })
                }}
              >
                View More
              </ViewMore>
            </>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default React.memo(Plan)

const ViewMore = styled.div`
  margin: 0 auto;
  background: ${(props) => (props.darkmode ? "#151d25" : "#ffffff")};
  color: ${(props) => (props.darkmode ? "#ffffff" : "#151d25")};
  translate: 0 -5px;
  width: max-content;
  height: auto;
  border: 2px solid #5fccf2;
  border-radius: 5px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;

  &:hover {
    color: #5fccf2;
    /* border-color: white; */
  }
`

const NextButton = styled.button`
  margin: 0 auto;
  background: ${(props) => (props.darkmode ? "#151d25" : "#ffffff")};
  color: ${(props) => (props.darkmode ? "#ffffff" : "#151d25")};
  translate: 0 -5px;
  width: max-content;
  height: auto;
  border: 2px solid #5fccf2;
  border-radius: 5px;
  padding: 2px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;

  &:hover {
    color: #5fccf2;
    /* border-color: white; */
  }
`
