import React, { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../Reuseable/Forms"
import { api } from "../../../../Services/api"
import {
  setShowAlert,
  setAllTrackings,
  setMyTrackings
} from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import TrackingFormWrap from "./style.js"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import { fileToBase64 } from "../../../../Utilities/commonFunctions"
import { useEffect } from "react"

export const NewTracking = ({
  setPageView,
  project,
  setTaskview,
  setShowAlertBox,
  setAlertMessage,
  inputFormArr,
  defaultProjectvalue
}) => {
  /******************************* react functions   *******************/
  const dispatch = useDispatch()
  /*************************** redux var intiaalize *******************/
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const { data: allProjectsRes, refetch: getAllProjects } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjects = allProjectsRes?.data?.Items ?? []
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)

  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    true
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  const { refetch: getAllMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  /*************************** react state  intiaalize *******************/
  const [loading, setLoading] = useState(false)
  const [cancelConfirmationShow, setCancelConfirmationShow] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [fileArr, setFileArr] = useState([])
  const [fileNameList, setFileNameLIst] = useState([])
  const [changeInForm, setChangeInForm] = useState(false)
  const [resetEditorValue, setResetEditorValue] = useState(false)
  const [fileSize, setFileSize] = useState("")
  const [formvalue, setFormvalue] = useState("")

  const menus = useSelector((state) => state.Auth.menuList)

  const editorRef = useRef(null)
  const addNewProject = [{ label: "+ Add New Project", id: "Add New Project" }]
  const isAbleToAddNewProject =
    menus.find((menu) => menu.key === "Projects")?.access ?? false ? true : false

  /***************************** components  *************************/
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  /************************* conditions *******************/

  const { data: allInputformsRed } = useFetch(
    key.get_all_inputforms,
    "/masterdata/2",
    () => {},
    () => {},
    false,
    false
  )

  let defaultProjectteammates = []
  let projectAddnewtask = []

  projectAddnewtask = allInputformsRed?.formStructureData
  let projectIndex = projectAddnewtask?.findIndex((item) => item.name === "project_name")
  projectAddnewtask = [
    ...projectAddnewtask.slice(0, projectIndex),
    ...projectAddnewtask.slice(projectIndex + 1)
  ]
  if (project && defaultProjectvalue) {
    let project_team_mates = allProjects.find((e) => e.trackingprojectid == defaultProjectvalue)
    if (project_team_mates) project_team_mates = project_team_mates.teammates
    if (project_team_mates) {
      project_team_mates.forEach((element) => {
        defaultProjectteammates.push({ label: element.label, id: element.id })
      })
    }
  }

  let result = []
  let team_mates = allProjects.find((e) => e.trackingprojectid === formvalue?.id)
  if (team_mates) team_mates = team_mates.teammates
  if (team_mates) {
    team_mates.forEach((element) => {
      result.push({ label: element.label, id: element.id })
    })
  }

  if (allProjects.length > 0) {
    let result = []
    allProjects.forEach((element) => {
      result.push({ label: element.label, id: element.trackingprojectid })
    })
    allProjects = result
  }

  if (allTaskTypes.length > 0) {
    let result = []
    allTaskTypes.forEach((item) => result.push({ label: item.label, id: item.id }))
  }
  /************************************************/

  /******************************************************/
  allProjects = isAbleToAddNewProject ? [...addNewProject, ...allProjects] : allProjects

  let newInputFormArr = inputFormArr

  newInputFormArr = newInputFormArr?.map((item) => {
    if (item.name === "project_name") {
      item.options = allProjects
    }
    if (item.name === "tasktype") {
      item.options = allTaskTypes
    }
    return item
  })

  // useEffect(() => {
  //   if (project) {
  //     console.log("-----Arry value ----")
  //   }
  // }, [])

  let allAddTaskInputs = allInputformsRed?.formStructureData ?? []

  allAddTaskInputs = allAddTaskInputs?.map((item) => {
    if (item.name === "project_name") {
      item.options = allProjects
    }
    if (item.name === "tasktype") {
      item.options = allTaskTypes
    }
    return item
  })

  /******************************* Functions *******************************/
  const SubmitForm = async (form, valid, setEditorState) => {
    let Obj = {
      title: form.title,
      trackingprojectsid: project ? defaultProjectvalue : form.project_name.id,
      type: form.tasktype.id,
      priority: form.priority.label,
      backlogs: form.backlogs,
      fileurls: fileNameList,
      description: form.description,
      reportedby: user.userId,
      assignedto: form.assignedto.id,
      timezone: timezone,
      referencenumber: form?.referencenumber ?? "",
      startdateandtime: form.startdateandtime,
      enddateandtime: form.enddateandtime,
      label: form?.label ?? "",
      authorizationToken: authToken,
      filesize: fileSize
    }
    if (valid) {
      setLoading(true)
      api
        .post("/tracking/" + trackingClientId, Obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            dispatch(setShowAlert(true))
            setSubmitted(true)
            let item = {
              trackingid: response.data.data.trackingid,
              trackingclientid: response.data.data.trackingclientid,
              trackingprojectsid: response.data.data.trackingprojectsid,
              comments: response.data.data.comments,
              history: response.data.data.history,
              description: response.data.data.description,
              priority: response.data.data.priority,
              fileurls: response.data.data.fileurls,
              title: response.data.data.title,
              type: response.data.data.type,
              trackingprojectstatusid: response.data.data.trackingprojectstatusid,
              assignedto: response.data.data.assignedto,
              reportedby: user.userId,
              reporteddate: response.data.data.reporteddate,
              referencenumber: response.data.data.referencenumber,
              lable: response.data.data.lable,
              projectmodel: response.data.data.projectmodel,
              tasktype: response.data.data.tasktype
            }
            const trackingid = response.data.data.trackingid

            const approvalStatus = response.data.data.approval_status

            allAddTaskInputs.forEach((item) => {
              item.value = ""
            })

            if (form.backlogs === "Yes") {
              if (!form.add_task) {
                if (project) {
                  setPageView(false)
                } else {
                  setTaskview("AllTask")
                }
              }
              setShowAlertBox(true)
              setAlertMessage(
                "(" +
                  trackingClientId +
                  "-" +
                  response.data.data.trackingid +
                  (approvalStatus === ""
                    ? ") New Backlogs item has been created successfully" //? if approvalStatus is "" it is not for hashching users
                    : ") New Backlogs items has been created and waiting for Approval")
              )
            } else {
              if (response.data.data.assignedto === user.userId && approvalStatus === "") {
                myTrackingList.push(item)
              }
              dispatch(setMyTrackings([...myTrackingList]))
              if (approvalStatus === "") {
                allTrackingsList.push(item)
              }
              dispatch(setAllTrackings([...allTrackingsList]))

              handleFileUpload(trackingid)
              setLoading(false)
              if (!form.add_task) {
                if (project) {
                  setPageView(false)
                } else {
                  setTaskview("AllTask")
                }
              }
              setShowAlertBox(true)
              setAlertMessage(
                "(" +
                  trackingClientId +
                  "-" +
                  response.data.data.trackingid +
                  (approvalStatus === ""
                    ? ") New tracking item has been created successfully" //? if approvalStatus is "" it is not for hashching users
                    : ") New Tracking items has been created and waiting for Approval")
              )
            }
          }
          setResetEditorValue(true)
        })
    }
  }

  const handleFileUpload = async (trackingid) => {
    for (let i = 0; i < fileArr.length; i++) {
      try {
        const base64file = await fileToBase64(fileArr[i])

        const response = await api.post(
          "/tracking/fileupload",
          {
            trackingid: trackingid,
            trackingclientid: trackingClientId,
            fileurls: fileArr[i].name,
            fileType: fileArr[i].type,
            filedata: base64file
          },
          {
            headers: {
              authorizationtoken: ` ${authToken}`
            }
          }
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onCancelHandle = (response) => {
    if (response) setCancelConfirmationShow(true)
    else if (project) setPageView(false)
    else setTaskview("AllTask")
  }

  const cancelConfimrationHandler = (response) => {
    if (response) {
      if (project) setPageView(false)
      else setTaskview("AllTask")
    }
  }

  return (
    <>
      <TrackingFormWrap>
        <h4 className='d-flex justify-content-start align-items-center mb-1'>
          <ArrowCircleLeftIcon
            className='me-3'
            onClick={() => {
              // setTaskview("AllTask")
              project ? setPageView(false) : setTaskview("AllTask")
            }}
            style={{ cursor: "pointer", color: "#2D53DA", fontSize: "30px" }}
          />
          <div className='title add-new-task'>Add new task</div>
        </h4>
        <div className='formbody'>
          <Forms
            title={""}
            className='p-2'
            formArr={
              project ? projectAddnewtask : inputFormArr ? newInputFormArr : allAddTaskInputs
            }
            template='template'
            setTaskview={setTaskview}
            formvalue={formvalue}
            FileName={setFileNameLIst}
            FileArr={setFileArr}
            submitBtn={"Create"}
            cancelBtn={true}
            buttonclr={"#3E3F3E"}
            onSubmit={SubmitForm}
            onResetEditor={resetEditorValue}
            onCancel={onCancelHandle}
            showcheckbox={true}
            loading={loading}
            submitted={submitted}
            changeInForm={changeInForm}
            setChangeInForm={setChangeInForm}
            editorRef={editorRef}
            getAllMembers={getAllMembers}
            getAllProjects={getAllProjects}
            allProjects={allProjects}
            allMembers={project ? defaultProjectteammates : result}
            setFileSize={setFileSize}
            setFormvalue={setFormvalue}
            newtask={true}
          />
        </div>
        <ConfirmationBox
          heading={"You may loss your changes. Are you sure to cancel"}
          hidePopup={(e) => setCancelConfirmationShow(false)}
          show={cancelConfirmationShow}
          confirmationResponse={cancelConfimrationHandler}
        />
      </TrackingFormWrap>
    </>
  )
}
