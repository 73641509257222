import React from "react"
import { Row, Col, Container, Card } from "react-bootstrap"
import { BsFillTrashFill } from "react-icons/bs"
import { useSelector } from "react-redux"
import "bootstrap/dist/css/bootstrap.min.css"
import WorkflowBox from "../WorkflowBox"
import WorkflowView from "../../WorkflowView"
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight"
import { dateFormat } from "../../../../../../Utilities/commonFunctions"
import { useFetch } from "../../../../../../Hooks/useFetch"
import { key } from "../../../../../../data/queryKeys"

const VerticalCardView = ({
  allWorkFlow,
  setDeleteConfirmationShow,
  WorkflowEdit,
  setTrackingItemData,
  randDarkColor,
  showDetail,
  updateData,
  handleCardUpdate,
  handleCardChange,
  setEditData,
  EditNodeData,
  temp,
  openView,
  setOpenView,
  viewData,
  setViewData,
  editNode,
  setEditNode,
  editPermission,
  deletePermission,
  createPermission
}) => {
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []
  return (
    <>
      <Container className='wf-container mt-3'>
        <Row className='gap-2'>
          {Object.keys(allWorkFlow).map((key, index) => {
            return (
              <React.Fragment key={index}>
                <Col className='workflow-col'>
                  <Card className='card-bd card-contentV'>
                    <Card.Body>
                      {allWorkFlow[key].map((value, index) => {
                        let length = value.workFlow?.length

                        let project_name = allProjectsData.find(
                          (e) => e.trackingprojectid == value.projectId
                        )

                        if (project_name) value.project_name = project_name.label
                        else value.project_name = "Not Assigned"

                        const addWorkflowBox = (length, key) => {
                          let newfield = { [length]: "" }
                          {
                            allWorkFlow[key].map((value) => {
                              let temp = [...value.workFlow, newfield]
                              return (value.workFlow = temp)
                            })
                          }
                          setEditNode({
                            editable: true,
                            index: length,
                            key: key,
                            value: value,
                            newNode: true
                          })
                          EditNodeData(value.workFlow)
                          setEditData(value)
                        }

                        return (
                          <React.Fragment key={index}>
                            {value.workFlowId === "default"
                              ? null
                              : deletePermission && (
                                  <div className='float-end'>
                                    <BsFillTrashFill
                                      onClick={() => {
                                        setDeleteConfirmationShow(true)
                                        setTrackingItemData(
                                          value.workFlowId !== null &&
                                            value.workFlowId !== undefined
                                            ? value.workFlowId
                                            : value.workFlowid !== null &&
                                              value.workFlowid !== undefined
                                            ? value.workFlowid
                                            : ""
                                        )
                                      }}
                                      className='plusIcon mx-1'
                                      style={{
                                        color: "#2D53DA",
                                        cursor: "pointer"
                                      }}
                                    />
                                  </div>
                                )}
                            <div className='pe-2' style={{ fontSize: 12 }}>
                              <span className='workflow-name'>
                                <strong className='me-2'>Workflow ID:</strong>
                                <span
                                  className=''
                                  style={{
                                    color: "#2D53DA",
                                    cursor: "pointer"
                                  }}
                                  onClick={() => {
                                    setOpenView(true)
                                    setViewData({
                                      value: value,
                                      key: key
                                    })
                                  }}
                                >
                                  {value.workFlowId}
                                </span>
                              </span>
                              <span className='workflow-name workflowname'>
                                <strong className='me-2'>Workflow name:</strong>
                                {value.workFlowName}
                              </span>
                              <span className='workflow-name workflowname'>
                                <strong className='me-2'>Project name:</strong>
                                {value.project_name}
                              </span>
                              <span className='workflow-name workflowname'>
                                <strong className='me-2'>Created On:</strong>
                                {dateFormat(value.createdOn)}
                              </span>
                            </div>
                            <div
                              className={
                                length > 4 ? "blur-block vertical-container" : "vertical-container"
                              }
                            >
                              {value.workFlow?.map((e, index) => {
                                let viewdata = []
                                const workFlowLength = index + 1
                                Object.keys(e).map((n) => {
                                  viewdata.push(e[n])
                                })

                                return (
                                  <React.Fragment key={index}>
                                    {workFlowLength <= 10 && (
                                      <WorkflowBox
                                        index={index}
                                        editNode={editNode}
                                        updateData={updateData}
                                        id={key}
                                        allWorkFlow={allWorkFlow}
                                        temp={temp}
                                        viewData={viewdata}
                                        value={value}
                                        handleCardChange={handleCardChange}
                                        setEditNode={setEditNode}
                                        EditNodeData={EditNodeData}
                                        handleCardUpdate={handleCardUpdate}
                                        setEditData={setEditData}
                                        setViewData={setViewData}
                                        verticalview={true}
                                        editPermission={editPermission}
                                      />
                                    )}
                                  </React.Fragment>
                                )
                              })}
                              {editNode &&
                              editNode.editable &&
                              editNode.key === key &&
                              editNode.newNode ? null : (
                                <div
                                  className='verticalplus'
                                  style={{
                                    cursor: "pointer",
                                    color: "#2D53DA"
                                  }}
                                  onClick={() => {
                                    addWorkflowBox(length, key)
                                  }}
                                >
                                  {createPermission ? <LocalHospitalIcon /> : ""}
                                </div>
                              )}
                            </div>

                            <span
                              className='link more'
                              onClick={(e) => {
                                setOpenView(true)
                                setViewData({
                                  value: value,
                                  key: key
                                })
                              }}
                            >
                              show more
                              <KeyboardDoubleArrowRightIcon />
                            </span>
                          </React.Fragment>
                        )
                      })}
                    </Card.Body>
                  </Card>
                </Col>
              </React.Fragment>
            )
          })}
        </Row>
      </Container>
      {/* {openView ? (
        <WorkflowView
          setOpenView={setOpenView}
          openView={openView}
          viewData={viewData}
          randDarkColor={randDarkColor}
          setViewData={setViewData}
          setEditNode={setEditNode}
          EditNodeData={EditNodeData}
          setEditData={setEditData}
          handleCardUpdate={handleCardUpdate}
          handleCardChange={handleCardChange}
          updateData={updateData}
          editNode={editNode}
          allWorkFlow={allWorkFlow}
          editPermission={editPermission}
        />
      ) : null} */}
    </>
  )
}

export default VerticalCardView
