import Styled from 'styled-components';

const MydashboardModalView = Styled.div`

.project_card{
  cursor:pointer;
  transition: border-color 0.5s ease;

  &:hover {
          border:1px solid #2D53DA
        }
}

.project_tab_col{
  width:20%;
}

.my_team_select{
  width:50%;
}

.total_chart_container{
  display:grid;
  grid-template-columns:73% 27%;
}

.project_barchart_con{
  display: ;
}

.project_barchart_canvas{
  width:100%;
  height: 325px !important;
  padding:10px;
}

.myDashboard_doughnut_chart {
    height: 310px !important;
}

.table-view-card{
    background-color: ${({ theme }) => theme["tableViewCard"]};
}

.table-responsive{
  background-color: ${({ theme }) => theme["tableViewCard"]};
}

.project_sub_head{
  color: ${({ theme }) => theme["themeColor"]};
}

.trackingPage{
  .table-view-card
  {
      background-color: ${({ theme }) => theme["tableViewCard"]};
  };
}

.table-responsive {
    overflow: auto hidden;
    border-radius: 10px;
}
.team-name-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display:flex;
    justify-content:center;
    align-items:center;
    /* font-weight: 100;
    padding: 8px 12px; */
  }

.btn-close {
    margin-bottom: 20px;
    margin-right: 5px;
}
.dropdown-toggle::after
{
    display:none;
}
.task_status {
    display: grid;
    grid-template-columns: 32.5% 32.5% 32.5%;
    justify-content: space-between;
}
.budget_timeline{
    display: grid;
    grid-template-columns: 49.5% 49.5%;
    justify-content: space-between;
}
.dashboard_btn , .version_btn{
  font-size : 15px;
  cursor: pointer;
}
.active {
    color: #2D53DA
}
.view {
    margin-left: 1rem;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-bottom: 1rem;
}
.h_budget{
  color: #2D53DA
  font-weight:bold;
}
.card-body {
    padding: 0px !important;
}
.budget_inside {
    padding: 15px;
    display: flex;
    height: 85px;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius:10px;
}
.timeline_inside{
    background-color: ${({ theme }) => theme["tableViewCard"]};
    border-radius:10px;
    height: 85px;
    padding: 15px;
}
.timeline_head{
    color: ${({ theme }) => theme["themeColor"]};
    display: flex;
    justify-content: space-between;
}
.progress{
  margin-top: -4px;
}
.progress-bar {
    background: #2D53DA;
    border-radius: 6px;
}
.project_card {
    color: ${({ theme }) => theme["themeColor"]};
    border: 1px solid rgb(62 61 61);
    border-radius: 10px;
    text-align: center;
}
.modal-header {
    position: relative;
}
.project_dashboard_chart{
  background-color: ${({ theme }) => theme["tableViewCard"]};
  border-radius: 10px;
}
.bar_chart_container{
  background-color: ${({ theme }) => theme["tableViewCard"]};
  width: 100%;
  margin: auto;
  border-radius: 10px;
}

.tr-color{
  color: ${({ theme }) => theme["themeColor"]};
}

.modal-header:after {
  background: linear-gradient(to right, #4285f4 25%, #34a853 25%, #fabb05 50%, #e94235 75%);
    position: absolute;
    content: '';
    height: 4px;
    right: 0;
    left: 0;
    bottom: 0;
}

.trackingPage .table-responsive {
    overflow: hidden;
    overflow-x: auto;
}

.trackingPage table thead th {
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
}

.userRoleTypePart  {
    color: ${({ theme }) => theme["body-text"]};
}

.closeicon{
  margin-top: 2px;
  color: #2D53DA;
  float: left;
}

.block-icon{
  color: red;
}

.projectconatiner{
  background-color:${({ theme }) => theme["tableViewCard"]};;
}

.serchDropdownMenu
{
    min-width: 250px;
    padding: 10px;
    top: 5px !important;
    box-shadow: 0px 2px 11px 0 rgb(0 0 0 / 24%);
    border: none;
}
.serchDropdownMenu input
{
    font-size:13px;
}

.filter-dropdown label
{
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  margin-bottom: 5px;
}

.filter-dropdown .dropdown-menu {
  inset: 0px 45px auto auto !important;
  transform: inherit !important;
  top: 45px !important;
  right: 0 !important;
  width: 500px !important;
  position:relative;
  }
  .filter-dropdown .dropdown-menu:after {
    border: solid transparent;
    content: " ";
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 12px solid #ffffff;
    position: absolute;
    top: -6px;
    right: 15px;   
    transform: rotate(222deg);
}

.titlecancel, .titleupdate{
        padding: 4px 4px;
        color:  #9d9d9d;
        background: #f1f1f1;
        border: 2px solid #d3d3d3; 
        border-color: #d3d3d3;
        &:hover {
          background: #a6a6a6;
          color: white;
        }
    }
  .filter-dropdown .crosIcon
  {
    width: 15px;
    height: 15px;
  }
  .tracking-count {
     margin-right: 7px;
     margin-bottom : 5px;
     padding: 6px;
     border-radius: 5px;
     font-size: 13px;
     color : white;
     display: inline-block;
  }

  .tasks-tabs .nav-link.active {
    color:  #2D53DA;
  }
  
  .tasks-tabs  .nav-link:hover {
    border-color:  #292a29;
  }

  .nav-tabs .nav-link {
    border: 0px;
  }
 
  .edit-content { 
    width: 300px;
    display: flex;
    align-items:center;
    justify-content:center;
    padding: 7px 20px 7px 10px;
    color: ${({ theme }) => theme["themeColor"]};
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  .select-options{
    border-radius: 5px;
    padding: 0px 10px;
    width :210px;
    height: 30px;
  }

  select.select-options.form-select.form-select-sm{
    color: #212529;
    background-color: #fff;  
  }

  .imgRow {
    margin-top: 60px !important;
    display: inline-flex;
    flex-direction: row;
  }
  .downloadbtn{
    color: ${({ theme }) => theme["themeColor"]}; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  [data-rmiz] {
  }
  .file {
    display: inline-flex;
    flex-direction: row;
  }
  .Filecontainer {
    margin-bottom: 30px;
  }
  .filename{
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 9px;
    height: 30px;
    margin-top: 5px;
    width: 120px;
     display: flex;
     align-items: center;
     justify-content: center;
     overflow: hidden;
  }
  .videoname {
    right: 105px;
  }

  .refreshicon{
    float: right;
    color: #2D53DA;
    bottom: 9px;
  }

.nodata{
  color: ${({ theme }) => theme["themeColor"]};
  display: flex;
  align-items: center;
  justify-content: center;
}

.savechangesbtn {
  float: right;
}

.removebtn{
  right : 13px;
  bottom: 30px;
}
.deletebtn {
    bottom: 20px;
    color: #c60202;
    height: 20px;
    width: 1px;
  }
  .videodwn {
    right : 106px;
  }
  .addbtn {
    color: ${({ theme }) => theme["themeColor"]};
  }
  .downloadicon{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .imgRow {
    margin-top: 60px !important;
    display: inline-flex;
    flex-direction: row;
  }
  .edit-content:hover { 
    border-radius: 2px;
    background-color: ${({ theme }) => theme["tableViewCard"]};
  }
  .edit-cross {
    font-size:10px;
    width: 20px;
    height : 20px;
    border : 1px solid #c5bfbf;
    border-radius: 5px;
    background: #f1f1f1;
    color: #998e8e;
    cursor: pointer;
    padding-top : 3px;
    margin-top:3px;
  }
  .clone-box {
    text-align: center;
  }
  .clone {
    cursor: pointer;
    width: 150px;   
    background-color: ${({ theme }) => theme["Clonebg"]};
    padding: 5px;
    border-radius: 50px;
    color: #3a3a3a;
  }
  .clone:hover {
    background-color: ${({ theme }) => theme["Cloneag"]};
    color : #3a3a3a;
  } 

  .description-edit {
    /* border: 1px inset white;
    border-radius: 8px; */
    margin-left: 5px;
    background-color : #00000000 !important;
  }
  .comment-text {
    width:100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
  }
  /******************************/
  .my_Chart_container_title{
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 18px;
  }
  .my_Chart_datae_section{
    display: flex;
    align-items: center;
    gap: 1rem;
    color: ${({ theme }) => theme["themeColor"]};
    padding-bottom: 2rem;
    p{
        margin-bottom: 0rem;
    }
  }
  .chart_date_filter{
    width: 200px;
  }
  /************************************/
  .image-view-document{
    div{
      img{
        height: 120px;
        width: 120px;
      }
    }
  }
  .__react_modal_image__modal_container{
    top: 65px;
  }
  .__react_modal_image__modal_content{
    div{
      img{
        height: unset;
        width: unset;
      }
    }
  }
  .__react_modal_image__header{
    padding: 10px 20px 10px 50px; 
  }
  .back-icon-rounded{
    margin-right:30px;
  }
  .description-project{
      p{
        margin-bottom:0;
      }
  }
  @media (min-width: 576px) {
    .pagetitle
    {
        display:none;
    }
   }
@media (max-width: 991px) {
 
}
@media (min-width: 992px) {
    .trackingPage table tbody tr td .mobHead {
        display: none
    }
    .fileinput {
      width: 700px !important;
     }
     .fileuploadcontainer{
       display: flex;
       align-items: center;
       justify-content: center;
     }
    
}

@media (max-width: 1350px) {
    .trackingPage table tbody tr td {
        // white-space: nowrap;
    }
}


@media (max-width: 1199px) {
    .trackingPage table tbody tr td {
        // white-space: nowrap;
    }

    .addTrack {
        padding-left: 0;
    }

    .addTrack .btn {
        padding: 8px 10px;
        font-size: 13px;
    }

}

@media (max-width: 991px) {

    .addTrack {
        padding-left: 15px;
        margin-top: 10px;
    }

    .addTrack .add-new-text {
        // display:none;
    }


    .trackingPage table tbody tr td {
        // white-space: inherit;
    }

   
    .trackingPage table thead th {
        display: none;
    }

    .trackingPage table tbody tr td {
        white-space: break-spaces;
        padding: 10px 15px !important;
        width: auto !important;
        display: block;
    }

    .taskviewtable table tbody tr td{
      white-space: break-spaces;
      padding: 10px 15px !important;
      width: auto !important;
      display: block;
    }

    .trackingPage table tbody,
    .trackingPage table td,
    .trackingPage table tr {
        // border-width: 0 !important;
    }

    .trackingPage table tr {}

    .trackingPage table.table-striped>tbody>tr:nth-of-type(odd)>* {
        // --bs-table-accent-bg: #fff;
    }
    .trackingPage table tbody tr td.actionBtns {
        border-top: 1px solid #ddd;
        // --bs-table-accent-bg: #fbfbfb !important;
        border-bottom: 1px solid #ddd;
    }
}

@media (max-width:992px){
  .bar_chart_container{
    width: 100%;
    margin: unset;
}
}
@media (max-width:767px)
{
  .filter-dropdown .dropdown-menu {
    width: 300px !important;
  }
  .task_status{
    grid-template-columns: 48% 48%;
  }
  .project_card {
    min-height: 130px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.budget_timeline {
    grid-template-columns: 100%;
}
}

@media (max-width: 612px) {

.select-options{
  width: 150px;
}

.edit-content{
  width: 180px;
  word-break: break-all;
}
}
      
@media (max-width:575px)
{
  .filter-dropdown .dropdown-menu {
    right: -150px !important;
  }
  .filter-dropdown .dropdown-menu:after {
    right: 50%;
    margin-right: 15px;
    }
    .pagetitle
    {
        color: ${({ theme }) => theme["themeColor"]};
    }
    .Filecontainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
}

@media (max-width: 387px) {

  .select-options{
    width: 100px;
  }

  .edit-content{
    width: 180px;
    word-break: break-all;
  }
}

@media  (max-width: 500px) {
  .total_chart_container{
    display:flex;
    flex-direction:column;
    gap:1rem;
    flex-wrap:wrap;
}
  .doughnut_chart {
      width: 90%;
      } 
}
@media (min-width: 501px) and (max-width: 768px) {

.total_chart_container{
  display:flex;
  flex-direction:column;
  gap:1rem;
  flex-wrap:wrap;
}
.doughnut_chart {
    width: 70%;
    height: unset!important;
} 

}
@media (min-width: 769px) and (max-width: 1024px) {

  .total_chart_container{
    display:flex;
    flex-direction:column;
    gap:1rem;
    flex-wrap:wrap;
}
  .doughnut_chart {
      width: 50%;
      height: unset!important;
  } 
  
}
@media (min-width: 1025px) and (max-width:1124px) {
  .total_chart_container{
    display:grid;
    grid-template-columns:70% 30%;
}
.bar_chart_container{
  padding:0px !important;
}
  .project_barchart_canvas{
    width:100%;
    height: 245px !important;
    padding:10px;
  }
  .myDashboard_doughnut_chart {
      height: 200px !important;
  }
}
@media (min-width: 1125px) and (max-width:1220px) {
  .total_chart_container{
    display:grid;
    grid-template-columns:70% 30%;
}
.bar_chart_container{
  padding:0px !important;
}
  .project_barchart_canvas{
    width:100%;
    height: 273px !important;
    padding:10px;
  }
  .myDashboard_doughnut_chart {
      height: 225px !important;
  }
}
@media (min-width: 1221px) and (max-width:1320px) {
  .total_chart_container{
    display:grid;
    grid-template-columns:70% 30%;
}
.bar_chart_container{
  padding:0px !important;
}
  .project_barchart_canvas{
    width:100%;
    height: 273px !important;
    padding:10px;
  }
  .myDashboard_doughnut_chart {
      height: 225px !important;
  }
}

@media (min-width: 1220px) { 

 .projectconatiner{
   padding : 5px 110px  5px 110px ! important;
 }
 .title{
   padding : 5px 5px  5px 90px ! important;
 }
 .back-icon-rounded{
    margin-right:100px;
  }
}
@media (max-width: 1220px) and (min-width: 992px) {
.edit-content{
 width: 230px;
}
trackingviewtbody{
 margin-left: 50px;
}
}

`;
export default MydashboardModalView;