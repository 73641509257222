import Styled from "styled-components";

const ActivityWrap = Styled.div`
    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: #fff;
        background-color: #3c4043;
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        color: ${({ theme }) => theme["background-color"]};
        background-color: ${({ theme }) => theme["themeColor"]};
    }

    .bar-loader{
        margin-right: 6px;
    }

    .comment-dropdown{
        background-color: ${({ theme }) => theme["tableViewCard"]};
        color: ${({ theme }) => theme["body-text"]};
    }

    .nav-pills .nav-link {
     font-size: 15px;
     padding: 4px 8px 4px 8px;
     color: ${({ theme }) => theme["themeColor"]};
     cursor: pointer;
    }
    
    .profile-img{
        border-radius : 50%;
        height: 40px;
    }

    .comments-section,.history-section,.editor-section{
        color: ${({ theme }) => theme["themeColor"]};
        word-break: break-word;
    }
    
    .status{
        font-size:12px;
    }

    .titleupdate{
        padding: 4px 4px;
    }

    .titlecancel{
        padding: 4px 4px;
        color:  #9d9d9d;
        background: #f1f1f1;
        border: 2px solid #d3d3d3; 
        border-color: #d3d3d3;
    }

    .old-status{
        background: #cbcbd1;
        padding: 2px;
        color: black;
        font-size:12px;
    }
    .new-status{
        background: #c0e1f9;
        padding: 2px;
        color: black;
        font-size:12px;
    }

    .add-comment .form-control{
        background-color: ${({ theme }) => theme["tableViewCard"]};
    }
    .add-comment .form-control::placeholder{
        color: ${({ theme }) => theme["placeholder"]};
        opacity:0.5;
    }
    
    .imgfirstLetr
    {
        display: inline-block;
        background-color: #2D53DA;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        color: #fff;
        box-shadow: inset 0px 3px 6px #ffffff30, 0px 3px 6px #00000029;
        font-size: 18px;
        padding: 3px 6px;
        margin: 0 auto;
        text-align: center;
    }
    .comments-section p
    {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
    }
   .editCommentPart
   {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
   }



`;

export default ActivityWrap;
