import React from "react"
import { Row, Col, Button, Spinner } from "react-bootstrap"
import RefreshIcon from "@mui/icons-material/Refresh"
import Searchbox from "../../../../Reuseable/Searchbox"
import Styled from "styled-components"

export const BacklogsListHeader = ({
  refreshAllTracking,
  searchValue,
  handleSearch,
  allMembers,
  allBacklogs,
  handleHeaderCheckboxChange,
  selectAll,
  isLoading,
}) => {
  if (allBacklogs.length > 0) {
    let result = []
    allBacklogs?.forEach((element) => {
      result.push({ label: element.label, value: element.id })
    })
    allBacklogs = result
  }

  if (allMembers.length > 0) {
    let result = []
    allMembers?.forEach((element) => {
      result.push({ label: element.personname, value: element.trackinguserid })
    })
    allMembers = result
  }

  return (
    <>
      <BacklogsHeaderView>
        {allBacklogs.length > 0 && (
          <Row className='pb-2'>
            <Col lg={3} md={12}>
              {" "}
              <Searchbox
                handleSearch={handleSearch}
                value={searchValue}
                placeholder={"Tracking No."}
              />{" "}
            </Col>
            <Col lg={9} md={12} className='text-end addTrack'>
              <Button
                className={
                  allBacklogs.length > 0
                    ? "select_all_backlogs"
                    : "select_all_backlogs disabeled_btn disabled"
                }
                onClick={handleHeaderCheckboxChange}
                checked={selectAll}
              >
                Select all
              </Button>
              <button
                className='me-1 p-0 refresh_btn btn-rad'
                onClick={(e) => {
                  refreshAllTracking()
                }}
              >
                {isLoading ? (
                  <Spinner size='sm' variant='light' animation='border' />
                ) : (
                  <RefreshIcon />
                )}
              </button>
            </Col>
          </Row>
        )}
      </BacklogsHeaderView>
    </>
  )
}

const BacklogsHeaderView = Styled.div`
  .refresh_btn{
    padding: 0px 0px;
    background-color: #2D53DA;
    border-color: #2D53DA;
    width: 28px;
    height: 28px;
    color: #ffffff;
  }
`
