/* eslint-disable eqeqeq */
import React, { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Button, Spinner } from "react-bootstrap"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import BlockIcon from "@mui/icons-material/Block"
import {
  setAllTrackings,
  setFetchedAllTrackingsList,
  setStartAllTrekId,
  setAllTaskSelectedProject,
  setAllTaskView,
  setalltask
} from "../../../../redux/Tracking/actionCreator"
import { ConfirmationBox } from "../../../Reuseable/ConfimationBox"
import norecordsvg from "../../../../assets/images/norecord.svg"
import TrackingModalView from "./style"
import { TrackingView } from "./TrackingView"
import { TrackingListHeader } from "./TrackingListHeader"
import { TrackingListColumns } from "./TrackingListColumns"
import { NewTracking } from "../NewTracking"
import { Clone } from "./Clone"
import NoDataFound from "./NoDataFound"
import { v4 as uuid } from "uuid"
import { AddNewProject } from "../Projects/AddNewProject"
import { useSessionStorage } from "react-use"
import usePrevious from "../../../../Hooks/usePrevious"
import useTrackingItemData from "../../../../Hooks/useTrackingItemData"
import { useDelete } from "../../../../Hooks/useDelete"
import { useFetch } from "../../../../Hooks/useFetch"
import { key } from "../../../../data/queryKeys"
import trashIcon from "../../../../assets/images/icons/bluedelete12.svg"
import moment from "moment"
import CopyIcon from "../../../../assets/images/icons/copy.svg"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Popover from "react-bootstrap/Popover"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import Copy from "copy-to-clipboard"

const Tracking = ({ permission, backlogsPermission, projectsPermission }) => {
  const editPermission = permission?.edit
  const createPermission = permission?.create
  const deletePermission = permission?.delete
  
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  /**************************for all projects*********************************/
  const { data: allProjectsRes, isLoading: allProjectsLoading } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []
  /**************************for all members*********************************/
  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []
  /******************************all Task Types*****************************/
  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTasksTypes = allTasksTypesRes?.data ?? []
  /****************************all workflows*******************************/
  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )

  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []
  /***********************************************************************************/

  const fetchedAllTrackings = useSelector((state) => state.Tracking.fetchedAllTrackings)
  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let tempalltask = useSelector((state) => state.Tracking.allTaskTempData)

  const [getallchecked, setGetAllChecked] = useState([])

  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  let Work_Flow = Object.keys(allWorkFlow).map((key) => allWorkFlow[key])
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)

  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [fileLoading, setFileLoading] = useState(false)
  const [filterloading, setFilterLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")

  const [showView, setShowView] = useState(false)
  const [trackingItemData, setTrackingItemData] = useTrackingItemData()
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [clonetaskData, setCloneTask] = useState({})

  /****************date filter**************/
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)

  let taskview = useSelector((state) => state.Tracking.allTaskView)

  const isProjectExist = (project) => {
    const result = allProjectsData.find(
      // eslint-disable-next-line eqeqeq
      (e) => e.trackingprojectid == project || e.number == project
    )
    return result !== undefined ? true : false
  }

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  const dispatch = useDispatch()

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    typeSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters?.projects,
    typeFilter: filters?.taskType,
    assignedtoFilter: filters?.assignedTo,
    statusFilter: filters?.status,
    priorityFilter: filters?.priority,
    reportedByFilter: filters?.reportedBy
  })

  const selected_menu = "all_tasks"
  const [changeInForm, setChangeInForm] = useState(false)
  const [showFilter, setShowFilter] = useState(false)
  const [viewTaskData, setViewTaskData] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [updatedData, setUpdatedData] = useSessionStorage("trackingUpdatedData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [trackingLoading, setTrackingLoading] = useState(false)
  const startAllTrackingId = useSelector((state) => state.Tracking.startAllTrackingId)

  const selectedProject = useSelector((state) => state.Tracking.allTaskSelectedProject)
  const [cardui] = useSessionStorage("List", "List")

  const [allcheckTable, setAllCheckTable] = useState([])
  const [selectAllTasks, setSelectAllTasks] = useState(false)

  const handleTasksHeaderCheckbox = () => {
    if (selectAllTasks) {
      setAllCheckTable([])
    } else {
      const allRowIndexes = allTrackingsList.map((item, index) => ({
        item,
        index
      }))
      setAllCheckTable(allRowIndexes)
    }
    setSelectAllTasks((prevSelectAll) => !prevSelectAll)
  }

  const handleMoveCheckboxTable = (item, index) => {
    if (allcheckTable.some((row) => row.index === index)) {
      setAllCheckTable((prevSelectedRows) => prevSelectedRows.filter((row) => row.index !== index))
    } else {
      setAllCheckTable((prevSelectedRows) => {
        if (!Array.isArray(prevSelectedRows)) {
          prevSelectedRows = []
        }
        return [...prevSelectedRows, { item, index }]
      })
    }
  }

  const checkIconStyle = {
    color: "#2D53DA",
    fontSize: "14px",
    marginTop: "-2%",
    paddingRight: "2px"
  }
  const copytextStyle = {
    color: "#2D53DA",
    fontSize: "10px",
    padding: "3px"
  }

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.title,
        projectid: trackingItemData?.trackingprojectsid,
        taskType: trackingItemData?.type,
        assignedTo: trackingItemData?.assignedto,
        priority: trackingItemData?.priority,
        status: trackingItemData?.trackingprojectstatusid,
        description: trackingItemData?.description,
        timeline: trackingItemData?.timeline,
        fileurls: trackingItemData?.fileurls,
        filesize: trackingItemData?.filesize ?? [],
        fileArray: []
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.cloneTitle,
        assignedto: ""
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        title: trackingItemData?.title,
        description: trackingItemData?.description,
        fileurls: trackingItemData?.fileurls,
        timeline: trackingItemData?.timeline,
        filesize: trackingItemData?.filesize
      }
    })
  }, [trackingItemData])

  useEffect(() => {
    if (selected_menu === "all_tasks") {
      setShowFilter(true)
      setTrackingFilters({
        projectsFilter: filters?.projects,
        assignedtoFilter: filters?.assignedTo,
        typeFilter: filters?.taskType,
        statusFilter: filters?.status,
        priorityFilter: filters?.priority,
        reportedByFilter: filters?.reportedBy
      })
    } else {
      setShowFilter(false)
      setTrackingFilters({})
    }
  }, [selected_menu])

  const previousProject = usePrevious(selectedProject) || allProjectsData?.trackingprojectid
  const project = isProjectExist(selectedProject)
    ? selectedProject
    : previousProject || allProjectsData?.trackingprojectid

  const refreshAllTracking = () => {
    fetchTrackingsList(project, "refresh")
  }

  const onFetchTrackingsSuccess = (response) => {
    if (response?.data) {
      dispatch(setAllTrackings([...response.data]))
      dispatch(setalltask([...response.data]))
      setLoading(false)
      setTrackingLoading(false)
      dispatch(setFetchedAllTrackingsList(true))
      if (response.LastEvaluatedKey) {
        dispatch(setStartAllTrekId(response.LastEvaluatedKey.trackingid))
      } else {
        dispatch(setStartAllTrekId("none"))
      }
    } else {
      setTrackingLoading(false)
      dispatch(setAllTrackings([]))
    }
  }

  const { refetch: refetchAllTrackings, isFetched } = useFetch(
    "allTrackings",
    `/tracking/${trackingClientId}?lastEvaluatedKey=null&limit=10`,
    onFetchTrackingsSuccess,
    () => {},
    false,
    false
  )

  const fetchTrackingsList = async (fetchtype) => {
    setTrackingLoading(true)
    setLoading(true)
    try {
      let url = `/tracking/${trackingClientId}?lastEvaluatedKey=${startAllTrackingId}&limit=10`
      const response = await api.get(url, {
        headers: {
          authorizationtoken: ` ${authToken}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      })
      setTrackingLoading(false)
      if (response.data.status) {
        if (selected_menu === "all_tasks") {
          if (response.data.data) {
            if (fetchtype === "refresh") {
              dispatch(setAllTrackings([...response.data.data]))
              dispatch(setalltask([...response.data.data]))
            } else {
              dispatch(setAllTrackings([...allTrackingsList, ...response.data.data]))
              dispatch(setalltask([...tempalltask, ...response.data.data]))
            }
          }
          if (response.data.LastEvaluatedKey) {
            dispatch(setStartAllTrekId(response.data.LastEvaluatedKey.trackingid))
          } else {
            dispatch(setStartAllTrekId("none"))
          }
          dispatch(setFetchedAllTrackingsList(true))
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      setTrackingLoading(false)
      setAllTaskSelectedProject(previousProject || allProjectsData?.trackingprojectid)
    }
  }

  const formatDate = (date) => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")

    return `${year}-${month}-${day}`
  }

  const fetchTrackingFilter = async () => {
    const hasValue = Object.values(trackingFilters).some(
      (value) => value !== undefined && (Array.isArray(value) ? value.length > 0 : !!value)
    )
    if (hasValue || fromDate || toDate) {
      let obj = {
        trackingprojectsid: trackingFilters.projectsFilter?.map((data) => data.value),
        assignedto: trackingFilters.assignedtoFilter?.map((data) => data.value),
        reportedby: trackingFilters.reportedByFilter?.map((data) => data.value),
        fromdate: fromDate && formatDate(fromDate),
        todate: toDate && formatDate(toDate),
        tasktype:
          trackingFilters && trackingFilters.typeFilter
            ? trackingFilters.typeFilter.map((data) => data.value)
            : []
      }
      setFilterLoading(true)
      try {
        let url = `tracking/taskfilter`
        const response = await api.post(url, obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        if (response.data.data) {
          dispatch(setAllTrackings(response.data.data))
          setFilterLoading(false)
        }
      } catch (error) {
        setAllTaskSelectedProject(previousProject || allProjectsData?.trackingprojectid)
      }
    } else {
      {
        tempalltask.length > 0
          ? dispatch(setAllTrackings(tempalltask))
          : dispatch(setAllTrackings(allTrackingsList))
      }
    }
  }

  useEffect(() => {
    if (!isFetched) {
      setTrackingLoading(true)
      setLoading(true)
      refetchAllTrackings()
    }
  }, [refetchAllTrackings, isFetched])

  useEffect(() => {
    if (allTrackingsList === 0) {
      dispatch(setStartAllTrekId(null))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTrackingsList])

  const refreshfiles = (item) => {
    fetchFileData(item)
  }

  const taskViewRender = (value) => {
    dispatch(setAllTaskView(value))
  }

  const fetchFileData = (item) => {
    for (let i = 0; i < item?.fileurls?.length; i++) {
      setFileLoading(true)
      api
        .get(`tracking/fileupload/${trackingClientId}/${item.trackingid}/${item.fileurls[i]}`)
        .then((response) => {
          if (response) {
            let Filename = item.fileurls[i]
            setTaskFile((prevfiles) => [...prevfiles, response.data.url])
            setTaskFileType((prevfiles) => [...prevfiles, Filename.split(".").pop()])
            setTaskFileList((prevfiles) => [...prevfiles, Filename])
            setFileLoading(false)
          } else {
            console.error("Error")
          }
        })
    }
  }

  const { data: allProjectModalsRes } = useFetch(
    key.get_project_modal_value,
    `/projectmodellist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectModals = allProjectModalsRes?.data ?? []

  let ProjectModoptions = []
  if (allProjectModals.length > 0) {
    allProjectModals.forEach((item) =>
      ProjectModoptions.push({
        label: item.ModelTitle,
        id: item.ProjectModelId
      })
    )
  }

  let AllProjects = []
  if (allProjectsData.length > 0) {
    allProjectsData.forEach((element) => {
      AllProjects.push({ label: element.label, id: element.trackingprojectid })
    })
  }

  const getData = (data) => {
    const result = data
      ?.sort((item1, item2) => {
        if (trackingSortings.selectedSortingItem === "priority") {
          if (trackingSortings.prioritySorting) {
            if (trackingSortings.prioritySorting === "down") {
              if (item1.priority < item2.priority) return 1
              else return -1
            } else {
              if (item1.priority < item2.priority) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "project") {
          if (trackingSortings.projectNameSorting) {
            if (trackingSortings.projectNameSorting === "down") {
              if (item1.project_name < item2.project_name) return 1
              else return -1
            } else {
              if (item1.project_name < item2.project_name) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "reportedby") {
          if (trackingSortings.reportedBySorting) {
            if (trackingSortings.reportedBySorting === "down") {
              if (item1.reportedByName < item2.reportedByName) return 1
              else return -1
            } else {
              if (item1.reportedByName < item2.reportedByName) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "status") {
          if (trackingSortings.statusSorting) {
            if (trackingSortings.statusSorting === "down") {
              if (item1.status_name < item2.status_name) return 1
              else return -1
            } else {
              if (item1.status_name < item2.status_name) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "assignedto") {
          if (trackingSortings.assignedToSorting) {
            if (trackingSortings.assignedToSorting === "down") {
              if (item1.assignedtoName < item2.assignedtoName) return 1
              else return -1
            } else {
              if (item1.assignedtoName < item2.assignedtoName) return -1
              else return 1
            }
          }
        } else if (trackingSortings.selectedSortingItem === "type") {
          if (trackingSortings.typeSorting) {
            if (trackingSortings.typeSorting === "down") {
              if (item1.taskName < item2.taskName) return 1
              else return -1
            } else {
              if (item1.taskName < item2.taskName) return -1
              else return 1
            }
          }
        } else {
          if (trackingSortings.trackingNumberSorting) {
            if (trackingSortings.trackingNumberSorting === "down") {
              if (parseInt(item1.trackingid) < parseInt(item2.trackingid)) return 1
              else return -1
            } else {
              if (parseInt(item1.trackingid) < parseInt(item2.trackingid)) return -1
              else return 1
            }
          }
        }
      })
      .filter((item) => {
        if (selected_menu === "my_tasks")
          return item.status_name !== "Deployed" && item.status_name !== "Verified"
        else return item
      })
      // .filter((item) => {
      //   return allProjectsData.find((e) => e.trackingprojectid === item.trackingprojectsid) //? only show the tasks which are in the projects
      // })
      .filter((item) => {
        let trackingnumber = trackingClientId + "-" + item.trackingid
        let trackingnumber1 = trackingClientId + item.trackingid
        return (
          trackingnumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          trackingnumber1.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      })
      // .filter((item) => {
      //   if (
      //     trackingFilters.projectsFilter &&
      //     trackingFilters.projectsFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.projectsFilter.filter((element) =>
      //       element.value === item.trackingprojectsid ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      // .filter((val) => {
      //   if (fromDate && toDate && fromDate !== "Invalid date" && toDate !== "Invalid date") {
      //     if (val.reporteddate && val.reporteddate.length) {
      //       const ReportedDate = new Date(val.reporteddate)
      //       const formattedDate = ReportedDate.toLocaleDateString("en-US")
      //       if (moment(formattedDate).isBetween(fromDate, toDate, "day", "[]")) {
      //         return val
      //       }
      //     }
      //   } else {
      //     return val
      //   }
      // })
      // .filter((val) => {
      //   if (fromDate && fromDate !== "Invalid date") {
      //     if (val.reporteddate && val.reporteddate.length) {
      //       const ReportedDate = new Date(val.reporteddate)
      //       const formattedDate = ReportedDate.toLocaleDateString("en-US")
      //       if (moment(formattedDate).isSameOrAfter(fromDate, "day")) {
      //         return val
      //       }
      //     }
      //   } else {
      //     return val
      //   }
      // })
      // .filter((val) => {
      //   if (toDate && toDate !== "Invalid date") {
      //     if (val.reporteddate && val.reporteddate.length) {
      //       const ReportedDate = new Date(val.reporteddate)
      //       const formattedDate = ReportedDate.toLocaleDateString("en-US")
      //       if (moment(formattedDate).isSameOrBefore(toDate, "day")) {
      //         return val
      //       }
      //     }
      //   } else {
      //     return val
      //   }
      // })
      // .filter((item) => {
      //   if (
      //     trackingFilters.typeFilter &&
      //     trackingFilters.typeFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.typeFilter.filter((element) =>
      //       element.value === item.type ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      // .filter((item) => {
      //   if (
      //     trackingFilters.assignedtoFilter &&
      //     trackingFilters.assignedtoFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.assignedtoFilter.filter((element) =>
      //       element.value === item.assignedto ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      // .filter((item) => {
      //   if (
      //     trackingFilters.statusFilter &&
      //     trackingFilters.statusFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.statusFilter.filter((element) =>
      //       element.value === item.trackingprojectstatusid ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      // .filter((item) => {
      //   if (
      //     trackingFilters.priorityFilter &&
      //     trackingFilters.priorityFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.priorityFilter.filter((element) =>
      //       element.value === item.priority ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      // .filter((item) => {
      //   if (
      //     trackingFilters.reportedByFilter &&
      //     trackingFilters.reportedByFilter.length > 0 &&
      //     selected_menu === "all_tasks"
      //   ) {
      //     let filterdData = trackingFilters.reportedByFilter.filter((element) =>
      //       element.value === item.reportedby ? true : false
      //     )
      //     if (filterdData.length > 0) return item
      //     else return false
      //   } else return item
      // })
      .filter((item) => {
        let trackingnumber = trackingClientId + "-" + item.trackingid
        let trackingnumber1 = trackingClientId + item.trackingid
        return (
          trackingnumber.toLowerCase().includes(searchValue.toLowerCase()) ||
          trackingnumber1.toLowerCase().includes(searchValue.toLowerCase()) ||
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        )
      })
      .map((item, i) => {
        let project_name = allProjectsData.find(
          (e) => e.trackingprojectid === item.trackingprojectsid
        )
        if (project_name) item.project_name = project_name.label
        else item.project_name = "Not Assigned"

        let workflowId = allProjectsData.find(
          (e) =>
            e.trackingprojectid == item.trackingprojectsid || e.number == item.trackingprojectsid
        )
        if (workflowId) item.workflowId = workflowId.workflow

        let newArr = []
        Work_Flow.map((e, i) => {
          Object.keys(e).map((n) => {
            newArr.push(e[n])
          })
        })
        let status_name = newArr.find((e) => e.workFlowId == item.workflowId)
        if (status_name) item.workflow = status_name.workFlow

        if (status_name) item.status_name = status_name.workFlow[item.trackingprojectstatusid]
        else item.status_name = "In approval"

        if (item.status_name) {
          let name = []
          Object.keys(item.status_name).map((n) => {
            name.push(item.status_name[n])
          })
          if (status_name) item.status_name = name
          else item.status_name = "In approval"
        }
        let reportedByName = allMembers.find((e) => e.userId === item.reportedby)
        if (reportedByName) item.reportedByName = reportedByName.personname
        let assignedto = allMembers.find((e) => e.userId === item.assignedto)
        if (assignedto) item.assignedtoName = assignedto.personname
        else item.assignedtoName = "Not Assigned"
        let task = allTasksTypes.find((e) => e.id == item.type)
        if (task) item.taskName = task.label
        item.cloneTitle = "Clone - " + item.title

        item.reporteddate = moment(item.reporteddate).format("MMM D, YYYY h:mm A")

        const copyTicketNumber = (e) => {
          var text = trackingClientId + "-" + item.trackingid
          Copy(text)
        }

        return (
          <tr
            key={i}
            val={item}
            onClick={(e) => {
              taskViewRender("taskview")
              setTrackingItemData(
                (selected_menu === "all_tasks" ? allTrackingsList : myTrackingList).find(
                  (element) => element.trackingid === item.trackingid
                )
              )
              // resetState();
            }}
          >
            <td>
              <input
                type='checkbox'
                name='group1'
                className='table_check_box'
                onChange={() => handleMoveCheckboxTable(item, i)} // Wrap the function call inside an arrow function
                checked={allcheckTable?.some((row) => row.index === i)}
                onClick={(e) => e.stopPropagation()}
              />
            </td>

            <td>
              <strong className='mobHead'>Tracking No. : </strong>
              <span
                className='tracking-id'
                onClick={(e) => {
                  setTrackingItemData(
                    (selected_menu === "all_tasks" ? allTrackingsList : myTrackingList).find(
                      (element) => element.trackingid === item.trackingid
                    )
                  )
                  fetchFileData(item)
                }}
              >
                <span>
                  {trackingClientId}-{item.trackingid}
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      display: "inline-block",
                      padding: "6px 0px 3px 3px",
                      cursor: "pointer"
                    }}
                    id={`showicon-index-${i}`}
                    onMouseEnter={(e) => {}}
                    onMouseLeave={(e) => {}}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <OverlayTrigger
                      trigger='click'
                      rootClose='false'
                      placement='top'
                      overlay={
                        <Popover style={copytextStyle}>
                          <CheckCircleIcon style={checkIconStyle} />
                          Copied
                        </Popover>
                      }
                    >
                      <img
                        src={CopyIcon}
                        height={15}
                        width={15}
                        alt='copyIcon'
                        style={{ display: "block" }}
                        onClick={copyTicketNumber}
                      />
                    </OverlayTrigger>
                  </div>
                </span>{" "}
                {item.priority === "blocker" || item.priority === "blocker2" ? (
                  <BlockIcon
                    className='block-icon'
                    style={{
                      cursor: "pointer",
                      color: "red",
                      fontSize: "17px",
                      margin: "0px 0px 3px 0px"
                    }}
                  />
                ) : (
                  <></>
                )}
              </span>
            </td>
            <td>
              <strong className='mobHead'>Title : </strong>
              <span>{item.title}</span>
            </td>
            <td className='text-capitalize'>
              <strong className='mobHead'>Task type : </strong>
              <span>{item.taskName}</span>
            </td>
            <td className='text-capitalize'>
              <strong className='mobHead'>Status : </strong>
              <span>{item.status_name}</span>
            </td>
            <td className=''>
              <strong className='mobHead'>Timeline : </strong>
              <span>{item.timeline ? item.timeline.concat(" Hr") : "-"}</span>
            </td>
            <td>
              <strong className='mobHead'>Reported Date : </strong>
              <span>{item.reporteddate}</span>
            </td>
            <td className='text-capitalize'>
              <strong className='mobHead'>Reported By : </strong>
              <span>{item.reportedByName}</span>
            </td>
            {
              <td className='text-capitalize'>
                <strong className='mobHead'>Assigned To : </strong>
                <span>{item.assignedto !== "" ? item.assignedtoName : "Not Assigned"}</span>
              </td>
            }
            <td>
              <strong className='mobHead'>Priority : </strong>
              <span className='text-capitalize'>
                {item.priority === "blocker"
                  ? "Blocker Level 1"
                  : item.priority === "blocker2"
                  ? "Blocker Level 2"
                  : item.priority === ""
                  ? "-"
                  : item.priority}
              </span>
            </td>
            <td style={{ width: "300", whiteSpace: "nowrap" }} className='text-center'>
              {deletePermission && (
                <Button size='sm' variant='link' className='text-decoration-none pe-1 ps-1'>
                  <img
                    alt='img'
                    src={trashIcon}
                    onClick={(e) => {
                      e.stopPropagation()
                      setDeleteConfirmationShow(true)
                      setTrackingItemData(item)
                    }}
                  />
                </Button>
              )}{" "}
            </td>
          </tr>
        )
      })
    return result
  }

  const [allTrackingData, setAllTrackingData] = useState([])

  const changesInList = useRef(null)

  const changesMade = () => {
    changesInList.current = uuid()
  }

  const deleteOnSuccess = (res) => {
    if (res.status) {
      setShowAlertBox(true)
      taskViewRender("AllTask")
      let index = allTrackingsList.findIndex(
        (element) => element.trackingid === trackingItemData.trackingid
      )
      if (index >= 0) {
        allTrackingsList.splice(index, 1)
      }
      changesMade()
      setAlertMessage("Selected item has been deleted successfully")
    }
  }

  const { mutate: deleteTask } = useDelete(
    "delete-task",
    "/tracking/" + trackingClientId,
    deleteOnSuccess,
    () => {}
  )

  const deleteConfimrationHandler = (response) => {
    if (response) {
      deleteTask(trackingItemData.trackingid)
    }
  }

  const showWorkFlow = allProjectsData?.find(
    (allProject) => allProject.trackingprojectid === project || allProject.number == project
  )?.workflow

  const workflow = Work_Flow?.find(
    // eslint-disable-next-line eqeqeq
    (flow) => flow[0].workFlowId == showWorkFlow
  )

  useEffect(() => {
    setAllTrackingData(getData(allTrackingsList))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allTrackingsList,
    project,
    workflow,
    changesInList.current,
    searchValue,
    trackingFilters,
    trackingSortings,
    fromDate,
    toDate,
    allcheckTable,
    allMembers
  ])

  if (taskview === "add project") {
    return (
      <AddNewProject
        setProjectView={taskViewRender}
        get
        setShowAlertBox={setShowAlertBox}
        setAlertMessage={setAlertMessage}
        onTaskView={false}
        taskViewPanel='AllTask'
      />
    )
  }

  if (taskview === "addnew") {
    return (
      createPermission && (
        <NewTracking
          setTaskview={taskViewRender}
          setShowAlertBox={(val) => setShowAlertBox(val)}
          setAlertMessage={(val) => setAlertMessage(val)}
          refreshAllTracking={refreshAllTracking}
          setLoading={(val) => setLoading(val)}
          loading={loading}
          defaultProjectid={project}
        />
      )
    )
  }

  if (trackingLoading && !fetchedAllTrackings) {
    return (
      <div className='text-center mt-5 mb-5'>
        <Spinner animation='border' variant='info' className='spinner' />
      </div>
    )
  }

  if (
    allProjectsData?.length <= 0 &&
    allTrackingsList?.length <= 0 &&
    (!fetchedAllTrackings || !trackingLoading)
  ) {
    return (
      <>
        {allProjectsLoading ? (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' className='spinner' />
          </div>
        ) : (
          <NoDataFound
            setTaskview={taskViewRender}
            setProjectView={taskViewRender}
            changeInForm={changeInForm}
            setAlertMessage={setAlertMessage}
            setShowAlertBox={setShowAlertBox}
            setChangeInForm={setChangeInForm}
            addtask='add task'
            addAllTask={true}
            headcontent='You have not added any Task to you account.'
            bodycontent='Add a new project or create a task for your team to work on.'
            btncontent='task'
            img={norecordsvg}
            createPermission={createPermission}
            projectsPermission={projectsPermission}
          />
        )}
      </>
    )
  }
  return (
    <>
      {allTrackingsList?.length > 0 || tempalltask?.length > 0 ? (
        <>
          {showAlertBox && (
            <Alert
              variant='success'
              className='text-center alert-sucess alert mt-3 mb-1'
              dismissible
              closeVariant='white'
              onClick={(e) => setShowAlertBox(false)}
            >
              {alertMessage}
            </Alert>
          )}
          <ConfirmationBox
            heading='Are you sure to delete this ?'
            hidePopup={(e) => setDeleteConfirmationShow(false)}
            show={deleteConfirmationShow}
            confirmationResponse={deleteConfimrationHandler}
          />
          {taskview === "AllTask" ? (
            <>
              {allProjectsLoading ? (
                cardui !== "List" && (
                  <div className='text-center mt-5 mb-5'>
                    <Spinner animation='border' variant='info' className='spinner' />
                  </div>
                )
              ) : (
                <></>
              )}

              <TrackingModalView>
                <div className='trackingPage mt-1'>
                  <Tables
                    setDeleteConfirmationShow={setDeleteConfirmationShow}
                    fetchFileData={fetchFileData}
                    selected_menu={selected_menu}
                    setTaskview={taskViewRender}
                    setTrackingItemData={setTrackingItemData}
                    setShowEditTask={setShowEditTask}
                    deletePermission={deletePermission}
                    handleMoveCheckboxTable={handleMoveCheckboxTable}
                    allcheckTable={allcheckTable}
                    setAllCheckTable={setAllCheckTable}
                    setGetAllChecked={setGetAllChecked}
                    getallchecked={getallchecked}
                    setProjectTable={true}
                    columns={
                      <TrackingListColumns
                        handleSearch={handleSearch}
                        trackingSortings={trackingSortings}
                        setTrackingSorting={setTrackingSorting}
                        setSelectAllTasks={setSelectAllTasks}
                        selectAllTasks={selectAllTasks}
                        handleTasksHeaderCheckbox={handleTasksHeaderCheckbox}
                      />
                    }
                    fetchAllTracking={fetchTrackingsList}
                    startTrackingId={
                      searchValue
                        ? "none"
                        : selected_menu === "all_tasks"
                        ? startAllTrackingId
                        : "none"
                    }
                    data={selected_menu === "all_tasks" ? allTrackingData : []}
                    showInfiniteScroll={true}
                    setHistory={setHistory}
                    header={
                      <TrackingListHeader
                        allTrackingsList={
                          selected_menu === "all_tasks" ? allTrackingsList : myTrackingList
                        }
                        fetchTrackingFilter={fetchTrackingFilter}
                        allcheckTable={allcheckTable}
                        setAllCheckTable={setAllCheckTable}
                        setAlertMessage={setAlertMessage}
                        setShowAlertBox={setShowAlertBox}
                        searchValue={searchValue}
                        handleSearch={handleSearch}
                        userRole={user.rolename}
                        userRoleType={user.roletype}
                        createPermission={createPermission}
                        fetchAllTracking={() => {}}
                        refreshAllTracking={refreshAllTracking}
                        setLoading={(val) => setLoading(val)}
                        loading={loading}
                        filterloading={filterloading}
                        trackingFilters={trackingFilters}
                        setTrackingFilters={setTrackingFilters}
                        data={selected_menu === "all_tasks" ? allTrackingData : []}
                        trackingData={selected_menu === "all_tasks" ? allTrackingsList : []}
                        setTaskview={taskViewRender}
                        setFormType={() => {}}
                        setChangeInForm={setChangeInForm}
                        showFilter={showFilter}
                        selectedProject={selectedProject}
                        fromDate={fromDate}
                        toDate={toDate}
                        setFromDate={setFromDate}
                        setToDate={setToDate}
                        backlogsPermission={backlogsPermission}
                      />
                    }
                    notfound={"No Trackings Found"}
                    loading={allProjectsLoading}
                  />
                </div>
              </TrackingModalView>
            </>
          ) : taskview === "taskview" ? (
            <>
              {trackingItemData.title ? (
                <>
                  <TrackingView
                    setCloneTask={setCloneTask}
                    fileLoading={fileLoading}
                    setTaskview={taskViewRender}
                    show={showView}
                    setTaskFile={setTaskFile}
                    setTaskFileType={setTaskFileType}
                    taskfileType={taskfileType}
                    setTrackingItemData={setTrackingItemData}
                    taskfileList={taskfileList}
                    setTaskFileList={setTaskFileList}
                    taskfile={taskfile}
                    refreshfiles={refreshfiles}
                    setShowView={(value) => setShowView(value)}
                    allTaskTypes={allTasksTypes}
                    allProjectsData={allProjectsData}
                    allMemberres={allMembers}
                    allStatus={allFlowStatus}
                    item={trackingItemData}
                    data={viewTaskData}
                    setViewTaskData={setViewTaskData}
                    showEditTask={showEditTask}
                    setShowEditTask={setShowEditTask}
                    updatedData={updatedData}
                    setUpdatedData={setUpdatedData}
                    history={taskHistory}
                    setHistory={setHistory}
                    setShowClone={setShowClone}
                    selected_menu={selected_menu}
                    setDeleteConfirmationShow={setDeleteConfirmationShow}
                    fetchFileData={fetchFileData}
                    deletePermission={deletePermission}
                    editPermission={editPermission}
                    backlogsPermission={backlogsPermission}
                    menu='alltask'
                  />
                  {clonetaskData.title !== undefined ? (
                    <Clone
                      showClone={showClone}
                      setShowClone={setShowClone}
                      item={trackingItemData}
                      allTrackingsList={allTrackingsList}
                      setShowAlertBox={setShowAlertBox}
                      setAlertMessage={setAlertMessage}
                      clonetaskData={clonetaskData}
                      setCloneTask={setCloneTask}
                      setTaskview={taskViewRender}
                      backTo={"AllTask"}
                      taskfile={taskfile}
                      taskfileList={taskfileList}
                      viewTaskData={viewTaskData}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </>
      ) : (
        !loading && (
          <NoDataFound
            setTaskview={taskViewRender}
            setProjectView={taskViewRender}
            changeInForm={changeInForm}
            setAlertMessage={setAlertMessage}
            setShowAlertBox={setShowAlertBox}
            setChangeInForm={setChangeInForm}
            addtask='add task'
            addAllTask={true}
            headcontent='You have not added any Task to your account.'
            bodycontent='Add a new project or create a task for your team to work on.'
            btncontent='task'
            img={norecordsvg}
            createPermission={createPermission}
            projectsPermission={projectsPermission}
          />
        )
      )}
    </>
  )
}

export default memo(Tracking)
