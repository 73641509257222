import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Box } from '@mui/material';
import HorizontalCardView from './Horizontalview';
import VerticalCardView from './Verticalview';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import TableRowsIcon from '@mui/icons-material/TableRows';
import "bootstrap/dist/css/bootstrap.min.css";
import { key } from '../../../../../data/queryKeys';
import { useFetch } from '../../../../../Hooks/useFetch';

const WorkFlowCardView = ({
  WorkflowEdit,
  setTrackingItemData,
  setDeleteConfirmationShow,
  showDetail,
  openView,
  setOpenView,
  viewData,
  setViewData,
  updateData,
  setUpdateData,
  EditNodeData,
  editNode,
  setEditNode,
  setEditData,
  handleCardUpdate,
  handleCardChange,
  editPermission,
  deletePermission,
  createPermission
}) => {

  const { data: allWorkFlowRes } = useFetch(key.get_all_workflow, `/trekrworkflow`, () => { }, () => { }, false, false)
  let allWorkFlow = allWorkFlowRes?.data?.workflowList ?? []

  const [view, setView] = useState('horizontal');
  const [temp, setTemp] = useState(allWorkFlow);

  const addWorkflowBox = () => {
    let newfield = { status: '' }
    setUpdateData([...updateData, newfield])
  }

  return (
    <>
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          color: '#2D53DA',
          '& > *': {
            m: 1,
          },
        }}
      >
        <div>
          <Button
            className={`layout-button ${view === "horizontal" ? "active" : ""}`}
            onClick={() => {
              setView('horizontal')
            }}
          >
            <TableRowsIcon />
          </Button>
          <span className='divide'>|</span>
          <Button
            className={`layout-button ${view === "vertical" ? "active" : ""}`}
            onClick={() => {
              setView('vertical')
            }}
          >
            <ViewColumnIcon />
          </Button>
        </div>
      </Box>

      {
        view === 'horizontal' ?
          <HorizontalCardView
            allWorkFlow={allWorkFlow}
            setDeleteConfirmationShow={setDeleteConfirmationShow}
            WorkflowEdit={WorkflowEdit}
            setTrackingItemData={setTrackingItemData}
            showDetail={showDetail}
            setUpdateData={setUpdateData}
            updateData={updateData}
            handleCardChange={handleCardChange}
            setEditData={setEditData}
            EditNodeData={EditNodeData}
            handleCardUpdate={handleCardUpdate}
            temp={temp}
            setViewData={setViewData}
            viewData={viewData}
            setOpenView={setOpenView}
            openView={openView}
            editNode={editNode}
            setEditNode={setEditNode}
            addWorkflowBox={addWorkflowBox}
            deletePermission={deletePermission}
            editPermission={editPermission}
            createPermission={createPermission}
          />
          :
          <VerticalCardView
            allWorkFlow={allWorkFlow}
            setDeleteConfirmationShow={setDeleteConfirmationShow}
            WorkflowEdit={WorkflowEdit}
            setTrackingItemData={setTrackingItemData}
            showDetail={showDetail}
            setUpdateData={setUpdateData}
            updateData={updateData}
            handleCardChange={handleCardChange}
            setEditData={setEditData}
            EditNodeData={EditNodeData}
            handleCardUpdate={handleCardUpdate}
            temp={temp}
            setViewData={setViewData}
            viewData={viewData}
            setOpenView={setOpenView}
            openView={openView}
            editNode={editNode}
            setEditNode={setEditNode}
            deletePermission={deletePermission}
            editPermission={editPermission}
            createPermission={createPermission}
          />
      }
    </>
  );
}


export default WorkFlowCardView;
