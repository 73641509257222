import Styled from "styled-components";

const ProjectModelWrap = Styled.div`
  .project_model_con{
    color: ${({ theme }) => theme["themeColor"]};
    padding: 35px 35px 12px 35px;
    font-size: 15px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme["tableViewCard"]};
    margin-bottom: 1rem;
  }
  .cursor_pointer{
    cursor:pointer;
  }
  .view_more_content{
    display: flex;
    justify-content: center;
    p{
      cursor: pointer;
      margin-bottom: 0rem;
      font: normal normal 600 15px/26px;
      letter-spacing: 0px;
      color: #2D53DA;
    }
  }
  .templates_note{
    span{
      color: ${({ theme }) => theme["themeColor"]} !important;
      p{
        font-weight: 600;
        color: ${({ theme }) => theme["themeColor"]} !important;
        background-color: transparent !important;
        span{
          color: ${({ theme }) => theme["themeColor"]} !important;
          background-color: transparent !important;
        }
      }
      ul{
        color: ${({ theme }) => theme["themeColor"]} !important;
        background-color: transparent !important;
        li{
          color: ${({ theme }) => theme["themeColor"]} !important;
          background-color: transparent !important;
          span{
            color: ${({ theme }) => theme["themeColor"]} !important;
            background-color: transparent !important;
          }
        }
      }
    }
  }
`
export default ProjectModelWrap;