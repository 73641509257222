import React, {useEffect} from "react"
import { darktheme, theme } from "./config/theme"
import { useDispatch, useSelector } from "react-redux"
import { Route, Routes } from "react-router"
// import unProtectedRoutes from "./unProtectedRoutes"
import { GlobalStyles } from "./assets/styles/js/GlobalStyles"
import { ThemeProvider } from "styled-components"
import ProtectedRouteAuth from "./Auth/ProtectedRouteAuth"
import { setSessionTimeout } from "./redux/Auth/actionCreator"
import NotFound from "./components/Pages/NotFound"
import { SessionBox } from "./components/Reuseable/SessionBox"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import { useProtectedRoutes } from "./useProtectedRoutes"

const App = () => {
  const dispatch = useDispatch()
  const darkmode = false

  const showSessionOut = useSelector((state) => state.Auth.sessionTimeout)

  const { allowedRoutes } = useProtectedRoutes()


  // useEffect(() => {
  //   if (isValidLogin) {
  //     if (pathname === "/" || pathname === "/login" || pathname === "/register") {
  //       navigate(allowedRoutes[0].path ?? "/")
  //     }
  //   }
  // }, [isValidLogin, pathname, navigate, allowedRoutes])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("jwtToken")
    const userRoleId = urlParams.get("roleId")
    if (token) {
      window.localStorage.setItem("token", token)
      window.localStorage.setItem("userRoleId", userRoleId)
      // window.history.pushState("", "", "/")
    }
  }, [])



  return (
    <div className='App'>
      <ThemeProvider theme={darkmode ? darktheme : theme}>
        <GlobalStyles />

        <SessionBox
          show={showSessionOut}
          setShow={() => {
            dispatch(setSessionTimeout(false))
          }}
        />

        <Routes>
          {/* {unProtectedRoutes.map((route) => {
            if (route.route && !isValidLogin) {
              return <Route path={route.route} key={route.key} element={route.component} />
            }
            return null
          })} */}

          <Route element={<ProtectedRouteAuth />}>
            {allowedRoutes?.map((route) => {
              return <React.Fragment key={route.route}>{route.route}</React.Fragment>
            })}

            <Route path='*' element={<NotFound />} />
          </Route>
        </Routes>
      </ThemeProvider>
    </div>
  )
}

export default App
