import React, { useEffect, useState } from "react"
import { setAddNewTemplate } from "../../../../redux/Tracking/actionCreator"
import { useDispatch, useSelector } from "react-redux"
import { Alert, Spinner } from "react-bootstrap"
import AddNewTemplate from "./AddNewTemplate"
import TemplateView from "./style"
import Tables from "../../../Reuseable/Tables"
import ProjectsModalView from "../Projects/style"
import TemplatesCard from "./TemplateCards"
import useTrackingItemData from "../../../../Hooks/useTrackingItemData"
import EditTemplate from "./EditTemplate"
import { useSessionStorage } from "react-use"
import { client } from "../../../.."
import { useFetch } from "../../../../Hooks/useFetch"
import { useDelete } from "../../../../Hooks/useDelete"

const TemplatesTabledata = ({ permission }) => {
  const editPermission = permission?.edit

  const dispatch = useDispatch()
  let addNewTemplateView = useSelector(state => state.Tracking.addNewTemplateView)
  const trackingClientId = useSelector(state => state.Auth.user.clientId)

  const { data: allTemplateTableDataRes, isLoading: loading } = useFetch(
    "get-template",
    `/7_templatelist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allTemplateTableData = allTemplateTableDataRes?.data ?? []

  const [showAlertBox, setShowAlertBox] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [deleteModelId, setDeleteModelId] = useState()
  const [trackingItemData, setTrackingItemData] = useTrackingItemData()
  const [showEditTask, setShowEditTask] = useState("")
  const [viewTaskData, setViewTaskData] = useState({})
  const [updatedData, setUpdatedData] = useSessionStorage("trackingUpdatedData", {})
  const [tempView, setTempView] = useState("")
  const [taskHistory, setHistory] = useState("")

  useEffect(() => {
    setViewTaskData(prevState => {
      return {
        ...prevState,
        templatetitle: trackingItemData.templatetitle,
        notes: trackingItemData.notes,
      }
    })
    // setCloneTask((prevState) => {
    //     return {
    //         ...prevState,
    //         title: trackingItemData.cloneTitle,
    //         assignedto: "",
    //     };
    // });
    setUpdatedData(prevState => {
      return {
        ...prevState,
        templatetitle: trackingItemData.templatetitle,
        notes: trackingItemData.notes,
      }
    })
  }, [trackingItemData])

  const onTemplateDeleteSuccess = async response => {
    if (response.status) {
      setShowAlertBox(true)
      await client.setQueryData(["get-template"], oldData => {
        return {
          ...oldData,
          data: allTemplateTableData?.filter(element => element?.templateid !== deleteModelId),
        }
      })
      setAlertMessage("Selected item has been deleted successfully")
    }
  }

  const { mutate: deleteTemplate } = useDelete(
    "delete-template",
    "/7_templatelist/" + trackingClientId,
    onTemplateDeleteSuccess,
    () => {}
  )

  const deleteConfimrationHandler = response => {
    if (response) {
      deleteTemplate(deleteModelId)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
    }, 2500)
  }, [showAlertBox])

  const taskViewRender = value => {
    dispatch(setAddNewTemplate(value))
  }

  return (
    <>
      {showAlertBox && (
        <Alert
          variant='success'
          className='text-center alert-sucess alert mt-3 mb-1'
          dismissible
          closeVariant='white'
          onClick={e => setShowAlertBox(false)}
        >
          {alertMessage}
        </Alert>
      )}
      <TemplateView>
        {addNewTemplateView === "New Template" ? (
          <AddNewTemplate
            setShowAlertBox={setShowAlertBox}
            setAlertMessage={setAlertMessage}
            setTaskview={taskViewRender}
          />
        ) : addNewTemplateView === "View Templates" ? (
          <EditTemplate
            showEditTask={showEditTask}
            setTaskview={taskViewRender}
            setViewTaskData={setViewTaskData}
            setShowEditTask={setShowEditTask}
            item={trackingItemData}
            data={viewTaskData}
            setHistory={setHistory}
            editPermission={editPermission}
            setUpdatedData={setUpdatedData}
            updatedData={updatedData}
            setTrackingItemData={setTrackingItemData}
          />
        ) : !loading || addNewTemplateView === "All Templates" ? (
          <ProjectsModalView>
            {allTemplateTableData.length > 0 ? (
              <div className='table-view-card rounded-3 mt-3'>
                {allTemplateTableData.map((item, index, allTemplateTableData) => {
                  let borderLine = index !== allTemplateTableData.length - 1 ? true : false
                  return (
                    <TemplatesCard
                      item={item}
                      key={index}
                      setTaskview={taskViewRender}
                      setTrackingItemData={setTrackingItemData}
                      borderLine={borderLine}
                      deleteConfimrationHandler={deleteConfimrationHandler}
                      setDeleteModelId={setDeleteModelId}
                      setTempView={setTempView}
                    />
                  )
                })}
              </div>
            ) : (
              <Tables
                data={[]}
                setProjectTable={true}
                notfound={"No ProjectModel Data found!"}
                loading={loading}
                refreshLoading={refreshLoading}
              />
            )}
          </ProjectsModalView>
        ) : (
          <div className='text-center mt-5 mb-5'>
            <Spinner animation='border' variant='info' className='spinner' />
          </div>
        )}
      </TemplateView>
    </>
  )
}

export default TemplatesTabledata
