import Styled from 'styled-components';

const ProjectVersionsView = Styled.div`

.card-body {
    padding: 0px !important;
}
.table-view-card {
    /* background-color: transparent; */
    background-color: ${({ theme }) => theme["tableViewCard"]} !important;

}
.template_modal_container.modal .modal-content {
    width: 550px;
    border-radius: 10px;
    background-color: white;
    padding: 12px 12px 0px 12px;
  }
.template_select_btn {
    width: 150px;
    height: 35px;
    background: #2D53DA
}
.version_description {
    width: 80%;
    height: 75px;
}
.version_buttons{
    display: flex;
    justify-content:end;
}

@media (min-width: 992px) {
.mobHead
    {
        display:none;
    }
}
@media (max-width: 991px) {
  .addTrack {
    padding-left: 15px;
    margin-top: 10px;
  }
  .addTrack .add-new-text {
  }
  .trackingPage table tbody tr td {
  }
  .trackingPage table thead th {
    display: none;
  }
  .trackingPage table tbody tr td {
    white-space: break-spaces;
    padding: 10px 15px !important;
    width: auto !important;
    display: block;
  }

  .taskviewtable table tbody tr td{
    white-space: break-spaces;
    padding: 10px 15px !important;
    width: auto !important;
    display: block;
  }

  .trackingPage table tbody,
  .trackingPage table td,
  .trackingPage table tr {
  }
  .trackingPage table tr {}
  .trackingPage table.table-striped>tbody>tr:nth-of-type(odd)>* {
  }
  .trackingPage table tbody tr td.actionBtns {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

`

export default ProjectVersionsView;