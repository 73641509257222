import { ActionTypes } from "./actions"

const setSelectedID = (response) => {
  return {
    type: ActionTypes.SET_SELECTED_ID,
    payload: response
  }
}

const setShowAlert = (response) => {
  return {
    type: ActionTypes.SET_SHOW_ALERT,
    payload: response
  }
}

const setalltask = (response) => {
  return {
    type: ActionTypes.SET_ALL_TASK,
    payload: response
  }
}

const setAlertMessage = (response) => {
  return {
    type: ActionTypes.SET_ALERT_MESSAGE,
    payload: response
  }
}

const setAllProjects = (response) => {
  return {
    type: ActionTypes.SET_PROJECTS,
    payload: response
  }
}

const setAllProjectsLoading = (response) => {
  return {
    type: ActionTypes.SET_PROJECTS_LOADING,
    payload: response
  }
}

const setAllProjectModel = (response) => {
  return {
    type: ActionTypes.SET_PROJECTS_MODELS,
    payload: response
  }
}

const setAllTrackings = (response) => {
  return {
    type: ActionTypes.SET_ALL_TRACKINGS,
    payload: response
  }
}

const setFlowStatusList = (response) => {
  return {
    type: ActionTypes.SET_FLOW_STATUS_LIST,
    payload: response
  }
}

const setWorkFlowList = (response) => {
  return {
    type: ActionTypes.SET_WORK_FLOW_LIST,
    payload: response
  }
}

const setDocumentList = (response) => {
  return {
    type: ActionTypes.SET_DOCUMENT_LIST,
    payload: response
  }
}

const setDocumentData = (response) => {
  return {
    type: ActionTypes.SET_DOCUMENT_DATA,
    payload: response
  }
}

const setDocumentNameList = (response) => {
  return {
    type: ActionTypes.SET_DOCUMENT_NAME_LIST,
    payload: response
  }
}

const setMembers = (response) => {
  return {
    type: ActionTypes.SET_MEMBERS,
    payload: response
  }
}

const setTaskTypes = (response) => {
  return {
    type: ActionTypes.SET_TASK_TYPES,
    payload: response
  }
}

const setApprovalTrackings = (response) => {
  return {
    type: ActionTypes.SET_APPROVAL_TRACKING_LIST,
    payload: response
  }
}

const setUserNotifications = (response) => {
  return {
    type: ActionTypes.SET_NOTIFICATIONS,
    payload: response
  }
}

const setFilters = (response) => {
  return {
    type: ActionTypes.SET_FILTERS,
    payload: response
  }
}

const resetTracking = () => {
  return {
    type: ActionTypes.RESET_TRACKING,
    payload: ""
  }
}

const setStartAllTrekId = (response) => {
  return {
    type: ActionTypes.SET_START_ALL_TREK_ID,
    payload: response
  }
}

const setStartMyTrekId = (response) => {
  return {
    type: ActionTypes.SET_START_MY_TREK_ID,
    payload: response
  }
}

const setMyTrackings = (response) => {
  return {
    type: ActionTypes.SET_MY_TRACKINGS,
    payload: response
  }
}

const setFetchedAllTrackingsList = (response) => {
  return {
    type: ActionTypes.SET_FETCHED_ALL_TRACKINGS_LIST,
    payload: response
  }
}

const setFetchedMyTrackingList = (response) => {
  return {
    type: ActionTypes.SET_FETCHED_MY_TRACKINGS_LIST,
    payload: response
  }
}

const setSelectedProject = (response) => {
  return {
    type: ActionTypes.SET_SELECTED_PROJECT,
    payload: response
  }
}

const setMyTaskSelectedProject = (response) => {
  return {
    type: ActionTypes.SET_MY_TASK_PROJECT,
    payload: response
  }
}

const setAllTaskSelectedProject = (response) => {
  return {
    type: ActionTypes.SET_ALL_TASK_PROJECT,
    payload: response
  }
}

const setMyTaskView = (response) => {
  return {
    type: ActionTypes.SET_MY_TASK_VIEW,
    payload: response
  }
}

const setAllTaskView = (response) => {
  return {
    type: ActionTypes.SET_ALL_TASK_VIEW,
    payload: response
  }
}
const setAddPriorityView = (response) => {
  return {
    type: ActionTypes.SET_ALL_PRIORITY_VIEW,
    payload: response
  }
}
const setNewProjectModel = (response) => {
  return {
    type: ActionTypes.SET_NEW_PROJECT_MODEL,
    payload: response
  }
}
const setNewDocumentModel = (response) => {
  return {
    type: ActionTypes.SET_NEW_DOCUMENT_MODEL,
    payload: response
  }
}
const setNewWorkflowModel = (response) => {
  return {
    type: ActionTypes.SET_NEW_WORKFLOW_MODEL,
    payload: response
  }
}

const setApprovalTaskView = (response) => {
  return {
    type: ActionTypes.SET_APPROVAL_VIEW,
    payload: response
  }
}

const setProjectView = (response) => {
  return {
    type: ActionTypes.SET_PROJECT_VIEW,
    payload: response
  }
}

const setWorkFlowView = (response) => {
  return {
    type: ActionTypes.SET_WORKFLOW_VIEW,
    payload: response
  }
}

const setAllMembersFetched = (response) => {
  return {
    type: ActionTypes.SET_ALL_MEMBERS_FETCHED,
    payload: response
  }
}
const setGlobalSearch = (response) => {
  return {
    type: ActionTypes.SET_GLOBAL_SEARCH,
    payload: response
  }
}

const setGlobalSearchResult = (response) => {
  return {
    type: ActionTypes.SET_GLOBAL_SEARCH_RESULT,
    payload: response
  }
}

const setArchiveTableData = (response) => {
  return {
    type: ActionTypes.SET_ARCHIVE_TABLE_DATA,
    payload: response
  }
}
const setInputFromData = (response) => {
  return {
    type: ActionTypes.SET_INPUTFORMDATA,
    payload: response
  }
}
const setMyChartData = (response) => {
  return {
    type: ActionTypes.SET_MY_CHART_DATA,
    payload: response
  }
}
const setKpiPointsData = (response) => {
  return {
    type: ActionTypes.SET_KPIPOINTS_DATA,
    payload: response
  }
}

const setProjectModelData = (response) => {
  return {
    type: ActionTypes.SET_PROJECTMODELDATA,
    payload: response
  }
}
const setReportTasksData = (response) => {
  return {
    type: ActionTypes.SET_REPORTSTASKS_DATA,
    payload: response
  }
}
const setReportTimeloggerData = (response) => {
  return {
    type: ActionTypes.SET_REPORT_TIMELOGGER_DATA,
    payload: response
  }
}
const setReportWeeklyHeaderData = (response) => {
  return {
    type: ActionTypes.SET_REPORTSWEEKLY_DATA,
    payload: response
  }
}
const setAllTaskHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_HEADERS_DATA,
    payload: response
  }
}
const setAllProjectsHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_PROJECTSHEADERS_DATA,
    payload: response
  }
}
const setAllWorkflowHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_WORKFLOWSHEADERS_DATA,
    payload: response
  }
}
const setAllDocumentHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_DOCUMENTHEADERS_DATA,
    payload: response
  }
}
const setAllBacklogsHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_BACKLOGSHEADERS_DATA,
    payload: response
  }
}
const setAllArchiveHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_ARCHIVEHEADERS_DATA,
    payload: response
  }
}
const setAllMyDashBoardHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_MYDASHBOARD_HEADERS,
    payload: response
  }
}
const setAllApprovalsHeadersName = (response) => {
  return {
    type: ActionTypes.SET_All_APPROVALSHEADERS_DATA,
    payload: response
  }
}
const setReportTasksHeadersName = (response) => {
  return {
    type: ActionTypes.SET_REPORT_TASKS_HEADERS_DATA,
    payload: response
  }
}
const setTemplatesData = (response) => {
  return {
    type: ActionTypes.SET_TEMPLATES_DATA,
    payload: response
  }
}
const setAddNewTemplate = (response) => {
  return {
    type: ActionTypes.SET_NEW_TEMPLATES_FORM,
    payload: response
  }
}
const setAllTemplateTableData = (response) => {
  return {
    type: ActionTypes.SET_ALL_TEMPLATES_DATA,
    payload: response
  }
}
const setAllPriorityData = (response) => {
  return {
    type: ActionTypes.SET_ALL_PRIORITY_DATA,
    payload: response
  }
}

const setProjectVersionList = (response) => {
  return {
    type: ActionTypes.SET_PROJECT_VERSION_DATA,
    payload: response
  }
}
const setProjectDashboardData = (response) => {
  return {
    type: ActionTypes.SET_PROJECT_DASHBOARD_DATA,
    payload: response
  }
}
const setAllProjectDashboardData = (response) => {
  return {
    type: ActionTypes.SET_ALL_PROJECT_DASHBOARD_DATA,
    payload: response
  }
}
const setActiveProject = (response) => {
  return {
    type: ActionTypes.SET_ACTIVE_PROJECT,
    payload: response
  }
}
const setCheckAllMoveTask = (response) => {
  return {
    type: ActionTypes.SET_ALL_MOVE_TASKS,
    payload: response
  }
}
const setAllBacklogsTaskView = (response) => {
  return {
    type: ActionTypes.SET_ALL_BACKLOGS_TASKS_VIEW,
    payload: response
  }
}

const setTrackingItemData = (response) => {
  sessionStorage.setItem("trackingItemData", JSON.stringify(response))
  return {
    type: ActionTypes.SET_TRACKING_ITEM_DATA,
    payload: response
  }
}

export {
  setSelectedID,
  setDocumentList,
  setDocumentData,
  setShowAlert,
  setalltask,
  setAlertMessage,
  setAllProjects,
  setSelectedProject,
  setAllProjectsLoading,
  setWorkFlowList,
  setAllTrackings,
  setFetchedAllTrackingsList,
  setFetchedMyTrackingList,
  setFlowStatusList,
  setMembers,
  setTaskTypes,
  setApprovalTrackings,
  setFilters,
  resetTracking,
  setStartAllTrekId,
  setStartMyTrekId,
  setMyTrackings,
  setUserNotifications,
  setMyTaskSelectedProject,
  setAllTaskSelectedProject,
  setDocumentNameList,
  setMyTaskView,
  setAllTaskView,
  setProjectView,
  setWorkFlowView,
  setAllMembersFetched,
  setGlobalSearch,
  setApprovalTaskView,
  setGlobalSearchResult,
  setTrackingItemData,
  setInputFromData,
  setAllProjectModel,
  setArchiveTableData,
  setMyChartData,
  setAllTaskHeadersName,
  setAllProjectsHeadersName,
  setAllWorkflowHeadersName,
  setAllDocumentHeadersName,
  setProjectModelData,
  setKpiPointsData,
  setReportTasksData,
  setAllBacklogsHeadersName,
  setAllArchiveHeadersName,
  setAllApprovalsHeadersName,
  setReportTasksHeadersName,
  setReportWeeklyHeaderData,
  setReportTimeloggerData,
  setAllMyDashBoardHeadersName,
  setTemplatesData,
  setAddPriorityView,
  setNewProjectModel,
  setAddNewTemplate,
  setProjectVersionList,
  setProjectDashboardData,
  setAllPriorityData,
  setAllTemplateTableData,
  setActiveProject,
  setAllProjectDashboardData,
  setCheckAllMoveTask,
  setNewWorkflowModel,
  setNewDocumentModel,
  setAllBacklogsTaskView
}
