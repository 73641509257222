import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../../Reuseable/Forms"
import { Validators } from "../../../../../Utilities/validator"
import { api } from "../../../../../Services/api"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"

const AddNewProjectModel = ({ setTaskview, setAlertMessage, setShowAlertBox }) => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.Auth.user)
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  let formArr = [
    {
      label: "Project model name",
      name: "ModelTitle",
      type: "text",
      halfWidth: false,
      placeholder: "Enter",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true
    },
    {
      label: "Description",
      name: "description",
      type: "textarea",
      halfWidth: false,
      placeholder: "Type here...",
      validators: [
        {
          check: Validators.required,
          message: "Description Field is required"
        }
      ],
      error: false,
      errormsg: "",
      rows: 4,
      value: "",
      show: true
    },
    {
      label: "",
      name: "add_task",
      type: "checkbox",
      placeholder: "",
      validators: [],
      required: false,
      value: false,
      error: false,
      errormsg: "",
      checkbox_label: "Add new model",
      show: true
    }
  ]

  const SubmitForm = (form, valid) => {
    if (valid) {
      setLoading(true)
      let Obj = {
        ModelTitle: form.ModelTitle,
        ModelDescription: form.description,
        Status: "open",
        CreatedBy: user.userName,
        UpdatedBy: ""
      }
      api.post("/projectmodellist/" + trackingClientId, Obj).then(function (response) {
        if (response.status === 200) {
          setShowAlertBox(true)
          setAlertMessage("New Project Model has been created successfully")
          // dispatch(setShowAlert(true));
          setLoading(false)
          setSubmitted(true)
          setTaskview("All ProjectModel")
        }
      })
    }
  }

  return (
    <>
      <h4 className='d-flex justify-content-start align-items-center mb-1'>
        <ArrowCircleLeftIcon
          className='me-3'
          onClick={() => {
            setTaskview("All ProjectModel")
          }}
          style={{ cursor: "pointer", color: "#2D53DA", fontSize: "30px" }}
        />
        <div className='title add-new-task'>Add new Projectmodel</div>
      </h4>
      <Forms
        title={""}
        classes='add-project-container'
        formArr={formArr}
        submitBtn={"Create"}
        onSubmit={SubmitForm}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
      />
    </>
  )
}

export default AddNewProjectModel
