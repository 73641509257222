import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Forms } from "../../../../Reuseable/Forms"
import { Validators } from "../../../../../Utilities/validator"
import { setShowAlert, setAllTemplateTableData } from "../../../../../redux/Tracking/actionCreator"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import { client } from "../../../../.."
import { api } from "../../../../../Services/api"

const AddNewTemplate = ({ setTaskview, defaultProjectid, setShowAlertBox, setAlertMessage }) => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.Auth.user)
  const trackingClientId = useSelector(state => state.Auth.user.clientId)

  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [changeInForm, setChangeInForm] = useState(false)
  const [templateDescription, setTemplateDescription] = useState(true)

  let formArr = [
    {
      label: "Template name",
      name: "templatetitle",
      type: "text",
      halfWidth: true,
      placeholder: "Enter",
      validators: [{ check: Validators.required, message: "This Field is required" }],
      error: false,
      errormsg: "",
      value: "",
      maxLength: 100,
      show: true,
    },

    {
      label: "",
      name: "description",
      type: "textarea",
      halfWidth: false,
      placeholder: "Type here...",
      validators: [
        {
          check: Validators.required,
          message: "Description Field is required",
        },
      ],
      error: false,
      errormsg: "",
      rows: 4,
      value: "",
      show: true,
      templateDescription: true,
    },
  ]

  const SubmitForm = (form, valid) => {
    if (valid) {
      setLoading(true)
      let Obj = {
        templatetitle: form.templatetitle,
        notes: form.description,
      }
      api.post("/7_templatelist/" + trackingClientId, Obj).then(function (response) {
        if (response.status) {
          let item = {
            clientid: response.data.data.clientid,
            notes: response.data.data.notes,
            templateid: response.data.data.templateid,
            templatetitle: response.data.data.templatetitle,
          }
          dispatch(setShowAlert(true))
          client.setQueryData(["get-template"], prevData => {
            return {
              ...prevData,
              data: [...prevData.data, item],
            }
          })
          setTaskview("All Templates")
          setSubmitted(true)
          setShowAlertBox(true)
          setAlertMessage("New Template has been created successfully")
          setLoading(false)
        }
      })
    }
  }

  return (
    <>
      <h4 className='d-flex justify-content-start align-items-center mb-1'>
        <ArrowCircleLeftIcon
          className='me-3'
          onClick={() => {
            setTaskview("All Templates")
          }}
          style={{ cursor: "pointer", color: "#2D53DA", fontSize: "30px" }}
        />
        <div className='title add-new-task'>Add new Template</div>
      </h4>
      <Forms
        title={""}
        classes='add-project-container'
        formArr={formArr}
        submitBtn={"Save"}
        onSubmit={SubmitForm}
        loading={loading}
        submitted={submitted}
        changeInForm={changeInForm}
        setChangeInForm={setChangeInForm}
        templateDescription={templateDescription}
      />
    </>
  )
}

export default AddNewTemplate
