import Styled from "styled-components";

const TableWrap = Styled.div`
.myDashBoard_table{
    border-radius: 8px;
}
.select_check_box{
    display: flex;
    justify-content: center;
    align-items: center;
}
.timeline_isempty{
    color: #AAAAAA;
    opacity: 0.8;
}
.table_check_box{
    cursor: pointer;
    width: 16px;
    height: 16px;
    color: #fff;
    accent-color: #2D53DA;
}
.table_check_box:checked{
    color: #fff;
    background-color: #fff;
}
.table_check_box ~ .checkmark {
  background-color: #fff;
  color: #fff;
}
.table-striped>tbody>tr:nth-of-type(odd)>* {
    color:${({ theme }) => theme["body-text"]};
    --bs-table-accent-bg: unset;
}
.table-hover>tbody>tr:hover>* {
    color:${({ theme }) => theme["body-text"]};
    --bs-table-accent-bg: unset;
}
.table-bordered>:not(caption)>*>* {
    border-width: 0;
}
        .table>:not(:first-child) {
            border-top: 1px solid ${({ theme }) => theme["border"]};
        }
        .table
        {
            // border-color: ${({ theme }) => theme["border"]};
            border-color: transparent;
        }
        table {
            
            border-collapse: collapse;
            thead {
                th {
                    padding: 10px !important;
                    color: ${({ theme }) => theme["body-text"]};
                    background-color: ${({ theme }) => theme["tableHeadBg"]};
                    
                }
            }
            tbody {
                tr {
                    cursor: pointer;
                    box-shadow: inset 0 1px 0 0 ${({ theme }) =>
        theme["bordercolor"]};
                    td {
                        // border: 1px solid #ddd;
                        line-height: 1.42857143;
                        vertical-align: middle;
                        padding: 10px !important;
                        color:${({ theme }) => theme["body-text"]};
                        font-size: 14px;
                        /* width:200px; */
                        p {
                            display: inline;
                            cursor: pointer;
                            margin: 0;
                            /* line-height: 1.5em; */
                            /* height: 20px; */
                            overflow: hidden;
                            white-space: pre-wrap;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;  
                            /* &:hover {
                                height: auto;
                            } */
                        }

                        ul {
                            padding-left: 15px;
                            margin: 0;
                        }
                    }
                    th {
                        // border: 1px solid #ddd;
                        line-height: 1.42857143;
                        vertical-align: middle;
                        padding: 10px !important;
                        color: ${({ theme }) => theme["body-text"]};
                        font-size: 14px;
                        white-space: nowrap;
                    }
                }
            }

            .drag-indicator {
                .drag-icon {
                    /* visibility: hidden; */
                    opacity: .1;
                    
                }

                &:hover {
                    .drag-icon {
                        /* visibility: visible; */
                        opacity: .8;
                    }
                }
            }
            
            .table>:not(:first-child) {
                border-top: 1px solid currentColor;
            }
            .imgfirstLetr {
                display: inline-block;
                background-color: #c3c3c3;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                color: #fff;
                box-shadow: inherit;
                font-size: 18px;
                padding: 6px 6px;
                margin: 0 auto;
                text-align: center;
                vertical-align: middle;
            }
            .imgFirstLetMain {
                span {
                    display: inline-block;
                    vertical-align: inherit;
                }
            }
            .btn-outline-light {
                color: #212529;
                border-color: #d3d3d3;
                &:hover {
                    color: #212529;
                    background-color: #f8f9fa;
                    border-color: #999;
                }
            }
            .indicator {
                background: #5db239;
                border-radius: 50%;
                display: block;
                height: 8px;
                width: 8px;
                padding: 1px;
                position: absolute;
                bottom: 5px;
                top: inherit;
                right: -3px;
                text-align: center;
                transition: top 0.1s ease-out;
                font-size: 0.675rem;
                color: #ffffff;
            }
            .actionLink {
                svg {
                    height: 25px;
                    path {
                        fill: ${({ theme }) => theme["orange-color"]};
                    }
                }
                padding: 5px;
            }
        }
        table.border-0 {
            tbody {
                tr {
                    td {
                        border: inherit;
                        span {
                            font-size: 14px;
                        }
                    }
                    th {
                        border: inherit;
                    }
                }
            }
        }
        
        .table {
            
                &:not(:first-child) {
                    border-top: inherit;
                }
            
        }


        @media (max-width: 991px) {

            .drag-indicator {
                .drag-icon {
                    display: none;
                    
                }}

            table {
                tbody {
                    tr {
                        td {
                            // white-space: nowrap;
                        }
                        th {
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        
`;

export default TableWrap;
