import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Tables from "../../../Reuseable/Tables"
import { api } from "../../../../Services/api"
import "react-confirm-alert/src/react-confirm-alert.css"
import {
  setAllProjects,
  setProjectView,
  setKpiPointsData
} from "../../../../redux/Tracking/actionCreator"
import { Spinner } from "react-bootstrap"
import ProjectsModalView from "./style"
import { KpiTableHeader } from "./KpiTableHeader"
import { KpiTableColumns } from "./KpiTableColumns"
import { v4 as uuid } from "uuid"
import { useSessionStorage } from "react-use"
import first_icon from "../../../../assets/images/icons/first_icon.svg"
import second_icon from "../../../../assets/images/icons/second_icon.svg"
import third_icon from "../../../../assets/images/icons/third_icon.svg"

export default function KpiTableData({ showProject, setShowProject, permission }) {
  const editPermission = permission.edit
  const createPermission = permission.create
  const deletePermission = permission.delete
  const viewPermission = permission.view

  let allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  let myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  let allProjects = useSelector((state) => state.Tracking.projects)
  let allKpiPointsData = useSelector((state) => state.Tracking.allKpiPointsData)
  const allProjectsLoading = useSelector((state) => state.Tracking.projectsLoading)
  const allFlowStatus = useSelector((state) => state.Tracking.allFlowStatus)
  const allMembers = useSelector((state) => state.Tracking.allMembers)
  const allTaskTypes = useSelector((state) => state.Tracking.allTaskTypes)
  const user = useSelector((state) => state.Auth.user)
  const authToken = useSelector((state) => state.Auth.authToken)
  const filters = useSelector((state) => state.Tracking.filters)
  const [searchValue, setSearchValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [showAlertBox, setShowAlertBox] = useState(false)
  const [deleteAlert, setDeleteAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const [showView, setShowView] = useState(false)
  const [showFormView, setShowFormView] = useState(false)
  const [trackingItemData, setTrackingItemData] = useSessionStorage("projectTrackingData", {})
  const [deleteConfirmationShow, setDeleteConfirmationShow] = useState(false)
  const [clonetaskData, setCloneTask] = useState({})
  const [taskfile, setTaskFile] = useState("")
  const [taskfileList, setTaskFileList] = useState("")
  const [taskfileType, setTaskFileType] = useState("")
  const [showEditTask, setShowEditTask] = useState("")
  const [updatedData, setUpdatedData] = useSessionStorage("projectUpdatedData", {})
  const [inprogressEmployee, setInpgrogressEmployee] = useState([])
  const [noTaskEmployee, setNoTaskEmployee] = useState([])

  const view = useSelector((state) => state.Tracking.projectView)

  let projectView = showProject ? "add project" : view

  const projectViewRender = (value) => {
    return dispatch(setProjectView(value))
  }

  // useEffect(() => {
  //   if (projectView === "all projects") {
  //     setShowProject(false)
  //   }
  // }, [projectView])

  const selected_menu = "all projects"
  let [searchData, setSearchData] = useState([])

  const updateAllProject = (newProject) => {
    dispatch(setAllProjects([...allProjects, newProject]))
    return [...allProjects, newProject]
  }

  const initialProjectType = {
    [uuid()]: {
      name: "New",
      items: [],
      loader: false
    },
    [uuid()]: {
      name: "In Progress",
      items: [],
      loader: false
    },
    [uuid()]: {
      name: "Completed",
      items: [],
      loader: false
    }
  }

  const [columns, setColumns] = useState(initialProjectType)

  const includeUuid = (data) => {
    return Object.entries(data).map(([k, d]) => {
      return { ...d, id: uuid() }
    })
  }

  const getTableData = allKpiPointsData
    .sort((a, b) => b.rating - a.rating)
    .map((item, i) => {
      return (
        <tr key={i} val={item}>
          <td className='text-center '>
            {" "}
            <div className=''>
              {i === 0 ? (
                <img src={first_icon} />
              ) : i === 1 ? (
                <img src={second_icon} />
              ) : i === 2 ? (
                <img src={third_icon} />
              ) : (
                <></>
              )}
            </div>
          </td>
          <td className='rank_badge'>
            <strong className='mobHead'>Employee : </strong>
            <span className=''>{item.personName === "" ? "-" : item.personName}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>No. Of task :</strong>
            <span>{item.totalTasks === "" ? "-" : item.totalTasks}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Total Points :</strong>
            <span>{item.totalPoints === "" ? "-" : item.totalPoints}</span>
          </td>
          <td className='text-center'>
            <strong className='mobHead'>Gained Points :</strong>
            <span className='gained_points'>
              {item.gainedPoints === "" ? "-" : item.gainedPoints}
            </span>
          </td>
        </tr>
      )
    })

  let filteredProjects = allProjects.filter((item) => {
    let projectIdSearch = item?.trackingprojectid
    return (
      projectIdSearch.toLowerCase().includes(searchValue.toLowerCase()) ||
      item.label.toLowerCase().includes(searchValue.toLowerCase())
    )
  })

  const makeCategory = useMemo(
    () => (data) => {
      let uuidData = includeUuid(data)
      let cols = initialProjectType
      Object.entries(uuidData).map(([key, value]) => {
        return Object.entries(cols).map(([k, col]) => {
          if (col?.name?.toLowerCase() === value?.priority?.toLowerCase()) {
            return col.items.push(value)
          }
        })
      })
      setColumns(cols)
    },
    [allProjects, filteredProjects]
  )

  const dispatch = useDispatch()

  const [trackingSortings, setTrackingSorting] = useState({
    selectedSortingItem: "trackingid",
    trackingNumberSorting: "down",
    statusSorting: "",
    projectNameSorting: "",
    reportedBySorting: "",
    prioritySorting: "",
    assignedToSorting: ""
  })

  const [trackingFilters, setTrackingFilters] = useState({
    projectsFilter: filters.projects,
    assignedtoFilter: filters.assignedTo,
    statusFilter: filters.status,
    priorityFilter: filters.priority,
    reportedByFilter: filters.reportedBy
  })

  const selected_tab = "my_tasks"
  const [showFilter, setShowFilter] = useState(false)
  const [viewTaskData, setViewTaskData] = useSessionStorage("ProjectViewData", {})
  const [taskHistory, setHistory] = useState("")
  const [showClone, setShowClone] = useState(false)
  const [cardui, setCardui] = useSessionStorage("cardOrList", "cardui")
  const [startAllTrackingId, setStartAllTrackingId] = useState(
    useSelector((state) => state.Tracking.startAllTrackingId)
  )
  const [startMyTrackingId, setStartMyTrackingId] = useState(
    useSelector((state) => state.Tracking.startMyTrackingId)
  )

  const isLoading = (columnName, ans) => {
    const newColumns = Object.entries(columns).map(([key, value]) => {
      if (value.name === columnName) {
        return {
          [uuid()]: { ...value, loader: ans }
        }
      }
      return { [uuid()]: value }
    })

    let updatedColumns = {}

    newColumns.forEach((element, index) => {
      const [key] = Object.keys(element)
      const [value] = Object.values(element)
      updatedColumns[key] = value
    })
    setColumns(updatedColumns)
  }

  useEffect(() => {
    makeCategory(filteredProjects)
  }, [allProjects, searchValue])

  useEffect(() => {
    setTimeout(() => {
      setAlertMessage("")
      setShowAlertBox(false)
      setDeleteAlert(false)
    }, 2000)
  }, [showAlertBox])

  useEffect(() => {
    fetchKpiPointsList()
  }, [])

  useEffect(() => {
    setViewTaskData((prevState) => {
      return {
        ...prevState,
        label: trackingItemData.label,
        projectid: trackingItemData.trackingprojectid,
        startdate: trackingItemData.startdate,
        duedate: trackingItemData.duedate,
        percentage: trackingItemData.percentage,
        priority: trackingItemData.priority,
        createdby: trackingItemData.createdby,
        projectdescription: trackingItemData.projectdescription,
        budget: trackingItemData.budget,
        workflow: trackingItemData.workflow,
        fileurls: trackingItemData.fileurls,
        teammates: trackingItemData.teammates
      }
    })
    setCloneTask((prevState) => {
      return {
        ...prevState,
        title: trackingItemData.cloneTitle,
        priority: trackingItemData.priority
      }
    })
    setUpdatedData((prevState) => {
      return {
        ...prevState,
        fileurls: trackingItemData.fileurls,
        filesize: trackingItemData.filesize
      }
    })
  }, [JSON.stringify(trackingItemData)])

  /************ Delete project ******************/

  const deleteConfimrationHandler = (response) => {
    if (response) {
      api
        .delete(
          "/trackingprojects/" + trackingClientId + "/" + trackingItemData.trackingprojectid,
          {
            headers: {
              authorizationtoken: `${authToken}`
            }
          }
        )
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            projectViewRender("all projects")
            const updatedProjects = response.data.data
            dispatch(setAllProjects(updatedProjects))
            setAlertMessage(trackingItemData.label + " - has been deleted successfully")
          }
        })
        .catch((error) => {
          if (error) {
            setShowAlertBox(true)
            setDeleteAlert(true)
            setAlertMessage(
              "Unable to delete this project! " + trackingItemData.label + " - has task"
            )
          }
          console.log(error)
        })
    }
  }

  const resetState = () => {
    setHistory("")
    setShowEditTask({
      editTitle: false,
      editProject: false,
      editTaskType: false,
      editReportedBy: false,
      editAssignedTo: false,
      editPriority: false,
      editTimeline: false,
      editStatus: false,
      editDescription: false
    })
  }

  const fetchKpiPointsList = () => {
    setLoading(true)
    let url = `/14_kpipoints/${trackingClientId}`
    api
      .get(url, {
        headers: {
          authorizationtoken: `${authToken}`,
          "Access-Control-Allow-Headers": "*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*"
        }
      })
      .then(function (response) {
        if (response.data) {
          dispatch(setKpiPointsData([...response.data.data]))
          setInpgrogressEmployee(response.data.inprogressTasks)
          setNoTaskEmployee(response.data.usersWithuoutTask)
          if (allKpiPointsData.length > 0) {
            dispatch(setKpiPointsData(response.data.data))
          } else dispatch(setKpiPointsData(response.data.data))
        }

        setLoading(false)
      })
      .catch((error) => {
        console.error("fetchKpiPointsList error", error)
        setLoading(false)
      })
  }
  // const refreshfiles = (item) => {
  //   fetchFileData(item, "refresh");
  // };

  const handleSearch = (value) => {
    setSearchValue(value)
  }

  return (
    <>
      {loading ? (
        <div className='text-center mt-5 mb-5'>
          <Spinner animation='border' variant='info' className='spinner' />
        </div>
      ) : (
        <ProjectsModalView>
          <div className='total_container'>
            <div className='trackingPage'>
              <p className='text-white kpi_head mt-3 mb-0'>Employee Performance Board</p>
              <Tables
                setProjectTable={true}
                columns={
                  <KpiTableColumns
                    trackingSortings={trackingSortings}
                    setTrackingSorting={setTrackingSorting}
                  />
                }
                data={getTableData}
                showInfiniteScroll={false}
                header={
                  <KpiTableHeader
                    userRole={user.rolename}
                    userRoleType={user.roletype}
                    allMembers={allMembers}
                    allProjects={allProjects}
                    allKpiPointsData={allKpiPointsData}
                    refreshAllTracking={() => {}}
                    createPermission={createPermission}
                  />
                }
                notfound={"No Data Found"}
                refreshLoading={refreshLoading}
              />
            </div>
            <div className='tasks_column'>
              <div className='second_container'>
                <p className='text-white kpi_head mb-0'>Employees with task in-progress</p>

                <div className='top_div text-white p-2'>
                  <div className='inprogress_emp'>
                    <p className='count_inprogress m-0'>{inprogressEmployee.length}</p>
                    <p className='employee_p m-0 p-0'>Employees</p>
                  </div>
                  <div className='inprogress_table'>
                    <table className='w-100'>
                      <tbody>
                        {inprogressEmployee?.map((item) => {
                          return (
                            <>
                              <tr className='inprogress_tr'>
                                <div>
                                  <td className='inprogress_td'>
                                    <span className='bck_ovr'>
                                      {allMembers.map((member) => {
                                        if (member.userId === item.assignedto) {
                                          return member.personname
                                        } else {
                                          return <></>
                                        }
                                      })}
                                    </span>
                                    <div className='center_dotted'></div>
                                    <span className='bck_ovr'>
                                      {item.trackingclientid + "-" + item.trackingid}
                                    </span>
                                  </td>
                                </div>
                              </tr>
                            </>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='second_container'>
                <p className='text-white kpi_head mb-0'>Employees with no task</p>
                <div className='top_div text-white p-2'>
                  <div className='no_task_top'>
                    <p className='count_inprogress m-0'>{noTaskEmployee.length}</p>
                    <p className='employee_p p-0'>Employees</p>
                  </div>
                  <div className='no_task_table'>
                    <table className='w-100'>
                      <tbody>
                        {noTaskEmployee?.map((item) => (
                          <tr className='inprogress_tr'>
                            <div>
                              <td className='inprogress_td'>
                                <span className='bck_ovr'>{item.personname}</span>{" "}
                                <div className='center_dotted'></div>
                                <span className='bck_ovr'>00000</span>
                              </td>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </ProjectsModalView>
      )}
    </>
  )
}
