import React from 'react'
import Button from 'react-bootstrap/Button'
import { NodataWrap } from './style'

const NoDataFound = ({
  setTaskview,
  setProjectView,
  addtask,
  headcontent,
  bodycontent,
  btncontent,
  img,
  addAllTask,
  createPermission,
  projectsPermission
}) => {

  const handleAddProjects = () => {
    setProjectView("add project")
  }

  const handleAddTask = () => {
    setTaskview("addnew")
  }


  return (
    <>
      <NodataWrap>
        <div className='nodata_container mt-2' style={{ height: "100%" }}>
          <div className='nodata_content'>
            <h1 style={{ fontSize: "21px", color: '#2D53DA' }}>{headcontent}</h1>
            <p>{bodycontent}
              <span>Get started now.</span>
            </p>
            <div style={{ marginTop: '5px' }}>


            {projectsPermission?.create == true && addAllTask && (
                <Button
                  variant='info'
                  style={{ marginRight: "8px" }}
                  onClick={handleAddProjects}
                  className='btn btn-orange border-0 px-4 py-1'
                >
                  Add Projects
                </Button>
              )}

              {createPermission && addtask === "add task" ? (
    <Button 
        variant="info" 
        onClick={handleAddTask} 
        className="btn btn-orange border-0 px-4 py-1"
    >
        Add {btncontent}
    </Button>
) : (
    projectsPermission?.create == true && (
        <Button 
            variant="info" 
            onClick={handleAddProjects} 
            style={{ marginRight: "8px" }} 
            className="btn btn-orange border-0 px-4 py-1"
        >
            Add Projects
        </Button>
    )
)}

            </div>
          </div>
          <div className='nodata_svg'>
            <img
              src={img}
              alt="no data found"
            />
          </div>
        </div>

      </NodataWrap>

    </>
  )
}

export default NoDataFound