import React from 'react';
import { Card, Row } from 'react-bootstrap';
import NoData from "../../../../../assets/images/Nodata.svg";

const NoDocumentFound = ({showAddDocument, createPermission}) => {

  return (
    <>
    <Row>
    {createPermission && (
      <div className="addTask">
                      <button
                        style={{
                          color: "#FFFFFF",
                          fontSize: "15px",
                          padding: "5px 12px",
                          backgroundColor: "#2D53DA",
                          border: "none",
                          outline: "none",
                          borderRadius: "5px",
                          float: 'right'
                        }}
                        onClick={(e) => {
                          showAddDocument();
                        }}
                      >
                        + Add new file
                      </button>
                    </div>
    )}
                    </Row>
                    <Row>
                        <Card className='card-bd my-4 p-2'>
                            <Card.Body>
                                <center>
                                    <h6 style={{ letterSpacing: '1px' }}>You have not added any document to your account.</h6>
                                    </center>
                            </Card.Body>
                        </Card>
                    </Row>
                    <div>
                        <center>
                            <img src={NoData} />
                        </center>
                    </div>
    </>
  )
}
export default NoDocumentFound;
