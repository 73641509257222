import React from "react"
import CloseIcon from "@mui/icons-material/Close"
import Modal from "react-bootstrap/Modal"
import "bootstrap/dist/css/bootstrap.min.css"
import WorkflowBox from "../WorkFlowCardView/WorkflowBox"
import LocalHospitalIcon from "@mui/icons-material/LocalHospital"
import WorkflowWrap from "../style"

const WorkflowView = ({
  setOpenView,
  openView,
  viewData,
  setViewData,
  setEditNode,
  EditNodeData,
  setEditData,
  handleCardUpdate,
  handleCardChange,
  updateData,
  editNode,
  allWorkFlow,
  editPermission,
  createPermission
}) => {
  const key = viewData.key
  let length = viewData.value.workFlow.length

  const addWorkflowBox = length => {
    let newfield = { [length]: "" }
    let temp = [...viewData.value.workFlow, newfield]

    viewData.value.workFlow = temp

    setEditNode({
      editable: true,
      index: length,
      key: key,
      value: viewData,
      newNode: true,
    })
    EditNodeData(viewData.value.workFlow)
    setEditData(viewData.value)
  }

  return (
    <>
      <Modal
        show={openView}
        onHide={() => {
          setOpenView(false)
          setEditNode({
            editable: false,
            index: "",
            key: "",
            value: "",
          })
        }}
        centered
        style={{ backgroundColor: "rgb(95, 93, 93, 0.75)" }}
        size='sm'
      >
        <WorkflowWrap>
          <div className='pe-2'>
            <CloseIcon
              className='float-end  mt-2'
              onClick={() => {
                setOpenView(false)
                setViewData("")
                setEditNode({
                  editable: false,
                  index: "",
                  key: "",
                  value: "",
                })
              }}
              style={{ cursor: "pointer", color: "#2D53DA" }}
            />
            <div className='modal-view-data'>
              <span className='workflow-name'>
                <strong className='me-2'>Workflow ID:</strong>
                <span
                  style={{
                    color: "#2D53DA",
                  }}
                >
                  {viewData.value.workFlowId}
                </span>
              </span>
              <span className='workflow-name workflowname'>
                <strong className='me-2'>Workflow name:</strong>
                {viewData.value.workFlowName}
              </span>
              <span className='workflow-name workflowname'>
                <strong className='me-2'>Project name:</strong>
                {viewData.value.project_name}
              </span>
            </div>
            <div className='flow-modal-view'>
              {viewData.value.workFlow.map((e, index) => {
                const workFlowLength = index + 1
                let viewdata = []
                Object.keys(e).map(n => {
                  viewdata.push(e[n])
                })

                return (
                  <>
                    {workFlowLength <= 10 && (
                      <WorkflowBox
                        index={index}
                        editNode={editNode}
                        updateData={updateData}
                        id={key}
                        allWorkFlow={allWorkFlow}
                        viewData={viewdata}
                        value={viewData}
                        setViewData={setViewData}
                        handleCardChange={handleCardChange}
                        setEditNode={setEditNode}
                        EditNodeData={EditNodeData}
                        handleCardUpdate={handleCardUpdate}
                        setEditData={setEditData}
                        openView={openView}
                        verticalview={true}
                        editPermission={viewData?.value?.workFlowId !=="default" ? editPermission : ""}
                      />
                    )}
                  </>
                )
              })}
              {editNode && editNode.editable && editNode.key === key && editNode.newNode
                ? null
                : length <= 10 && (
                    <div
                      className='verticalviewplus'
                      style={{ cursor: "pointer", color: "#2D53DA" }}
                      onClick={() => {
                        addWorkflowBox(length, key)
                      }}
                    >
                      {viewData?.value?.workFlowId !=="default" && createPermission ? <LocalHospitalIcon /> : "" }
                    </div>
                  )}
            </div>
          </div>
        </WorkflowWrap>
      </Modal>
    </>
  )
}

export default WorkflowView
