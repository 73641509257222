import React, { useRef, useState } from "react"
import Card from "../Cards/CardUI"
import { BsPlusLg } from "react-icons/bs"
import CardWrap from "../Cards/style"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { api } from "../../../Services/api"
import { useSelector } from "react-redux"
import QuickTask from "../../Pages/Portal/NewTracking/QuickTask"
import EditWorkFlow from "./EditWorkFlow"
import { Skeleton } from "@mui/material"
import { key } from "../../../data/queryKeys"
import { useFetch } from "../../../Hooks/useFetch"

const CardUI = ({
  columns,
  setColumns,
  loading,
  editSwitch,
  isLoading,
  firstData,
  img,
  setShowAlertBox,
  setAlertMessage,
  assignedToMe,
  createPermission,
  ...props
}) => {
  const authToken = useSelector(state => state.Auth.authToken)

  const trackingClientId = useSelector(state => state.Auth.user.clientId)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )
  let allProjects = allProjectsRes?.data?.Items ?? []

  const mainPanelHeight = useSelector(state => state.Tracking.mainPanelHeight)

  const updateTrackings = async (name, value, trackingid) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let Obj = {
      name: name,
      value: value,
      timezone: timezone,
    }
    if (value) {
      console.log(name, value, Obj)
      api
        .patch("/tracking/update/" + trackingid, Obj, {
          headers: {
            authorizationtoken: `${authToken}`,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            console.log("after dragNdrop response", response)
          }
        })
        .catch(error => {
          console.log("drag N drop api Error", error)
        })
    }
  }

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return
    const { source, destination } = result

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId]
      const destColumn = columns[destination.droppableId]
      const sourceItems = [...sourceColumn.items]
      const destItems = [...destColumn.items]
      let [removed] = sourceItems.splice(source.index, 1)
      removed.status_name = Array(destColumn.name)
      removed.trackingprojectstatusid = destColumn.trackingprojectstatusid

      // ?Api Call After Drag Ending
      updateTrackings(
        "trackingprojectstatusid",
        removed.trackingprojectstatusid,
        removed.trackingid
      )

      destItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      })
    } else {
      const column = columns[source.droppableId]
      const copiedItems = [...column.items]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      })
    }
  }

  const project = allProjects.find(allProject => allProject?.trackingprojectid === firstData)

  const cardSectionRef = useRef(null)

  return (
    <CardWrap mainPanelHeight={`${mainPanelHeight}px`}>
      <DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)}>
        <div className='cardWrap' ref={cardSectionRef}>
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                key={index}
                // style={{ maxHeight: "75vh" }}
              >
                <EditWorkFlow column={column} editSwitch={editSwitch} />

                <div
                  className='cardScroll mt-1'
                  // style={{ margin: 8 }}
                >
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className='section'
                          style={
                            {
                              // background: snapshot.isDraggingOver
                              //   ? "lightblue"
                              //   : "lightgrey",
                              // borderRadius: "10px",
                              // padding: 4,
                              // width: 250,
                              // minHeight: 500,
                            }
                          }
                        >
                          {createPermission && (
                            <AddNewQuickTask
                              trackingprojectstatusid={column.trackingprojectstatusid}
                              project={project}
                              setShowAlertBox={setShowAlertBox}
                              isLoading={isLoading}
                              setAlertMessage={setAlertMessage}
                              assignedToMe={assignedToMe}
                            />
                          )}

                          {loading && <LoaderCard />}

                          {column?.loader && <LoaderCard />}

                          {column.items?.map((item, index) => {
                            return (
                              <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      key={item.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        margin: "0 0 8px 0",
                                        minHeight: "50px",
                                        // borderRadius: "5px",
                                        // backgroundColor: snapshot.isDragging
                                        //   ? "hsla(201, 82%, 62%, 0.3)"
                                        //   : "hsla(201, 82%, 62%, 0.8)",
                                        // color: "white",
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      {!loading && (
                                        <Card
                                          key={index}
                                          column={column}
                                          clientId={item.trackingclientid}
                                          timeline={item.timeline !== "" && item.timeline}
                                          trackId={item.trackingid}
                                          title={item.title}
                                          content={item.description}
                                          date={item.reporteddate}
                                          assignedTo={item.assignedtoName}
                                          assignedToMe={assignedToMe}
                                          TaskLevel={item.priority}
                                          itemID={item.trackingid}
                                          file={item}
                                          {...props}
                                        />
                                      )}
                                    </div>
                                  )
                                }}
                              </Draggable>
                            )
                          })}
                          {provided.placeholder}
                          {/* {(loading) ? <div className="text-center mt-5 mb-5">
                            <Spinner animation="border" variant="info" />
                          </div> : <></>} */}
                        </div>
                      )
                    }}
                  </Droppable>
                </div>
              </div>
            )
          })}
        </div>
      </DragDropContext>
    </CardWrap>
  )
}

export default CardUI

const AddNewQuickTask = ({
  trackingprojectstatusid,
  project,
  setShowAlertBox,
  isLoading,
  setAlertMessage,
  assignedToMe,
}) => {
  const [showAddNew, setShowAddNew] = useState(false)
  return (
    <div className={`Error ${showAddNew && "p-0"}`}>
      {/* <div className="noData"> */}
      <div className={`emptyDtat ${!showAddNew && "d-flex justify-content-center"}`}>
        {!showAddNew ? (
          <button
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "4px",
              textAlign: "center",
              background: "transparent",
              border: "none",
              outline: "none",
            }}
            onClick={e => {
              setShowAddNew(true)
            }}
          >
            <BsPlusLg />
            Add Task
          </button>
        ) : (
          <QuickTask
            setShowAddNew={setShowAddNew}
            trackingprojectstatusid={trackingprojectstatusid}
            project={project}
            isLoading={isLoading}
            setShowAlertBox={setShowAlertBox}
            setAlertMessage={setAlertMessage}
            assignedToMe={assignedToMe}
          />
        )}

        {/* <p>Task not found</p> */}
      </div>
      {/* </div> */}
    </div>
  )
}

const LoaderCard = () => {
  return (
    <div className={`Error`}>
      <div className={`emptyDtat`}>
        <Skeleton animation='wave' />
        <Skeleton animation='pulse' />
        <Skeleton animation='wave' />
      </div>
    </div>
  )
}
