import React from "react"
import { Button, Spinner } from "react-bootstrap"
import filterDateIcon from "../../../../../assets/images/filterDateIcon.svg"
import { DatePicker } from "antd"
import RefreshIcon from "@mui/icons-material/Refresh"
import { styled } from "styled-components"

export const ArchiveListHeader = ({
  handleDueSearch,
  allMembers,
  allArchiveTableData,
  searchfromDate,
  searchDueDate,
  handleFromDate,
  isLoading,
  refreshAllTracking
}) => {
  if (allArchiveTableData.length > 0) {
    let result = []
    allArchiveTableData?.forEach((element) => {
      result.push({ label: element.label, value: element.id })
    })
    allArchiveTableData = result
  }

  if (allMembers.length > 0) {
    let result = []
    allMembers?.forEach((element) => {
      result.push({ label: element.personname, value: element.trackinguserid })
    })
    allMembers = result
  }

  return (
    <>
      {allArchiveTableData.length > 0 && (
        <div className='d-flex gap-3 pb-2 align-items-center justify-content-between'>
          <div className='report_task_date_filter'>
            <div className='date_filter_con'>
              {" "}
              <DatePicker
                suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
                onChange={handleFromDate}
                selected={searchfromDate}
                name={searchfromDate}
                placeholder='From date'
                className='form-control search-control border-start-0 search_date_filter'
              />{" "}
            </div>
            <div className='date_filter_con'>
              {" "}
              <DatePicker
                suffixIcon={<img src={filterDateIcon} alt='Calendar' />}
                onChange={handleDueSearch}
                selected={searchDueDate}
                name={searchDueDate}
                placeholder='To date'
                className='form-control search-control border-start-0 search_date_filter'
              />{" "}
            </div>
          </div>
          <ArchiveHeaderView>
            <Button
              className='me-1 btn-rad refresh_btn'
              onClick={(e) => {
                refreshAllTracking()
              }}
            >
              {isLoading ? (
                <Spinner size='sm' variant='light' animation='border' />
              ) : (
                <RefreshIcon />
              )}
            </Button>
          </ArchiveHeaderView>
        </div>
      )}
    </>
  )
}

const ArchiveHeaderView = styled.div`
  .refresh_btn {
    padding: 0px 0px;
    background-color: #2D53DA;
    border-color: #2D53DA;
    width: 28px;
    height: 28px;
    color: #ffffff;
  }
`
