import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { setAllTrackings, setMyTrackings } from "../../../../../redux/Tracking/actionCreator"
import { Modal, Form, Row, Col, Button } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import { api } from "../../../../../Services/api"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"

export const Clone = ({
  showClone,
  setTaskview,
  setShowClone,
  viewTaskData,
  item,
  allTrackingsList,
  setShowAlertBox,
  setAlertMessage,
  clonetaskData,
  setCloneTask,
  backTo,
  taskfile,
  taskfileList
}) => {
  /********************* Make clone task as mandatory TRWH-1106 *****************/
  const [loadingFile, setLoadingFile] = useState(false)
  const [bufferFile, setBufferFile] = useState([])
  const [imgUpldLoading, setImgUpldLoading] = useState(false)

  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.clientId)
  const myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const authToken = useSelector((state) => state.Auth.authToken)
  const user = useSelector((state) => state.Auth.user)
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const [loading, setLoading] = useState(false)

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjects = allProjectsRes?.data?.Items ?? []

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []

  let results = []
  let team_mates = allProjects.find(
    (e) => e.trackingprojectid === viewTaskData.projectid || e.number === viewTaskData.projectid
  )

  if (team_mates) team_mates = team_mates.teammates
  if (team_mates) {
    team_mates.forEach((element) => {
      results.push({ label: element.label, id: element.id })
    })
    allMembers = [...results, { label: "None", id: "notAssigned" }]
  }

  const onSubmitHandle = async () => {
    if (clonetaskData.assignedto !== "") {
      setLoading(true)
      let Obj = {
        title: clonetaskData.title,
        trackingprojectsid: item.trackingprojectsid,
        type: item.type,
        priority: item.priority,
        fileurls: [],
        filesize: [],
        description: item.description,
        reportedby: user.userId,
        assignedto: clonetaskData.assignedto,
        timezone: timezone,
        authorizationToken: authToken
      }
      api
        .post("/tracking/" + trackingClientId, Obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if ((response.status = 200)) {
            let item = {
              trackingid: response.data.data.trackingid,
              trackingclientid: response.data.data.trackingclientid,
              trackingprojectsid: response.data.data.trackingprojectsid,
              comments: response.data.data.comments,
              history: response.data.data.history,
              description: response.data.data.description,
              priority: response.data.data.priority,
              fileurls: response.data.data.fileurls,
              filesize: response.data.data.filesize,
              title: response.data.data.title,
              type: response.data.data.type,
              trackingprojectstatusid: response.data.data.trackingprojectstatusid,
              assignedto: response.data.data.assignedto,
              reportedby: user.userId,
              reporteddate: response.data.data.reporteddate
            }
            if (response.data.data.trackingprojectstatusid != 1) {
              allTrackingsList.push(item)
              if (response.data.data.assignedto == user.userId) myTrackingList.push(item)
            }
            // uploadImage(response.data.data.trackingid);
            dispatch(setAllTrackings([...allTrackingsList]))
            dispatch(setMyTrackings([...myTrackingList]))
            setLoading(false)
            bufferFile.length === 0 && setShowClone(false)
            bufferFile.length === 0 && setTaskview(backTo)
            setShowAlertBox(true)
            setAlertMessage(
              "(" +
                trackingClientId +
                "-" +
                response.data.data.trackingid +
                ")" +
                " New tracking item has been created successfully"
            )
          }
        })
        .catch((e) => {
          console.log("Clone Task Error Response", e)
          setLoading(false)
        })
    }
  }

  // const uploadImage = async (trackingid) => {
  //   bufferFile.length >= 0 && setImgUpldLoading(true)
  //   for (let i = 0; i < taskfile.length; i++) {
  //     axios({
  //       url: 'https://trackingapitestupload.hashching.org/upload/' + trackingClientId + '/S3' + trackingClientId + trackingid + '/' + taskfileList[i],
  //       method: "POST",
  //       fileurl: bufferFile[i],
  //       headers: {
  //         'content-type': 'image/png'
  //       },
  //       data: bufferFile[i]
  //     }).then((response) => {
  //       if (i === bufferFile.length - 1) {
  //         setImgUpldLoading(false)
  //         setShowClone(false);
  //         setTaskview(backTo);
  //       }
  //     }).catch((e) => {
  //       console.log("img Upload Error", e)
  //       setImgUpldLoading(false)
  //     })
  //   }
  // }

  const handleSplChar = (e) => {
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }
  return (
    <Modal show={showClone} onHide={(e) => setShowClone(false)} size='md' keyboard={false} centered>
      <Modal.Header className='border-0'>
        <Modal.Title className='clone'>
          Clone task :{trackingClientId}-{item.trackingid}
        </Modal.Title>
        <CloseRoundedIcon
          className='close-icon float_close_icon'
          onClick={() => setShowClone(false)}
          sx={{ fontSize: 10 }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form
          autoComplete='off'
          onSubmit={(e) => {
            onSubmitHandle()
            e.preventDefault()
          }}
        >
          <Row>
            <Col>
              <Form.Group controlId='title'>
                <Form.Label> Title: </Form.Label>
                <Form.Control
                  type='text'
                  value={clonetaskData.title}
                  placeholder='Enter Title'
                  name='title'
                  maxLength='80'
                  onKeyDown={handleSplChar}
                  onChange={(e) =>
                    setCloneTask((prevState) => {
                      return { ...prevState, title: e.target.value }
                    })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className='mt-3'>
            <Col>
              <Form.Group controlId='assigned_to'>
                <Form.Label> Assigned to: </Form.Label>
                <Form.Control
                  as='select'
                  name='assigned_to'
                  value={clonetaskData.assignedto}
                  required
                  onChange={(e) =>
                    setCloneTask((prevState) => {
                      return { ...prevState, assignedto: e.target.value }
                    })
                  }
                >
                  <option value=''>Select...</option>
                  {allMembers.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row className='mb-2 mt-3'>
            <Col className='text-center' sm={12}>
              <Button
                style={{
                  width: "100px",
                  padding: "4px 4px 4px 13px",
                  fontSize: "17px"
                }}
                type='button'
                size='sm'
                onClick={(e) => setShowClone(false)}
                className=' btn mx-2 my-2'
                variant='secondary'
              >
                Cancel
              </Button>
              <Button
                type='submit'
                size='sm'
                className='  mx-2 my-2 loginSubmitBtn btn btn-orange'
                disabled={clonetaskData.title || loadingFile ? false : true}
                variant='orange'
                style={{
                  width: "100px",
                  padding: "4px 4px 4px 13px",
                  fontSize: "17px",
                  background: loading ? "#FFFFFF" : "#2D53DA",
                  color: loading ? "#808080" : "#FFFFFF",
                  border: loading ? "2px solid #FFFFFF" : "2px solid #2D53DA"
                }}
              >
                {loading || imgUpldLoading ? (
                  <BarLoader
                    color='#808080'
                    className='bar-loader'
                    size={14}
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <></>
                )}
                Submit{""}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}
